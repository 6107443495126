import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatDisplayDatesForSearch, isValidDate } from "../../services/Utils";
import moment from "moment";

const SearchDateInput = (props) => {
  const initialDates = {
    from: new Date(new Date().setFullYear("2023")),
    to: new Date(),
  };

  const {
    colField,
    label,
    filterItems,
    setFilterItems,
    extraFilters,
    setExtraFilters,
    dateObj = initialDates,
    setDateObj,
    dateChecked,
    setDateChecked,
    initialfilter = {
      value: {
        from: initialDates?.from?.toISOString(),
        to: initialDates?.to?.toISOString(),
      },
      operatorValue: "between",
      columnField: colField, //Ex. "vehicle.vehiclePurchaseDetails.purchaseDate"
      label: label,
      displayValue: formatDisplayDatesForSearch(initialDates),
    },
  } = props;

  const saveFilterItemsToFilterArray = (item) => {
    const { columnField, operatorValue, value } = item;
    const oldItems = filterItems;
    const newItems = oldItems.filter((i) => i.columnField !== columnField);
    setFilterItems([...newItems, item]);
  };

  const removeFilterItemsFromFilterArray = (columnField) => {
    const newItems = filterItems.filter((i) => i.columnField !== columnField);
    setFilterItems([...newItems]);
  };

  const handleDateChange = (newValue = null, type) => {
    const newDates = dateObj;
    const newFrom = !!newValue ? new Date(newValue.setHours(0, 0, 0, 0)) : null;
    const newTo = !!newValue
      ? new Date(newValue.setHours(23, 59, 59, 59))
      : null;
    if (!!newValue && !!type && type === "from") {
      if (moment(newValue).isBefore("2001-01-01")) {
        setDateObj({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      if (moment(newValue).isAfter(newDates.to)) {
        setDateObj({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      if (!isValidDate(new Date(newValue))) {
        setDateObj({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      newDates.from = newFrom;
      setDateObj({ from: newFrom, to: newDates.to });
      const newItems = {
        value: {
          from: new Date(newFrom).toISOString(),
          to: new Date(newDates.to).toISOString(),
        },
        operatorValue: "between",
        columnField: colField,
        label: label,
        displayValue: formatDisplayDatesForSearch(newDates),
      };
      saveFilterItemsToFilterArray(newItems);
      if (!newValue && !type) {
        const newExtraFilters = extraFilters.filter((i) => {
          return i.columnField !== colField;
        });
        setExtraFilters([...newExtraFilters, ...[newItems]]);
      }
    } else if (!!newValue && !!type && type === "to") {
      if (moment(newValue).isBefore(newDates.from)) {
        setDateObj({ from: newDates.from, to: newTo });
        return;
      }
      if (!isValidDate(new Date(newValue))) {
        setDateObj({ from: newDates.from, to: newTo });
        return;
      }
      newDates.to = newTo;
      setDateObj({ from: newTo, to: newDates.to });
      const newItems = {
        value: {
          from: new Date(newDates.from).toISOString(),
          to: new Date(newDates.to).toISOString(),
        },
        operatorValue: "between",
        columnField: colField,
        label: label,
        displayValue: formatDisplayDatesForSearch(newDates),
      };
      saveFilterItemsToFilterArray(newItems);
      if (!newValue && !type) {
        const newExtraFilters = extraFilters.filter((i) => {
          return i.columnField !== colField;
        });
        setExtraFilters([...newExtraFilters, ...[newItems]]);
      }
    }
  };

  const handleDateCheckboxSelection = (e) => {
    const {
      target: { checked },
    } = e;
    setDateChecked(checked);
    if (!checked) {
      removeFilterItemsFromFilterArray(colField);
      setDateObj(initialDates);
    } else {
      const newItems = {
        value: {
          from: initialDates?.from?.toISOString(),
          to: initialDates?.to?.toISOString(),
        },
        operatorValue: "between",
        columnField: colField,
        label: label,
        displayValue: formatDisplayDatesForSearch(initialDates),
      };
      saveFilterItemsToFilterArray(newItems);
      setDateObj(initialDates);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormControl variant="standard">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={dateChecked}
                onChange={(e) => handleDateCheckboxSelection(e)}
                name={label}
              />
            }
            label={label}
          />
          <Grid container>
            {dateChecked && (
              <>
                <Grid item xs={6} style={{ padding: "2px" }}>
                  <DatePicker
                    disabled={!true}
                    disableFuture
                    label="From"
                    value={new Date(dateObj.from)}
                    onChange={(val) => handleDateChange(val, "from")}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        id="from-date"
                        variant="standard"
                      />
                    )}
                    maxDate={new Date()}
                    required
                  />
                </Grid>
                <Grid item xs={6} style={{ padding: "2px" }}>
                  <DatePicker
                    disabled={!true}
                    disableFuture
                    label="To"
                    value={new Date(dateObj.to)}
                    onChange={(val) => handleDateChange(val, "to")}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        id="to-date"
                        variant="standard"
                      />
                    )}
                    minDate={new Date(dateObj.from)}
                    required
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FormControl>
      </LocalizationProvider>
    </>
  );
};

export default SearchDateInput;
