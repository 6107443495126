import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { FormControl, InputLabel, Select } from "@mui/material";
import { Auth } from "aws-amplify";
import { getUserDetails } from "../services/Users";
import { stringToHslColor } from "../services/Utils";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { WizardContext, ACTIONS } from "../components/wizard/Context";
import { useQuery, useQueryClient } from "react-query";
import { CUSTOMERS, MODULES } from "../services/Constants";
import { themes } from "../components/themes/Theme";
import { CustomThemeContext } from "../components/themes/CustomThemeProvider";
import { getUserOrgs, getCurrentUserCustomerDetails } from "../services/Users";
import useModule from "../hooks/useModule";
import { CAN, SUBJECTS } from "../auth/permissions";
import Check from "../auth/ability";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

export default function ResponsiveAppBar(props) {
  const { validateModuleWithMessage } = useModule();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const { dispatch, state } = useContext(WizardContext);
  const { currentTheme, setTheme } = useContext(CustomThemeContext);

  const { data: customers = [], isLoading } = useQuery(
    "getcustomersforuser",
    getUserOrgs,
    {
      onSuccess: (orgs) => {
        const previouslySelectedCustomer = !!localStorage.selectedCustomer
          ? localStorage.selectedCustomer
          : null;
        const selectedOrg = orgs?.filter(
          (i) => i?.customerid === previouslySelectedCustomer
        )?.[0];
        if (!!selectedOrg?.customerid) {
          const customerid = selectedOrg?.customerid;
          setCustomer(customerid);
          localStorage.selectedCustomer = customerid;
        } else {
          const customerid = orgs?.[0]?.customerid;
          if (customerid) {
            setCustomer(customerid);
            localStorage.selectedCustomer = customerid;
          } else {
            localStorage.selectedCustomer = null;
          }
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const [customer, setCustomer] = useState(
    !!localStorage.selectedCustomer
      ? localStorage.selectedCustomer
      : customers?.[0]?.customerid
  );

  useQuery(
    ["currentUserCustomerData"],
    getCurrentUserCustomerDetails,
    {
      refetchOnWindowFocus: false,
      onSuccess: (customerData) => {
        const data = customerData.find(e => e.license.customerId === customer)
        const { modules = [] } = data;
        dispatch({
          type: ACTIONS.UPDATE_CUSTOMER_MODULES,
          payload: {
            modules: customerData,
            customerData: modules
          }
        });
      }
    }
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    props.onToggle();
  };

  const handleOpenUserMenu = async (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
    if (event.target.innerText === "Logout") {
      Auth.signOut();
    } else if (event.target.innerText === "Profile") {
      navigate(`/users/myprofile`, { replace: true });
      setAnchorElUser(null);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCustomerChange = (event) => {
    const customerid = event.target.value;
    setCustomer(customerid);
    localStorage.selectedCustomer = customerid;
    setOpenBackdrop(true);
    queryClient.invalidateQueries().finally(() => {
      setOpenBackdrop(false);
      window.location.href = "/";
    });
    handleCloseUserMenu();
    dispatch({
      type: ACTIONS.RESET,
    });
  };

  const handleThemeChange = (themeName) => {
    setTheme(themeName);
  };

  useEffect(() => {
    let fn = async () => {
      let { userName: name, userId: id } = await getUserDetails();
      setUserName(name);
      setUserId(id);
    };
    fn();
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleOpenNavMenu}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          Connected Services
        </Typography>
        <div className="wizard-item">
          <Check I={CAN.MANAGE} a={SUBJECTS.DEPLOYMENT}>
            <Button
              color="inherit"
              startIcon={<PlayCircleFilledWhiteIcon />}
              onClick={() => {
                if (validateModuleWithMessage(MODULES.OTA)) {
                  dispatch({
                    type: ACTIONS.TOGGLE_WIZARD,
                    payload: {
                      showWizard: true,
                    },
                  });
                }
              }}
            >
              Quick Deploy
            </Button>
          </Check>
        </div>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                sx={{
                  bgcolor: stringToHslColor(userName, 40, 40),
                }}
              >
                {userName.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key="customer">
              <FormControl
                variant="standard"
                style={{ margin: "0px" }}
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <InputLabel id="customer-select-small">Customer</InputLabel>
                <Select
                  labelId="customer-select-small"
                  id="customer-select-small"
                  value={customer}
                  label="Customer"
                  onChange={handleCustomerChange}
                >
                  {customers?.length
                    ? customers?.map((cus) => (
                      <MenuItem key={cus.customerid} value={cus.customerid}>
                        {cus.customerName || cus.customerid}
                      </MenuItem>
                    ))
                    : null}
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem key="theme">
              {Object.keys(themes).map((key) => {
                const theme = themes[key];
                return (
                  <Box
                    sx={{
                      width: 35,
                      height: 35,
                      mr: 1,
                      border: 1,
                      borderRadius: 1,
                      borderColor: theme.palette.primary[500],
                      backgroundColor: theme.palette.primary[500],
                    }}
                    onClick={() => handleThemeChange(key)}
                  ></Box>
                );
              })}
            </MenuItem>
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseNavMenu}>
                <Typography key={setting} textAlign="center">
                  {setting}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      <Backdrop
        sx={{ color: "#ef6c00", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AppBar>
  );
}
