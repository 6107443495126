// example reference from https://docs.maptiler.com/react/maplibre-gl-js/how-to-use-maplibre-gl-js/
// https://maplibre.org/maplibre-gl-js/docs/API/
import React, { useRef, useEffect, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from "!maplibre-gl"; // ! ignores this library from webpack transpiler // ref: https://docs.mapbox.com/mapbox-gl-js/guides/install/#targeting-transpilation-to-es6-with-browserslist
import { formatDateWithoutTime, formatDateToTime } from "../../services/Utils";
import "maplibre-gl/dist/maplibre-gl.css";
import "./map.css";

export default function AwsMap({ data }) {
  const { startLocation: start, startTime, endLocation: end, endTime } = data;
  const [startLocation] = useState({
    coordinates: [-115.17067618409216, 36.11297516088419], // Demo values
  });
  const [endLocation] = useState({
    coordinates: [-115.15149302670726, 36.12940675425624], // Demo values
  });
  const [viewport, setViewport] = React.useState({
    // latitude: 37.7833,
    // longitude: -122.4167,
    latitude: startLocation?.coordinates[1],
    longitude: startLocation?.coordinates[0],
    zoom: 12,
  });
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(startLocation?.coordinates[0]);
  const [lat] = useState(startLocation?.coordinates[1]);
  const [zoom] = useState(12.5);
  const apiKey = process.env.REACT_APP_AWS_MAP_APIKEY;
  const [API_KEY] = useState(apiKey);

  //   const identityPoolId = "us-east-2:80081e21-78e1-468d-9e06-f78705bbf052";
  const mapName = process.env.REACT_APP_AWS_MAP_NAME; // "Ota-nextdev-frontendPortalDefaultMap";
  const region = process.env.REACT_APP_AWS_REGION; //"us-east-2";

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      center: [lng, lat],
      zoom: zoom,
    });
    map.current.addControl(new maplibregl.NavigationControl(), "top-right");

    if (startLocation?.coordinates[0]) {
      const startPopup = new maplibregl.Popup({ offset: 25 }).setHTML(
        `<div><span>Start Time :</span>${formatDateWithoutTime(
          startTime
        )} ${formatDateToTime(startTime)}</div>
        <div><span>Location :</span>${startLocation?.coordinates[0]},${
          startLocation?.coordinates[1]
        }</div>
        `
      );
      const startMarker = new maplibregl.Marker({ color: "#FF0000" })
        .setLngLat([
          startLocation?.coordinates[0],
          startLocation?.coordinates[1],
        ])
        .setPopup(startPopup)
        .addTo(map.current);

      startMarker.addClassName("start-marker");
    }

    if (endLocation?.coordinates[0]) {
      const endPopup = new maplibregl.Popup({ offset: 25 }).setHTML(
        `<div><span>End Time :</span>${formatDateWithoutTime(
          endTime
        )} ${formatDateToTime(endTime)}</div>
        <div><span>Location :</span>${endLocation?.coordinates[0]},${
          endLocation?.coordinates[1]
        }</div>
        `
      );
      const endMarker = new maplibregl.Marker({ color: "#7dd813a1" })
        .setLngLat([
          endLocation?.coordinates[0], // + 0.009,
          endLocation?.coordinates[1],
        ])
        .setPopup(endPopup)
        .addTo(map.current);
      endMarker.addClassName(`end-marker`);
      if (
        startLocation?.coordinates[0] === endLocation?.coordinates[0] &&
        startLocation?.coordinates[1] === endLocation?.coordinates[1]
      ) {
        endMarker.addClassName(`same-as-start-marker`);
      }
    }

    map.current.on("load", () => {
      // to display a line connecting locations
      map.current.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [
              [startLocation?.coordinates[0], startLocation?.coordinates[1]],
              [endLocation?.coordinates[0], endLocation?.coordinates[1]],
            ],
          },
        },
      });
      map.current.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "red",
          "line-width": 2,
          "line-dasharray": [1, 3],
        },
      });
    });
  }, [
    apiKey,
    lng,
    lat,
    zoom,
    mapName,
    region,
    startLocation,
    endLocation,
    startTime,
    endTime,
  ]);

  return (
    <div className="map-wrap" style={{ height: "inherit", width: "100%" }}>
      <div ref={mapContainer} className="map" />
    </div>
  );
}
