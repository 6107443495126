import React, { useState } from "react";
import styled from "styled-components";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  formatDate_12h,
  capitalize,
  detailsTabsBoxStyle,
  overviewListItemStyles,
} from "../../services/Utils";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { getVehicleAuditData } from "../../services/vehicle";
import { getColumnsData } from "./VehicleAuditColumns";
import { UserNameFromUserId } from "../common/UserNameFromUserId";
import StatusActiveInactive from "../common/StatusActiveInactive";

import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import UpdateTCUPopup from "./UpdateTCUPopup";

const KeyTextWrapper = styled.p`
  margin: 0;
  padding: 0;
  word-break: break-all;
  min-width: 250px;
`;

const VehicleDetailsTab = ({ vehicleData, isLoading }) => {
  const columns = getColumnsData();
  const {
    device: {
      serialNo,
      vehicleKey,
      active = false,
      serialNoCreatedAt = "",
      vehicleKeyUpdatedAt = "",
      updatedBy = "",
      vehicle = { customerId: "" },
      customerId = "",
      certAvailable,
    } = {},
  } = vehicleData;
  const { overview = {} } = vehicleData;
  const { purchaseDetails = {} } = vehicleData;
  const { connectivity = {} } = vehicleData;

  const {
    data: auditData = [],
    isLoading: auditDataIsLoading,
    refetch,
  } = useQuery(["vehicleAuditData", serialNo], getVehicleAuditData, {
    enabled: !!serialNo,
    refetchOnWindowFocus: true,
  });

  if (isLoading) return <CircularProgress />;

  return (
    <Box {...detailsTabsBoxStyle}>
      <Grid container>
        <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
          <List>
            <ListItem {...overviewListItemStyles}>
              <ListItemText primary="Serial No" secondary={serialNo || "-"} />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Status"
                secondary={active ? "Active" : "Inactive"}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="HU Manufactured"
                secondary={formatDate_12h(serialNoCreatedAt)}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText primary="Customer" secondary={customerId || "-"} />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Certificate"
                secondary={certAvailable ? "Available" : "-"}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
          <List>
            <ListItem
              {...overviewListItemStyles}
              style={{ wordBreak: "break-all", whiteSpace: "normal" }}
            >
              <ListItemText
                primary="Vehicle Key"
                secondary={vehicleKey || "-"}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Vehicle Manufactured"
                secondary={formatDate_12h(vehicleKeyUpdatedAt)}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              {vehicleData?.features?.length ? (
                vehicleData?.features.map((i) => (
                  <ListItemText
                    primary="Features Enabled"
                    secondary={
                      i?.active
                        ? i?.feature?.featureName.toUpperCase() || "-"
                        : "-"
                    }
                  />
                ))
              ) : (
                <ListItemText primary="Features Enabled" secondary="None" />
              )}
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Vehicle TCU ID"
                secondary={
                  <TcuNumberInput
                    {...{
                      serialNo,
                      vehicleKey,
                      tcuId: vehicleData?.device?.vehicle?.tcuId,
                    }}
                  />
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={4}>
          <List>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Vehicle Purchase Date"
                secondary={formatDate_12h(purchaseDetails?.purchaseDate)}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Last Seen At"
                secondary={formatDate_12h(connectivity?.timestamp)}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      {auditData?.length > 0 && (
        <div style={{ marginTop: "32px" }}>
          <Typography
            sx={{ flex: "1 1 100%", ml: "12px" }}
            variant="button"
            id="tableTitle"
            component="div"
          >
            Vehicle audit logs
          </Typography>

          <TableContainer component={Paper} title="Vehicle Audit Logs">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Vehicle Key</TableCell>
                  <TableCell>Certificate</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditData.map((row) => (
                  <TableRow
                    key={row.auditId}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.serialNo}
                    </TableCell>
                    <TableCell>{capitalize(row?.action)}</TableCell>
                    <TableCell>
                      <UserNameFromUserId value={row.createdBy} />
                    </TableCell>
                    <TableCell>{formatDate_12h(row?.createdAt)}</TableCell>
                    <TableCell>
                      <KeyTextWrapper>{row?.vehicleKey || "-"}</KeyTextWrapper>
                    </TableCell>
                    <TableCell>
                      {row.certAvailable ? "Available" : "-"}
                    </TableCell>
                    <TableCell>
                      <StatusActiveInactive active={row?.active} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Box>
  );
};

export default VehicleDetailsTab;

const TcuNumberInput = ({ tcuId, serialNo, vehicleKey }) => {
  const [popupData, setPopupData] = useState(null);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>{!!tcuId ? tcuId : "TCU not mapped"}</Typography>
        {!tcuId ? (
          <Button
            onClick={() => {
              setPopupData({ serialNo, vehicleKey, tcuId });
            }}
            size="small"
            variant="outlined"
            startIcon={<AddBoxIcon />}
          >
            Add TCU ID
          </Button>
        ) : (
          <IconButton
            onClick={() => {
              setPopupData({ serialNo, vehicleKey, tcuId });
            }}
            aria-label="delete"
            size="small"
          >
            <ModeEditIcon fontSize="inherit" />
          </IconButton>
        )}
      </div>
      <UpdateTCUPopup
        data={popupData}
        toggleDialog={() => setPopupData(null)}
      />
    </>
  );
};
