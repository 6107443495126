import React from "react";
import { getClusterImagesList } from "../../services/cluster";
import Constants from "../../services/Constants";
import OTAPagination from "../OTAPagination";
import { getClusterImageUploadDataColumns } from "./ClusterImageUploadDataColumns";

const ClusterImageUploadDataGrid = () => {
  const columns = getClusterImageUploadDataColumns();

  return (
    <OTAPagination
      columns={columns}
      endpoint={Constants.LIST_CLUSTER_IMAGES}
      endpointFn={getClusterImagesList}
      queryName={"clusterimages"}
      filterMode="server"
      getRowId={(row) => row.simulationUploadId}
    />
  );
};

export default ClusterImageUploadDataGrid;
