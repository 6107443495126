import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  CircularProgress,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { updateVehicleTcuId } from "../../services/vehicle";
import { useToast } from "../toast";
import CloseIcon from "@mui/icons-material/Close";

const UpdateTCUPopup = ({ data, toggleDialog }) => {
  const { addToast } = useToast();
  // Access the client
  const queryClient = useQueryClient();
  const [tcuNumber, setTcuNumber] = useState(data?.tcuId || "");
  const [isValidTcuNumber, setIsValidTcuNumber] = useState(false);

  const toogle = () => {
    toggleDialog?.();
  };

  const { mutate: updateMutation, isLoading: updateLoading } = useMutation(
    updateVehicleTcuId,
    {
      onSuccess: () => {
        // Invalidate and refetch vehicle details
        queryClient.invalidateQueries("vehicleDetails");
        toogle();
        addToast({
          type: "success",
          message: `TCU ID for ${data?.serialNo} updated successfully.`,
          autoClose: 3000,
        });
      },
      onError: (e) => {
        const { response } = e;
        addToast({
          type: "error",
          message: response?.data?.message || "Failed to add!",
          autoClose: 3000,
        });
      },
    }
  );
  const onAdd = () => {
    updateMutation({
      tcuId: tcuNumber,
      vehicleKey: data?.vehicleKey,
    });
  };
  const onCancel = () => {
    toogle();
  };

  const validateTcuNumber = (val) => {
    const E164Phoneno = /^\+[1-9]\d{10,14}$/; // E.164 format phone number validation (+XX XXXXXXXXXX)
    if (val.match(E164Phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  const onInput = (e) => {
    const val = e?.target?.value;
    if (val === "") {
      setIsValidTcuNumber(false);
    } else {
      //validate the tcu number
      const isValid = validateTcuNumber(val);
      if (isValid) {
        setIsValidTcuNumber(true);
      } else {
        setIsValidTcuNumber(false);
      }
    }
    setTcuNumber(val);
  };

  return (
    <Dialog
      open={!!data?.vehicleKey}
      onClose={onCancel}
      aria-labelledby="tcu-update-dialog-title"
      aria-describedby="tcu-update-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Update TCU ID for "${data?.serialNo}"`}
        <IconButton
          aria-label="close"
          onClick={toogle}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Tooltip title="Close update dialog">
            <CloseIcon />
          </Tooltip>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minWidth: 480 }}>
        <TextField
          placeholder="+00 0000000000"
          helperText={`
            Input a valid phone number,
            with prefix (+ and country code).
          `}
          style={{ marginTop: "1em" }}
          type="tel"
          label="TCU ID"
          id="tcu-id-input"
          size="small"
          fullWidth
          value={tcuNumber}
          onChange={(e) => onInput(e)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={updateLoading} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={updateLoading || !isValidTcuNumber || !tcuNumber?.length}
          onClick={onAdd}
          autoFocus
        >
          {updateLoading ? (
            <CircularProgress size="1rem" color="secondary" />
          ) : (
            "Add"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateTCUPopup;
