import {
  ChecksumAlgorithm,
  S3,
  S3Client,
  ListPartsCommand,
  UploadPartCommand,
  CreateMultipartUploadCommand,
  CompleteMultipartUploadCommand,
  ListMultipartUploadsCommand,
} from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { getChunk } from "./chunker";
import { IMAGE_UPLOAD_ERRORS } from "./Constants";
export default async function uploadFileUsingS3Api({
  bucket,
  key,
  selectedFile,
  onProgress,
  onInit,
  creds,
  contentType,
}) {
  try {
    let totalBytes = selectedFile.size;
    let queueSize = 4;
    let partSize = 1024 * 1024 * 5;

    const client = new S3Client({
      region: process.env.REACT_APP_AWS_REGION,
      credentials: creds,
    });

    let listMultiPartResp = await getUploadIdFromS3Key(client, bucket, key);
    let uploadId = listMultiPartResp?.Uploads?.[0]?.UploadId || "";

    if (!uploadId) {
      // NEW UPLOAD
      const filePayload = {
        Bucket: bucket,
        Key: key,
        Body: selectedFile,
        ChecksumAlgorithm: ChecksumAlgorithm.SHA256,
        // ContentType: selectedFile?.type,
      };
      if (!!contentType) {
        filePayload.ContentType = selectedFile?.type;
      }
      const parallelUploads3 = new Upload({
        client:
          new S3({
            region: process.env.REACT_APP_AWS_REGION,
            credentials: creds,
          }) || client,
        queueSize: 4,
        partSize: 1024 * 1024 * 5,
        leavePartsOnError: false,
        params: filePayload,
      });

      parallelUploads3.on("httpUploadProgress", (progress) => {
        onProgress(progress);
      });

      window.addEventListener(
        "offline",
        async () => {
          console.log("Became offline");
          await parallelUploads3.abort();
        },
        { once: true }
      );

      onInit(parallelUploads3);

      let result = await parallelUploads3.done();

      return result;
    } else {
      //reupload
      let reuploadReqParam = {
        client,
        bucket,
        key,
        selectedFile,
        uploadId,
        queueSize,
        partSize,
        totalBytes,
        onProgress,
      };

      let prevUploadPartDetails = await getPreviousImageUploadPartDetails(
        bucket,
        key,
        uploadId,
        client
      );

      let reUploadImgStatus = await checkReUploadImageAsSameAsPreviousImage(
        prevUploadPartDetails,
        selectedFile,
        partSize
      );

      if (reUploadImgStatus) {
        throw new Error(
          `The selected file ${selectedFile.name} doesn't match with the previously uploaded file!`
        );
      } else {
        let response = await reuploadMultipartUploadFile(
          reuploadReqParam,
          prevUploadPartDetails
        );

        if (!response) {
          throw new Error(
            `Some parts of the selected file ${selectedFile.name} failed to upload. Please try again to complete the upload process.`
          );
        } else {
          return response;
        }
      }
    }
  } catch (error) {
    console.error("Error occurred while uploading file:", error);
    if (error?.message === IMAGE_UPLOAD_ERRORS.UPLOAD_ABORT) {
      throw new Error(IMAGE_UPLOAD_ERRORS.ERR_INTERNET_DISCONNECTED);
    } else {
      throw error;
    }
  }
}

let bytesUploadedSoFar = 0;
let concurrentUploaders = [];
let total_parts = 0;

const calculateSHA256Hash = async (blob) => {
  const buffer = await blob.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  const hashArray = new Uint8Array(hashBuffer);
  const hashBase64 = base64FromArray(hashArray);
  return hashBase64;
};
// Function to convert Uint8Array to Base64
const base64FromArray = (array) => {
  const binary = String.fromCharCode.apply(null, array);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return btoa(String.fromCharCode.apply(null, bytes));
};

const getUploadIdFromS3Key = async (client, bucket, key) => {
  const input = {
    Bucket: bucket, // required
    Prefix: key, //key
  };
  const command = new ListMultipartUploadsCommand(input);
  const response = await client.send(command);
  return response;
};

const getPreviousImageUploadPartDetails = async (
  bucket,
  key,
  uploadId,
  client
) => {
  //LIST OF EXISTING UPLOAD PART
  const input = {
    Bucket: bucket,
    Key: key,
    UploadId: uploadId,
  };
  try {
    const command = new ListPartsCommand(input);
    const response = await client.send(command);
    return response;
  } catch (error) {
    console.log("UploadListpart error:", error);
  }
};

const checkReUploadImageAsSameAsPreviousImage = async (
  prevUploadPartDetails,
  selectedFile,
  partSize
) => {
  try {
    const { Parts } = prevUploadPartDetails;

    let dataFeeder = getChunk(selectedFile, partSize);

    let currentUploadFilePartsSHA = [];

    for await (const dataPart of dataFeeder) {
      if (dataPart?.lastPart) {
        total_parts = dataPart.partNumber;
      }
      const blob = new Blob([dataPart?.data], {
        type: "application/octet-stream",
      });
      let ChecksumSHA256 = await calculateSHA256Hash(blob);
      currentUploadFilePartsSHA.push(ChecksumSHA256);
    }

    //compare two array if array length is 1
    var result = Parts.filter(function (data) {
      bytesUploadedSoFar += Number(data.Size);
      return currentUploadFilePartsSHA.indexOf(data.ChecksumSHA256) === -1;
    });

    return result.length ? true : false;
  } catch (error) {
    console.error("Hash Error", error);
  }
};

const reuploadMultipartUploadFile = async (
  reuploadReqParam,
  prevUploadPartDetails
) => {
  const { selectedFile, partSize, queueSize } = reuploadReqParam;

  const { Parts } = prevUploadPartDetails;
  let dataFeeder = getChunk(selectedFile, partSize);
  const partNumberSet = new Set(Parts.map((obj) => obj.PartNumber));

  for (let index = 0; index < queueSize; index++) {
    let currentUpload = doConcurrentUpload(
      dataFeeder,
      partNumberSet,
      reuploadReqParam
    );
    concurrentUploaders.push(currentUpload);
  }

  await Promise.all(concurrentUploaders);
  const completedPartResp = await completePreviousUploadParts(reuploadReqParam);
  return completedPartResp;
};

const doConcurrentUpload = async (
  dataFeeder,
  partNumberSet,
  reuploadReqParam
) => {
  for await (const dataPart of dataFeeder) {
    const isValuePresent = partNumberSet.has(dataPart.partNumber);
    if (!isValuePresent) {
      await uploadPart(dataPart, reuploadReqParam);
    }
  }
};

const uploadPart = async (dataPart, reuploadReqParam, retryCount = 3) => {
  const { bucket, key, uploadId, client, totalBytes, onProgress } =
    reuploadReqParam;

  const uploadPartInput = {
    Body: dataPart.data,
    Bucket: bucket, // required
    Key: key, // required
    ChecksumAlgorithm: "SHA256",
    PartNumber: Number(dataPart.partNumber), // required
    UploadId: uploadId, // required
  };

  try {
    const commandInputPart = new UploadPartCommand(uploadPartInput);
    const uploadPartResp = await client.send(commandInputPart);
    bytesUploadedSoFar += Number(dataPart?.data.length);

    onProgress({
      loaded: bytesUploadedSoFar,
      total: totalBytes,
      part: dataPart.partNumber,
      Key: key,
      Bucket: bucket,
    });

    return uploadPartResp;
  } catch (error) {
    console.error(
      `Error uploading part ${Number(dataPart.partNumber)}: ${error.message}`
    );
    if (retryCount > 0) {
      console.log(`Retrying upload of part ${Number(dataPart.partNumber)}`);
      return uploadPart(dataPart, reuploadReqParam, retryCount - 1);
    } else {
      console.log(`Failed to upload part ${Number(dataPart.partNumber)}`);
    }
  }
};

const completePreviousUploadParts = async (reuploadReqParam) => {
  const { bucket, key, uploadId, client } = reuploadReqParam;

  let prevUploadPartDetails = await getPreviousImageUploadPartDetails(
    bucket,
    key,
    uploadId,
    client
  );

  if (total_parts === prevUploadPartDetails?.Parts?.length) {
    let completedParts = prevUploadPartDetails.Parts.map((part) => ({
      PartNumber: part.PartNumber,
      ETag: part.ETag,
      ChecksumSHA256: part.ChecksumSHA256,
    }));
    const completePartInput = {
      // CompleteMultipartUploadRequest
      Bucket: bucket,
      Key: key,
      MultipartUpload: {
        // CompletedMultipartUpload
        Parts: completedParts,
      },
      UploadId: uploadId,
    };
    try {
      const command = new CompleteMultipartUploadCommand(completePartInput);
      const response = await client.send(command);
      return true;
    } catch (error) {
      console.log(
        "An error occurred while completing the multipart upload",
        error
      );
    }
  } else {
    return false;
  }
};
