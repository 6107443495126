export const CAN = {
  READ: "read",
  CREATE: "create",
  MANAGE: "manage",
  PUBLISH: "publish",
  UPDATE: "update",
  CANCEL: "cancel",
};

export const SUBJECTS = {
  DEPLOYMENT: "deployment",
  REMOTE_COMMANDS: "remote-commands",
  FEATURES: "features",
  ORGANIZATION: "organization",
  PROVISION: "provision",
  CONFIGURATION: "configuration",
  CUSTOM_CONFIG: "custom-config",
  SCHEDULER: "scheduler",
  USER: "user",
  IMAGE: "image",
  RELEASE: "release",
  GROUP: "group",
  ALL: "all",
};

export const USER_GROUPS = {
  SUPER_ADMIN: "super-admin",
  HD_ADMIN: "hd-admin",
  OTA_ADMIN: "ota-admin-group",
  COTA_ADMIN: "cota-admin",
  SUBSCRIPTION_ADMIN: "subscription-admin",
  REMOTE_COMMANDS_ADMIN: "remote-commands-admin",
  ANALYTICS_ADMIN: "analytics-admin",
  OTA_CAMPAIGN_CREATOR: "ota-campaign-creator",
  OTA_CAMPAIGN_APPROVER: "ota-campaign-approver",
  GUEST: "ota-guest-group",
};

