import React, { useState, useEffect, useCallback } from "react";
import Constants from "../../services/Constants";
import ClusterSimulationFileUploadDialog from "./ClusterSimulationFileUploadDialog";

function ClusterFileUpload(props) {
  const { handleClose, setUploadProgress, setUploadStatus, openModal } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onFileSelected = (event) => {
    setSelectedFile?.(event.target.files[0]);
    setIsFilePicked?.(true);
  };

  const resetForm = useCallback(() => {
    setIsFilePicked?.(false);
    setSelectedFile?.(null);
    setUploadProgress?.({ loaded: 0, total: 100 });
    setUploadStatus?.(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleClose = () => {
    resetForm?.();
    handleClose?.();
  };

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  return (
    <>{openModal ? <ClusterSimulationFileUploadDialog {...props} /> : null}</>
  );
}

export default ClusterFileUpload;
