import { Box, Alert } from "@mui/material";
import React from "react";
import { detailsTabsBoxStyle } from "../../services/Utils";
import useModule from "../../hooks/useModule";
import { MODULES, MODULE_TEXT } from "../../services/Constants";

const VehicleLogsTab = ({ vehicleId }) => {
  const { validate } = useModule();

  if (!validate(MODULES.ANALYTICS)) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="warning">
          {MODULE_TEXT.ANALYTICS} module not enabled for
          {localStorage.selectedCustomer} customer
        </Alert>
      </Box>
    );
  }

  return (
    <Box {...detailsTabsBoxStyle}>
      <iframe
        title="grafana vehicle graph"
        src={`https://grafana.ota${process.env.REACT_APP_STAGE}.visteoncloud.com/d/${process.env.REACT_APP_GRAFANA_ID}/livelogs?orgId=1&=&refresh=5s&var-serialNo=${vehicleId}&from=now-15m&to=now&panelId=2&theme=light&kiosk`}
        width="100%"
        height="750px"
        frameborder="0"
      ></iframe>
    </Box>
  );
};

export default VehicleLogsTab;
