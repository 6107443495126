import React, { memo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import { Box, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useQuery } from "react-query";
import { getCommentDetails } from "../../../services/cluster.js";
import { useToast } from "../../toast/useToast.jsx";
import { DateAndTimeGridCell } from "../../common/DateAndTimeGridCell.jsx";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InfoIcon from "@mui/icons-material/Info";
import { AddCircleOutline, DeleteOutline, Download } from "@mui/icons-material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CommentDetails = ({ details, handleClose, simulationUploadId }) => {
  const { addToast } = useToast();
  const [commentData, setCommentData] = useState(null);

  useQuery(
    [
      "getCommentAttachmentUrls",
      details?.simulationCommentId,
      simulationUploadId,
    ],
    getCommentDetails,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCommentData(data);
      },
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          addToast({
            type: "error",
            message: errorData.message,
            autoClose: 3000,
          });
        }
      },
    }
  );

  return (
    <>
      <Dialog
        open={details}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {}}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Comment Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <CommentReviewCard details={details} commentData={commentData} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function CommentReviewCard({ details, commentData }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card style={{ maxWidth: "628px", minWidth: "428px", width: "auto" }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {commentData?.createdBy?.[0]}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={commentData?.createdBy}
        subheader={
          <DateAndTimeGridCell value={details?.createdAt} singleLine={true} />
        }
      />
      <CardContent sx={{ paddingLeft: "1em" }}>
        <Typography variant="body2" color="text.secondary" paragraph>
          {commentData?.comment}
        </Typography>
      </CardContent>
      <hr></hr>

      <CardActions disableSpacing>
        <IconButton
          aria-label="Add more attachment"
          title="Add more attachment"
        >
          <AddCircleOutline />
        </IconButton>

        <div style={{ textAlign: "center", width: "100%" }}>
          <Typography variant="caption">
            Attachments{` (${commentData?.attachments?.length})`}
          </Typography>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show attachments"
          title="show attachments"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ height: "400px", overflowY: "auto" }}>
          <ThumbnailsList list={commentData?.attachments} />
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function ThumbnailsList({ list }) {
  const theme = useTheme();
  const [showImageViewer, setShowImageViewer] = useState(null);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ImageList id="img-list" sx={{ width: 400, height: "auto" }}>
        {list.map((item, index) => (
          <ImageListItem
            style={{ cursor: "pointer" }}
            key={item.name}
            onClick={() => {
              // setAttachmentIndex(index);
              setShowImageViewer({ initialIndex: index });
            }}
          >
            <img
              style={{
                minHeight: "128px",
                border: `2px solid ${theme.palette.primary[500]}`,
              }}
              // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={item.url}
              alt={item.name}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.name}
              // subtitle={item.author}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.name}`}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      {showImageViewer && list ? (
        <ImageViewerSlider
          list={list}
          initialIndex={showImageViewer?.initialIndex}
          handleClose={() => {
            setShowImageViewer(null);
          }}
        />
      ) : null}
    </div>
  );
}

const LazyImage = ({ url }) => {
  // const [src, setSrc] = useState(null);
  // const [loadingClass, setLoadingClass] = useState("");
  // const imgRef = useRef();

  return (
    <>
      <img
        style={{ width: "100%", height: "auto" }}
        className="img"
        alt={url}
        src={url}
        // ref={imgRef}
        // data-src={url}
        // onLoad={() => {
        //   console.log("img on load : ", imgRef?.current);
        //   imgRef.current.className = "loaded";
        //   imgRef.current.src = imgRef.current.data - src;
        //   setSrc(url);
        // }}
      />
    </>
  );
};

const ImageViewerSlider = ({ list, initialIndex, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [attachmentIndex, setAttachmentIndex] = useState(initialIndex || 0);

  const moveToNext = () => {
    if (attachmentIndex < list?.length - 1) {
      setAttachmentIndex(attachmentIndex + 1);
    }
  };

  const moveToPrev = () => {
    if (attachmentIndex > 0) {
      setAttachmentIndex(attachmentIndex - 1);
    }
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={list?.length}
        fullScreen
        style={{ padding: "2em" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Attachments {` (${list?.length})`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Fade in={list?.length}>
            <Box sx={{ style }}>
              <div
                id="image-viewer-container"
                style={{ width: "100%", height: "auto" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <div>
                    <IconButton
                      size="large"
                      disabled={attachmentIndex === 0}
                      title="Previous"
                      color="primary"
                      onClick={() => {
                        if (attachmentIndex === 0) {
                          return;
                        } else {
                          moveToPrev();
                        }
                      }}
                    >
                      <NavigateBeforeIcon />
                    </IconButton>
                  </div>
                  <div style={{ paddingLeft: "1em" }}>
                    <Typography variant="caption">
                      {list[attachmentIndex]?.name}
                    </Typography>
                  </div>
                  <div>
                    <IconButton
                      size="large"
                      disabled={attachmentIndex === list?.length - 1}
                      title="Next"
                      color="primary"
                      onClick={() => {
                        if (attachmentIndex === list?.length - 1) {
                          return;
                        } else {
                          moveToNext();
                        }
                      }}
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </div>
                </div>

                <div className="lazy-image-container">
                  <LazyImage url={list[attachmentIndex]?.url} />
                </div>
              </div>

              <CardActions
                disableSpacing
                style={{
                  marginTop: "1em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <IconButton aria-label="Delete attachment">
                  <DeleteOutline />
                </IconButton>

                <IconButton aria-label="Download attachment">
                  <Download />
                </IconButton>
              </CardActions>
            </Box>
          </Fade>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(CommentDetails);
