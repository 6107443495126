import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import SourceIcon from "@mui/icons-material/Source";
import JavascriptIcon from "@mui/icons-material/Javascript";
import HtmlIcon from "@mui/icons-material/Html";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import DescriptionIcon from "@mui/icons-material/Description";

export function LinearProgressWithLabel(props) {
  console.log("Progress = ", props?.value);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const iconsObj = {
  html: <HtmlIcon />,
  js: <JavascriptIcon />,
  data: <SourceIcon />,
  wasm: <MiscellaneousServicesIcon />,
};

export function UploadItem({
  file,
  isUploading,
  // isWaitingToBeUploaded,
  currentFileBeingUploaded,
  // doneUploading,
  simulationsRepoBucket,
  uploadedItems,
  uploadProgress,
}) {
  const getRelativePathString = (i) => {
    const path = i?.webkitRelativePath;
    const pathParts = path.split("/");
    pathParts.shift();
    pathParts.pop();
    const pathString = pathParts.join("/");
    console.log("processed path string : ", pathString);
    return pathString;
  };
  return (
    <>
      <ListItem
        secondaryAction={
          isUploading ? (
            <>
              {currentFileBeingUploaded ? (
                "" // <LinearProgressWithLabel value={uploadProgress || 0} />
              ) : (
                <>
                  {uploadedItems?.indexOf(file?.name) > -1 ? (
                    <Typography variant="button" sx={{ fontSize: "x-small" }}>
                      Uploaded
                    </Typography>
                  ) : (
                    <Typography variant="button" sx={{ fontSize: "x-small" }}>
                      Waiting to upload...
                    </Typography>
                  )}
                </>
              )}
            </>
          ) : null
        }
      >
        <ListItemAvatar>
          <Avatar>
            {iconsObj[file?.name?.split(".")[1]] || <DescriptionIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <div style={{ display: "flex" }}>
              <Typography variant="body2" style={{ fontSize: "0.8em" }}>
                {getRelativePathString(file)}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: "0.9em", fontWeight: "bold" }}
              >
                {"/" + file?.name}
              </Typography>
            </div>
          }
          // secondary={`${Number(file?.size / 1024 ** 2).toFixed(2)}  MB`}
          secondary={
            <>
              <Typography
                variant="button"
                sx={{ fontSize: "x-small", display: "block" }}
              >{`${Number(file?.size / 1024 ** 2).toFixed(2)}  MB`}</Typography>

              {currentFileBeingUploaded ? (
                <LinearProgressWithLabel
                  value={uploadProgress ? uploadProgress * 100 : 0}
                />
              ) : null}
            </>
          }
        />
      </ListItem>
    </>
  );
}
