import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Dialog, List, ListItem, ListItemText, Grid, Box } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import { getTripDetailsByTripId } from "../../services/vehicle";
import {
  formatDate_12h,
  capitalize,
  detailsTabsBoxStyle,
  overviewListItemStyles,
} from "../../services/Utils";
import AwsMap2 from "../maps/awsMap2";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

const TripDetailsDialog = ({ tripId }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { data, isLoading, refetch } = useQuery(
    ["tripDetails", tripId],
    getTripDetailsByTripId,
    {
      enabled: !!tripId,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <React.Fragment>
      <Button
        size="medium"
        sx={{ justifyContent: "flex-start", textTransform: "none" }}
        onClick={handleClickOpen}
      >
        {tripId}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // style={{ padding: "1em" }}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Trip Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box {...detailsTabsBoxStyle}>
            <Grid container>
              <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
                <List>
                  <ListItem {...overviewListItemStyles}>
                    <ListItemText
                      primary="Trip ID"
                      secondary={data?.tripId || "-"}
                    />
                  </ListItem>
                  <ListItem {...overviewListItemStyles}>
                    <ListItemText
                      primary="Trip Start Time"
                      secondary={formatDate_12h(data?.startTime)}
                    />
                  </ListItem>
                  <ListItem {...overviewListItemStyles}>
                    <ListItemText
                      primary="Trip End Time"
                      secondary={formatDate_12h(data?.endTime)}
                    />
                  </ListItem>
                  <ListItem
                    {...overviewListItemStyles}
                    style={{ wordBreak: "break-all", whiteSpace: "normal" }}
                  >
                    <ListItemText
                      primary="Stops"
                      secondary={data?.stopCount || "-"}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
                <List>
                  <ListItem
                    {...overviewListItemStyles}
                    style={{ wordBreak: "break-all", whiteSpace: "normal" }}
                  >
                    <ListItemText
                      primary="Distance Covered"
                      secondary={data?.distance + " km" || "-"}
                    />
                  </ListItem>
                  <ListItem
                    {...overviewListItemStyles}
                    style={{ wordBreak: "break-all", whiteSpace: "normal" }}
                  >
                    <ListItemText
                      primary="Top Speed"
                      secondary={data?.topSpeed + " km/h" || "-"}
                    />
                  </ListItem>

                  <ListItem
                    {...overviewListItemStyles}
                    style={{ wordBreak: "break-all", whiteSpace: "normal" }}
                  >
                    <ListItemText
                      primary="Average speed"
                      secondary={data?.averageSpeed + " km/h" || "-"}
                    />
                  </ListItem>
                  <ListItem
                    {...overviewListItemStyles}
                    style={{ wordBreak: "break-all", whiteSpace: "normal" }}
                  >
                    <ListItemText
                      primary="Average Odometer"
                      secondary={data?.avgOdometer + " km" || "-"}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={4}>
                <List>
                  <ListItem {...overviewListItemStyles}>
                    <ListItemText
                      primary="Driving Details"
                      // secondary={data?.distance}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid
              style={{
                width: "100%",
                height: "400px",
                border: "1px solid #80808042",
              }}
            >
              <AwsMap2 data={data} />
            </Grid>
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
};

export default TripDetailsDialog;
