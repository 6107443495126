import React from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { getJobExecutionsForVehicles, getJobExecutions } from "../../services/vehicle";
import CancelVehicleDeployment from "./CancelVehicleDeployment";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const VehicleDeploymentList = (data = {}) => {
  let { vehicleId, deploymentId } = useParams();
  const { vehicles = [], type } = data;
  const [vehicle] = vehicles;

  const getFn = type === 'vehicle' ? getJobExecutionsForVehicles : getJobExecutions;

  // jobExecutions Queries
  let {
    data: { data: { executionSummaries = [] } = {} } = {},
    isLoading: summaryLoading,
  } = useQuery(
    ["jobExecutions", deploymentId, vehicle?.vehicleKey],
    getFn, // to get the execution summary of the whole deployment
    {
      refetchOnWindowFocus: false,
    }
  );

  const allVehicles = executionSummaries.map((execution) => {
    return {
      show: true,
      serialNo: execution?.thingArn.split("/")[1],
      ...execution
    };
  });

  if (summaryLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid item xs={12}>
      <TableContainer
        sx={{
          maxHeight: 800,
          overflow: "auto",
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 650, border: "1px solid #e2e2e2" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {vehicleId && <TableCell>Deployment Id</TableCell>}
              {!vehicleId && (
                <>
                  <TableCell>Serial No</TableCell>
                  <TableCell>Vehicle Key</TableCell>
                </>
              )}
              <TableCell>Status</TableCell>
              {type === "deployment" && <TableCell>Group</TableCell>}
              <TableCell>Queued At</TableCell>
              <TableCell>Started At</TableCell>
              <TableCell>Last Updated At</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allVehicles.map((vehicle) => (
              <>
                <TableRow
                  key={vehicle.serialNo}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {vehicleId && (
                    <TableCell component="th" scope="row">
                      {deploymentId}
                    </TableCell>
                  )}
                  {!vehicleId && (
                    <>
                      <TableCell>
                        <Tooltip title={vehicle.serialNo}>
                          <Button
                            size="medium"
                            disabled={!vehicle?.vehicleKey}
                            sx={{
                              justifyContent: "flex-start",
                              textTransform: "none",
                            }}
                            component={RouterLink}
                            to={`/vehicles/${vehicle.serialNo}/deployments/${deploymentId}`}
                          >
                            {vehicle.serialNo}
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{vehicle.vehicleKey}</TableCell>
                    </>
                  )}
                  <TableCell>
                    {vehicle.jobExecutionSummary.status
                      ? vehicle.jobExecutionSummary.status
                      : "-"}
                  </TableCell>
                  {type === "deployment" && <TableCell>
                    {!!vehicle?.vehicleGroupName &&
                      !!vehicle?.vehicleGroupId ? (
                      <Tooltip title={vehicle?.vehicleGroupName}>
                        <Button
                          disabled={vehicle?.vehicleGroupIsDeleted}
                          size="medium"
                          sx={{
                            justifyContent: "flex-start",
                            textTransform: "none",
                          }}
                          component={RouterLink}
                          to={`/groups/${vehicle.vehicleGroupId}/deployments/${deploymentId}`}
                        >
                          {vehicle.vehicleGroupName}
                        </Button>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>}
                  <TableCell>
                    {vehicle.jobExecutionSummary.queuedAt ? (
                      <DateAndTimeGridCell
                        value={vehicle.jobExecutionSummary.queuedAt}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    {vehicle.jobExecutionSummary.startedAt ? (
                      <DateAndTimeGridCell
                        value={vehicle.jobExecutionSummary.startedAt}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    {vehicle.jobExecutionSummary.lastUpdatedAt ? (
                      <DateAndTimeGridCell
                        value={vehicle.jobExecutionSummary.lastUpdatedAt}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    <CancelVehicleDeployment data={vehicle} />
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default VehicleDeploymentList;
