import React, { useState, Fragment, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Input,
  Grid,
  CardActions,
  TextareaAutosize,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import { valid } from "semver";
import { formatFileSize } from "../../services/Utils";
import { getUserName } from "../../services/Users";
import {
  createImage,
  getStsToken,
  getServerConfiguration,
  getImageByName,
  getImageById,
  updateImageUploadStatus,
} from "../../services/Image";
import { useQueryClient, useMutation, useQuery } from "react-query";
import Constants, {
  DEPLOYMENT_PACKAGE_TYPE,
  IMAGE_UPLOAD_ERRORS,
  UPLOAD_MIN_FILESIZE,
} from "../../services/Constants";
import uploadFileUsingS3Api from "../../services/AwsFileUploader.js";
import { useToast } from "../toast";

const FileUploadAdditionalInfoPane = ({
  packageType,
  manifestData,
  selectedFile,
  setUploadStatus,
  setUploadProgress,
  resetForm,
  resumeUpload,
  setErrorMsg,
  errorMsg,
  uploadInstance,
  setUploadInstance,
  imageUploadActionLoading,
  setImageUploadActionLoading,
}) => {
  const footerArea = document.getElementById("upload-dialog-footer-area");
  const { name } = selectedFile;
  const { addToast } = useToast();
  // Access the client
  const queryClient = useQueryClient();
  const resumeUploadId = !!resumeUpload ? resumeUpload : false;

  const [changeLog, setChangeLog] = useState("");
  const [stsToken, setStsToken] = useState({
    accessKeyId: "",
    secretAccessKey: "",
    sessionToken: "",
  });
  const [version, setVersion] = useState(
    packageType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE
      ? manifestData?.version
      : ""
  );
  const [isVersionValid, setIsVersionValid] = useState(valid(version));
  const [campaignType, setCampaignType] = useState(
    packageType ? packageType : DEPLOYMENT_PACKAGE_TYPE.OTHERS
  );
  const [baseVersion, setBaseVersion] = useState("");
  const [isBaseVersionValid, setIsBaseVersionValid] = useState(true);
  const [lastKnownVersion, setLastKnownVersion] = useState("None");
  const [isChangeLogError, setIsChangeLogError] = useState(false);
  const [imageRepoBucket, setImageRepoBucket] = useState("");
  const [versionHelperText, setVersionHelperText] = useState(
    "Version cannot be empty!"
  );
  const [baseVersionHelperText, setBaseVersionHelperText] = useState(
    campaignType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE
      ? "Base version cannot be empty when campaign type is SMART CORE!"
      : ""
  );

  let { UPLOAD_STATUS: UploadStatus } = Constants;

  const validateChangeLog = (log) => {
    if (log.length > Constants.IMAGE_UPLOAD_CHANGELOG_MAX_LENGTH) {
      setIsChangeLogError(true);
    } else {
      setIsChangeLogError(false);
    }
  };

  // query
  const {
    data: partiallyUploadedImageData,
    isLoading: loadingPartialImagedata,
  } = useQuery(
    ["getPartiallyUploadedImageData", resumeUploadId],
    getImageById,
    {
      enabled: !!resumeUploadId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          if (errorData.statusCode !== 404) {
            addToast({
              type: "error",
              message: errorData.message,
              autoClose: 3000,
            });
          }
        }
      },
    }
  );

  const { data: previousImageData } = useQuery(
    ["checkIfImageExists", { name }],
    getImageByName,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          if (errorData.statusCode !== 404) {
            addToast({
              type: "error",
              message: errorData.message,
              autoClose: 3000,
            });
          }
        }
      },
    }
  );

  const checkVersionTypeAndValidate = useCallback(
    (ver) => {
      if (campaignType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE) {
        return true;
      } else {
        return valid(ver);
      }
    },
    [campaignType]
  );

  const validateVersion = useCallback(
    (ver = version) => {
      if (!!ver) {
        const isValid = checkVersionTypeAndValidate(ver);
        const isSameAsLastVersion =
          ver === previousImageData?.version ? true : false;
        setIsVersionValid(isValid && !isSameAsLastVersion);
        if (isValid) {
          if (isSameAsLastVersion) {
            setIsVersionValid(false);
            setVersionHelperText("Version cannot be same as last version!");
          } else {
            setIsVersionValid(true);
          }
        } else {
          setIsVersionValid(false);
          setVersionHelperText(Constants.INVALID_VERSION_HELPER_TEXT);
        }
      } else {
        setIsVersionValid(false);
        setVersionHelperText("Version cannot be empty!");
      }
    },
    [checkVersionTypeAndValidate, previousImageData?.version, version]
  );

  const onChangeLogModified = (event) => {
    setChangeLog(event.target.value);
    validateChangeLog(event.target.value);
  };

  const versionInputHandler = (event) => {
    const {
      target: { value },
    } = event;
    setVersion(value);
    validateVersion(value);
  };

  const validateBaseVersion = useCallback(
    (ver = baseVersion, type = campaignType) => {
      if (!!ver) {
        const isValid = checkVersionTypeAndValidate(ver);
        if (isValid) {
          setIsBaseVersionValid(true);
          setVersionHelperText("");
        } else {
          setIsBaseVersionValid(false);
          setBaseVersionHelperText(Constants.INVALID_VERSION_HELPER_TEXT);
        }
      } else {
        if (type === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE) {
          setIsBaseVersionValid(false);
          setBaseVersionHelperText(
            "Base version cannot be empty when campaign type is SMART CORE!"
          );
        } else {
          setIsBaseVersionValid(true);
          setBaseVersionHelperText("");
        }
      }
    },
    [baseVersion, campaignType, checkVersionTypeAndValidate]
  );

  const baseVersionInputHandler = (event) => {
    const {
      target: { value },
    } = event;
    setBaseVersion(value);
    validateBaseVersion(value);
  };

  const campaignTypeInputHandler = (event) => {
    const {
      target: { value },
    } = event;
    setCampaignType(value);
    validateBaseVersion(baseVersion, value);
    validateVersion(version);
  };

  useQuery("stsToken", getStsToken, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: token = {} } = data;
      setStsToken(token);
    },
    onError: (err) => {
      setIsChangeLogError(true);
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  useQuery("getServerConfig", getServerConfiguration, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: serverConfig = {} } = data;
      setImageRepoBucket(serverConfig.s3.IMAGE_UPLOAD_BUCKET);
    },
    onError: (err) => {
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  const { mutate: updateUploadStatus } = useMutation(updateImageUploadStatus, {
    onSuccess: async ({ data }) => {},
    onError: (err) => {},
    onSettled: () => {
      queryClient.invalidateQueries("images");
    },
  });

  const updateImgUploadStatus = (imageUploadId, imageUploadStatus) => {
    const payload = {
      imageUploadId: imageUploadId,
      uploadStatus: imageUploadStatus,
    };
    updateUploadStatus(payload);
  };

  const imageUploadAction = async (data) => {
    try {
      setImageUploadActionLoading(true);
      const result = await uploadFileUsingS3Api({
        bucket: imageRepoBucket,
        key: data.imageUploadId,
        selectedFile,
        onInit: (instance) => {
          setUploadInstance?.(instance || null);
        },
        onProgress: (progress) => {
          setUploadStatus(UploadStatus.InProgress);
          if (progress?.part === 2) {
            updateImgUploadStatus(data.imageUploadId, UploadStatus.InProgress);
          }
          setUploadProgress({
            ...progress,
            uploadPct: progress.loaded / progress.total,
          });
        },
        creds: stsToken,
      });

      if (result) {
        setUploadStatus(UploadStatus.Completed);
        updateImgUploadStatus(data.imageUploadId, UploadStatus.Completed);
      }
    } catch (error) {
      const { message = IMAGE_UPLOAD_ERRORS.SOMETHING_WENT_WRONG } = error;
      setErrorMsg(message);
      setUploadStatus(UploadStatus.Failed);
    } finally {
      setImageUploadActionLoading(false);
    }
  };

  const { mutate: mutateImage, isLoading } = useMutation(createImage, {
    onSuccess: async ({ data }) => {
      setUploadStatus(UploadStatus.Started);
      imageUploadAction(data);
    },
    onError: (err) => {
      //Api call error block
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
      setImageUploadActionLoading(false);
    },
    onSettled: () => {
      setImageUploadActionLoading(true);
      queryClient.invalidateQueries("images");
      queryClient.invalidateQueries("getImagesData");
    },
  });

  const handleSubmission = async () => {
    const body = {
      fileName: selectedFile.name,
      fileType: selectedFile.type,
      version,
      uploadedBy: await getUserName(),
      fileSize: selectedFile.size.toString(),
      uploadStatus: UploadStatus.Started,
      changeLog,
      baseVersion,
      campaignType,
      manifest:
        packageType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE ? manifestData : {},
    };

    if (!!resumeUploadId) {
      imageUploadAction(partiallyUploadedImageData);
    } else {
      setImageUploadActionLoading(true);
      mutateImage(body);
    }
  };

  const validateResumeUpload = () => {
    let resp = false;
    let selectedFileValid =
      Number(selectedFile?.size) < UPLOAD_MIN_FILESIZE ? true : false;
    if (!!errorMsg || !!resumeUploadId) {
      if (selectedFile?.name !== partiallyUploadedImageData?.fileName) {
        resp = true;
      } else if (
        Number(selectedFile?.size) !==
        Number(partiallyUploadedImageData?.fileSize)
      ) {
        resp = true;
      } else if (selectedFile.type !== partiallyUploadedImageData?.fileType) {
        resp = true;
      }
    } else if (
      isChangeLogError ||
      !isVersionValid ||
      !isBaseVersionValid ||
      selectedFileValid
    ) {
      if (
        packageType !== DEPLOYMENT_PACKAGE_TYPE.SMARTCORE ||
        selectedFileValid
      ) {
        resp = true;
      }
    }
    if (isLoading) {
      resp = true;
    }
    if (imageUploadActionLoading) {
      resp = true;
    }

    return resp;
  };

  useEffect(() => {
    validateBaseVersion();
    validateVersion();
  }, [campaignType, validateVersion, validateBaseVersion]);

  return (
    <Grid container spacing={2}>
      <Grid item md={4}>
        <List>
          <ListItem>
            <ListItemText
              primary="File Name"
              secondary={
                <>
                  <Typography
                    paragraph={true}
                    sx={{ display: "inline", fontSize: "14px" }}
                  >
                    <div>{selectedFile.name}</div>
                    {!loadingPartialImagedata && !!resumeUploadId && (
                      <Typography
                        paragraph={true}
                        sx={{
                          display: "inline",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {selectedFile?.name !==
                        partiallyUploadedImageData?.fileName
                          ? "The file name does not match!"
                          : ""}
                      </Typography>
                    )}
                  </Typography>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="File Size"
              //secondary={formatFileSize(selectedFile.size)}
              secondary={
                <>
                  <Typography
                    paragraph={true}
                    sx={{ display: "inline", fontSize: "14px" }}
                  >
                    <div>{formatFileSize(selectedFile.size)}</div>
                    {!loadingPartialImagedata && !!resumeUploadId && (
                      <Typography
                        paragraph={true}
                        sx={{
                          display: "inline",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {Number(selectedFile?.size) !==
                        Number(partiallyUploadedImageData?.fileSize)
                          ? "The Total file size does not match!"
                          : ""}
                      </Typography>
                    )}
                    {!resumeUploadId && (
                      <Typography
                        paragraph={true}
                        sx={{
                          display: "inline",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {Number(selectedFile?.size) < UPLOAD_MIN_FILESIZE
                          ? "Invalid file size"
                          : ""}
                      </Typography>
                    )}
                  </Typography>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="File Type"
              secondary={
                <>
                  <Typography
                    paragraph={true}
                    sx={{ display: "inline", fontSize: "14px" }}
                  >
                    <div>{selectedFile.type}</div>
                    {!loadingPartialImagedata && !!resumeUploadId && (
                      <Typography
                        paragraph={true}
                        sx={{
                          display: "inline",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {selectedFile.type !==
                        partiallyUploadedImageData?.fileType
                          ? "The file type does not match!"
                          : ""}
                      </Typography>
                    )}
                  </Typography>
                </>
              }
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item md={4}>
        <List>
          <ListItem>
            <ListItemText
              primary="Last Modified Date"
              secondary={selectedFile.lastModifiedDate.toLocaleDateString()}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Last Version"
              secondary={previousImageData?.version || lastKnownVersion}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Package Type"
              secondary={
                campaignType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE
                  ? "Smart Core"
                  : "Others"
              }
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item md={4}>
        <List>
          {packageType?.length &&
            packageType !== DEPLOYMENT_PACKAGE_TYPE.SMARTCORE && (
              <ListItem>
                <ListItemText
                  primary="Base Version"
                  secondary={
                    <TextField
                      variant="standard"
                      onChange={baseVersionInputHandler}
                      style={{ width: "100%" }}
                      error={!!resumeUploadId ? false : !isBaseVersionValid}
                      value={partiallyUploadedImageData?.baseVersion}
                      helperText={
                        !!resumeUploadId
                          ? ""
                          : !isBaseVersionValid
                          ? baseVersionHelperText
                          : ""
                      }
                      disabled={!!resumeUploadId}
                    />
                  }
                />
              </ListItem>
            )}
          <ListItem>
            <ListItemText
              primary="Version"
              secondary={
                <TextField
                  variant="standard"
                  onChange={versionInputHandler}
                  value={
                    packageType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE
                      ? manifestData?.version
                      : partiallyUploadedImageData?.version
                  }
                  style={{ width: "100%" }}
                  error={!!resumeUploadId ? false : !isVersionValid}
                  helperText={
                    !!resumeUploadId
                      ? ""
                      : !isVersionValid
                      ? versionHelperText
                      : ""
                  }
                  disabled={
                    (packageType?.length &&
                      packageType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE) ||
                    !!resumeUploadId
                  }
                />
              }
            />
          </ListItem>
          {packageType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE ? (
            <ListItem>
              <ListItemText
                primary="Applicable versions"
                secondary={
                  <List
                    dense={true}
                    sx={{
                      overflow: "auto",
                      maxHeight: 100,
                    }}
                  >
                    {manifestData?.applicable_versions?.length ? (
                      manifestData?.applicable_versions.map((i) => (
                        <ListItem style={{ paddingLeft: "0" }}>
                          <ListItemText primary={i} />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem style={{ paddingLeft: "0" }}>
                        <ListItemText primary="No Data" />
                      </ListItem>
                    )}
                  </List>
                }
              />
            </ListItem>
          ) : null}

          <ListItem>
            <ListItemText
              primary="Change Log"
              secondary={
                <Fragment>
                  <TextareaAutosize
                    minRows={3}
                    placeholder="500 characters max"
                    onChange={onChangeLogModified}
                    value={changeLog}
                    style={{ width: "100%" }}
                    disabled={!!resumeUploadId}
                  />
                  {isChangeLogError && (
                    <div
                      style={{
                        color: "#d32f2f",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      {`Maximum length of the change log cannot be over ${Constants.IMAGE_UPLOAD_CHANGELOG_MAX_LENGTH}
                      characters!`}
                    </div>
                  )}
                </Fragment>
              }
            />
          </ListItem>
        </List>
      </Grid>
      {createPortal(
        <CardActions sx={{ width: "100%", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={resetForm}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmission}
            disabled={validateResumeUpload()}
          >
            Upload
          </Button>
        </CardActions>,
        footerArea
      )}
    </Grid>
  );
};

export default FileUploadAdditionalInfoPane;
