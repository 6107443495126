import React, { memo, useState } from "react";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Close";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreateComment from "./CreateComment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteCommentForSimulation,
  getCommentsForSimulation,
} from "../../../services/cluster";
import DeleteCommentConfirm from "./DeleteCommentConfirm";
import { DateAndTimeGridCell } from "../../common/DateAndTimeGridCell";
import PreviewIcon from "@mui/icons-material/Preview";
import CommentDetails from "./CommentDetails";

const CommentsList = ({
  toggleCommentsTable,
  showCommentsTable,
  simulationUploadId,
  toggleCommentsDrawer,
}) => {
  const queryClient = useQueryClient();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const [commentValue, setCommentValue] = useState("");
  const [priority, setPriority] = useState("low");
  const [deleteConfirmDialogProps, setShowDeleteConfirmDialogProps] =
    useState(null);
  const [showCommentDetails, setShowCommentDetails] = useState(null);

  const closeCommentDetails = () => {
    setShowCommentDetails(null);
  };

  let queryName = "fetchCommentsForSimulation",
    page = 1,
    pageSize = 10,
    field = "",
    sort = "desc",
    filterValue,
    columns;

  const { data: { data } = { data: [] } } = useQuery(
    [
      queryName,
      page,
      pageSize,
      field,
      sort,
      filterValue,
      columns,
      simulationUploadId,
    ],
    getCommentsForSimulation,
    {
      enabled: !!simulationUploadId,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const { data: { meta = {}, res = [] } = {} } = response;
        setRows(res);
      },
    }
  );

  const { mutate: deleteComment } = useMutation(deleteCommentForSimulation, {
    onSuccess: async ({ data }) => {},
    onError: (err) => {},
    onSettled: () => {
      setShowDeleteConfirmDialogProps(null);
      queryClient.invalidateQueries("fetchCommentsForSimulation");
    },
  });

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };

  const cols = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 70,
      cellClassName: "actions",
      getActions: (d) => {
        const { id, row } = d;
        return [
          <GridActionsCellItem
            icon={<PreviewIcon color="primary" />}
            label="Details View"
            onClick={() => {
              setShowCommentDetails(row);
            }}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={() => {
          //     const show = true;
          //     const onAccept = () => {
          //       deleteComment(row?.simulationCommentId);
          //     };
          //     const onCancel = () => {
          //       setShowDeleteConfirmDialogProps(null);
          //     };

          //     const props = {
          //       onAccept,
          //       onCancel,
          //       show: true,
          //       comment: row?.comment,
          //     };
          //     setShowDeleteConfirmDialogProps(props);
          //   }}
          //   color="inherit"
          // />,
        ];
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 70,
      // editable: true,
      // type: "singleSelect",
      // valueOptions: ["High", "Medium", "Low"],
    },
    {
      field: "attachments",
      headerName: "Attachments",
      width: 100,
      renderCell: ({ row }) => {
        return row?.attachments.length;
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 1000,
      // editable: true,
    },
    // {
    //   field: "joinDate",
    //   headerName: "Join date",
    //   type: "date",
    //   //   width: 180,
    //   editable: true,
    // },
    {
      field: "uploadedBy",
      headerName: "Created By",
      // type: "number",
      //   width: 80,
      // align: "left",
      // headerAlign: "left",
      // editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      //   width: 180,
      // editable: true,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.createdAt} />
      ),
    },
  ];

  const createProps = {
    simulationUploadId,
    commentValue,
    setCommentValue,
    priority,
    setPriority,
    handlePriorityChange,
  };

  return (
    <>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
        id="draggable-handle"
      >
        <div
          style={{
            position: "absolute",
            left: "0",
            paddingLeft: "5px",
            paddingTop: "5px",
          }}
        >
          <CreateComment {...createProps} />
        </div>
        <IconButton
          color="primary"
          onClick={() => {
            toggleCommentsTable();
          }}
        >
          {showCommentsTable ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>

        <IconButton
          color="primary"
          onClick={() => {
            toggleCommentsDrawer?.();
          }}
          style={{
            position: "absolute",
            right: "0",
            paddingLeft: "5px",
            paddingTop: "5px",
          }}
        >
          <CancelIcon />
        </IconButton>
      </div>

      <div style={{ height: showCommentsTable ? "500px" : "250px" }}>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            density="compact"
            rows={rows}
            columns={cols}
            editMode="row"
            rowModesModel={rowModesModel}
            // onRowModesModelChange={handleRowModesModelChange}
            // onRowEditStop={handleRowEditStop}
            // processRowUpdate={processRowUpdate}
            isRowSelectable={false}
            rowSelection={false}
            // isRowEditable={(params) => {
            //   console.log("params = ", params);
            //   return params?.row?.isNew && params?.row?.inEditing;
            // }}
            getRowId={(row) => row?.simulationCommentId}
            // slots={{
            //   toolbar: <Typography>Test toolbar</Typography>,
            //   // <EditToolbar setAddComment={setAddComment} />,
            // }}
            // slotProps={{
            //   toolbar: { setRows, setRowModesModel },
            // }}
          />
        </Box>
      </div>
      {deleteConfirmDialogProps ? (
        <DeleteCommentConfirm {...deleteConfirmDialogProps} />
      ) : null}
      {showCommentDetails ? (
        <CommentDetails
          details={showCommentDetails}
          handleClose={closeCommentDetails}
          simulationUploadId={simulationUploadId}
        />
      ) : null}
    </>
  );
};

export default memo(CommentsList);
