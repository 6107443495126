import axios from "axios";
import Constants from "./Constants";
import { constructQueryUrl } from "./Utils";

export const getSdvFeatureList = async ({ queryKey }) => {
  const [_, page, pageSize, field, sort = "ASC", filterValue, columns] =
    queryKey;
  let query =
    Constants.LIST_SDV_APP_FEATURES +
    `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort?.toUpperCase()}`;

  query = constructQueryUrl({ filterValue, query, columns });
  return await axios.get(query);
};

export const getSdvFeatureDataCount = async () => {
  let query = Constants.SDV_FEATURE_REPORT;
  return await axios.get(query);
};

export const getSdvVehicleFeatureList = async ({ queryKey }, sdvFeatureId) => {
  const [
    _,
    page,
    pageSize,
    field,
    sort = "ASC",
    filterValue,
    columns,
    extraFilters = [],
  ] = queryKey;

  const newFilterValue = JSON.parse(JSON.stringify(filterValue));

  try {
    if (newFilterValue?.items) {
      newFilterValue.items = [...filterValue.items, ...extraFilters];
    } else {
      newFilterValue["items"] = [...extraFilters];
    }
  } catch (e) {
    console.log(e);
  }

  let query =
    Constants.LIST_SDV_VEHICLE_FEATURES +
    `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort?.toUpperCase()}`;

  query = `${query}&sdvFeatureId=${sdvFeatureId}`;

  query = constructQueryUrl({ filterValue: newFilterValue, query, columns });
  return await axios.get(query);
};

export const createSDVFeature = async (payload) => {
  return axios.post(Constants.ADD_SDV_APP_FEATURES, payload);
};

export const updateSDVFeatureUploadStatus = async (payload) => {
  return axios.put(Constants.PUBLISH_SDV_APP_FEATURES_UPLOAD_STATUS, payload);
};

export const getServerConfiguration = async () => {
  return await axios.get(Constants.SDV_SERVER_CONFIG);
};

export const getStsToken = async () => {
  return await axios.get(Constants.SDV_STS_TOKEN);
};

export const addVehicleForSdvFeature = async (payload) => {
  return axios.post(Constants.ADD_VEHICLE_FOR_SDV_FEATURES, payload);
};

export const removeVehiclesForSdvFeature = async (payload) => {
  return axios.delete(Constants.REMOVE_VEHICLE_FOR_SDV_FEATURES, payload);
};

export const updateSDVFeatureStatus = async (payload) => {
  return axios.put(Constants.SDV_APP_FEATURE_UPDATE_STATUS, payload);
};

export const getVehicleDataList = async ({ queryKey }, sdvFeatureId) => {
  console.log("sdvFeatureId", sdvFeatureId);
  const [
    _,
    page,
    pageSize,
    field,
    sort = "ASC",
    filterValue,
    columns,
    extraFilters = [],
  ] = queryKey;

  const newFilterValue = JSON.parse(JSON.stringify(filterValue));

  try {
    if (newFilterValue?.items) {
      newFilterValue.items = [...filterValue.items, ...extraFilters];
    } else {
      newFilterValue["items"] = [...extraFilters];
    }
  } catch (e) {
    console.log(e);
  }

  let query =
    Constants.LIST_VEHICLESDATA_FOR_SDV +
    `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort?.toUpperCase()}`;

  query = `${query}&sdvFeatureId=${sdvFeatureId}`;

  query = constructQueryUrl({ filterValue: newFilterValue, query, columns });
  return await axios.get(query);
};

export const getSdvFeatureById = async ({ queryKey }) => {
  const [_, id] = queryKey;
  //Constants.DESCRIBE_SDV_FEATURE.replace(":id", params?.sdvFeatureId);
  const url = Constants.DESCRIBE_SDV_FEATURE.replace(":id", id);
  const { data = [] } = await axios.get(url);
  return data;
};
