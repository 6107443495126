export const COMBINATORS = [
  { name: "AND", label: "AND" },
  { name: "NOT", label: "NOT" },
];

export const OPERATORS = [
  { name: "=", label: "=" },
  { name: "!=", label: "!=" },
  { name: "<", label: "<" },
  { name: ">", label: ">" },
  { name: "<=", label: "<=" },
  { name: ">=", label: ">=" },
  // { name: 'contains', label: 'contains' },
  { name: "beginsWith", label: "begins with" },
  // { name: 'endsWith', label: 'ends with' },
  // { name: 'doesNotContain', label: 'does not contain' },
  // { name: 'doesNotBeginWith', label: 'does not begin with' },
  // { name: 'doesNotEndWith', label: 'does not end with' },
  { name: "isTrue", label: "is True" },
  { name: "isFalse", label: "is False" },
  { name: "null", label: "is null" },
  { name: "notNull", label: "is not null" },
  // { name: 'in', label: 'in' },
  // { name: 'notIn', label: 'not in' },
  { name: "between", label: "between" },
  { name: "startsAndEndsWith", label: "starts and ends with" },
  // { name: 'notBetween', label: 'not between' },
];

export const FIELDS = [
  {
    name: "thingName",
    label: "Vehicle Name",
    operators: [
      { name: "=", label: "=" },
      { name: "beginsWith", label: "begins with" },
      { name: "null", label: "is null" },
      { name: "notNull", label: "is not null" },
      { name: "startsandendswith", label: "starts and ends with" },
    ],
    //operators: NameLabelPair[] | OptionGroup[], // Array of operators (if not provided, then `getOperators()` will be used)
    //valueEditorType?: ValueEditorType, // Value editor type for this field (if not provided, then `getValueEditorType()` will be used)
    //inputType?: string | null, // Input type for text box inputs, e.g. 'text', 'number', or 'date' (if not provided, then `getInputType()` will be used)
    //values?: NameLabelPair[], // Array of values, applicable when valueEditorType is 'select' or 'radio' (if not provided, then `getValues()` will be used)
    //defaultOperator?: string, // Default operator for this field (if not provided, then `getDefaultOperator()` will be used)
    //defaultValue?: any, // Default value for this field (if not provided, then `getDefaultValue()` will be used)
    //placeholder?: string, // Value to be displayed in the placeholder of the text field
    //validator?: RuleValidator, // Called when a rule specifies this field
    //valueSources?: ValueSources | ((operator: string) => ValueSources), // List of allowed value sources (must contain "value", "field", or both)
    //comparator?: string | ((f: Field, operator: string) => boolean), // Determines which (other) fields to include in the list when valueSource is "field"
  },
  {
    name: "attributes.device_status",
    label: "Device Status",
    valueEditorType: "select",
    operators: [{ name: "=", label: "=" }],
    values: [
      { name: "online", label: "Online" },
      { name: "offline", label: "Offline" },
    ],
  },
  { name: "attributes.soc_version", label: "Software Version" },
  { name: "attributes.device_type", label: "Device Type" },
  { name: "attributes.vin", label: "VIN" },
  { name: "attributes.plant_code", label: "Plant Code" },
  { name: "attributes.platform", label: "Platform" },
  { name: "attributes.make", label: "Make" },
  { name: "attributes.model", label: "Model" },
  { name: "attributes.year", label: "Year" },
];

export function convertRulesToQueryString(rule) {
  let result = "";
  const { field, value = "", operator } = rule;
  switch (operator) {
    case "=":
      result = `${field}:${value}`;
      break;
    case "!=":
      result = `${field}:${value}`;
      break;

    case "beginsWith":
      result = `${field}:${value}*`;
      break;

    case "isTrue":
      result = `${field}:true`;
      break;
    case "isFalse":
      result = `${field}:false`;
      break;

    default:
      break;
  }
  return result;
}

export function convertRuleObjectToString(ruleObj) {
  if (ruleObj) {
    let resultingRules = {};
    const ruleObjString = JSON.stringify(ruleObj);
    const ruleString = ruleObjString
      .replaceAll("[", "/:s_q_s:/") // square bracket start
      .replaceAll("]", "/:s_q_e:/") // square bracket end
      .replaceAll("{", "/:c_u_s:/") // curly bracket start
      .replaceAll("}", "/:c_u_e:/") // curly bracket end
      .replaceAll('"', "/:d_b_q:/") // double quote
      .replaceAll(",", "/:c_o_m:/"); // comma

    if (ruleString.length > 800) {
      const parts = ruleString.split(null, 800);
      const items = parts.map((i, index) => {
        resultingRules[`ruleObject${index}`] = i;
        return i;
      });
    } else {
      resultingRules.ruleObject0 = ruleString;
    }
    return resultingRules;
  } else {
    return false;
  }
}

export function convertRuleStringToObject(ruleStringAttributes) {
  const ruleSet = Object.keys(ruleStringAttributes).filter((i) =>
    i.startsWith("ruleObject")
  );
  const ruleString = ruleSet.map((i) => ruleStringAttributes[i]).join("");
  if (!!ruleString) {
    const newString = ruleString
      .replaceAll("/:s_q_s:/", "[") // square bracket start
      .replaceAll("/:s_q_e:/", "]") // square bracket end
      .replaceAll("/:c_u_s:/", "{") // curly bracket start
      .replaceAll("/:c_u_e:/", "}") // curly bracket end
      .replaceAll("/:d_b_q:/", '"') // double quote
      .replaceAll("/:c_o_m:/", ","); // comma
    const result = JSON.parse(newString);
    return result;
  } else {
    return false;
  }
}

export function getMonthString(monthNumber) {
  let name = monthNumber?.toString();
  switch (monthNumber) {
    case 0:
      name = "january";
      break;
    case 1:
      name = "february";
      break;
    case 2:
      name = "march";
      break;
    case 3:
      name = "april";
      break;
    case 4:
      name = "may";
      break;
    case 5:
      name = "june";
      break;
    case 6:
      name = "july";
      break;
    case 7:
      name = "august";
      break;
    case 8:
      name = "september";
      break;
    case 9:
      name = "october";
      break;
    case 10:
      name = "november";
      break;
    case 11:
      name = "december";
      break;
    default:
      break;
  }
  return name;
}

export function getDateStringForDefaultName(dt) {
  const day = dt?.getDate()?.toString()?.padStart(2, 0);
  // const month = (dt.getMonth() + 1).toString().padStart(2, 0);
  const month = getMonthString(dt?.getMonth())?.slice(0, 3)?.toUpperCase();
  const year = dt?.toLocaleDateString("en", { year: "2-digit" });
  return year + month + day;
}

export function getTimeStringForDefaultName(dt) {
  const hours = dt?.getHours()?.toString()?.padStart(2, 0);
  const mins = dt?.getMinutes()?.toString()?.padStart(2, 0);
  const secs = dt?.getSeconds()?.toString()?.padStart(2, 0);
  return hours + mins + secs;
}

function getCustomerName() {
  const name = localStorage.getItem("selectedCustomer") || "";
  return name;
}

export function getDefaultDeploymentName() {
  const dt = new Date();
  const dateString = getDateStringForDefaultName(dt);
  const timeString = getTimeStringForDefaultName(dt);
  const name = `DEP${getCustomerName().toUpperCase()}_${dateString}_${timeString}`;
  return name;
}

export function getDefaultConfigName() {
  const dt = new Date();
  const dateString = getDateStringForDefaultName(dt);
  const timeString = getTimeStringForDefaultName(dt);
  const name = `CONF${getCustomerName().toUpperCase()}_${dateString}_${timeString}`;
  return name;
}

export function getDefaultReleaseName() {
  const dt = new Date();
  const dateString = getDateStringForDefaultName(dt);
  const timeString = getTimeStringForDefaultName(dt);
  const name = `REL${getCustomerName().toUpperCase()}_${dateString}_${timeString}`;
  return name;
}
