import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CardActionArea,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import SpokeIcon from "@mui/icons-material/Spoke";
import SdvUploadDialog from "./SdvUploadDialog";
import { useQuery } from "react-query";
import { getSdvFeatureDataCount } from "../../services/SdvFeature";

const SdvFeatureStats = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);

  const { data: { data: { sdvFeatureCount = 0 } = {} } = {} } = useQuery(
    "getSdvFeatureDataCount",
    getSdvFeatureDataCount,
    {
      refetchOnWindowFocus: false,
    }
  );

  const onClose = () => {
    setOpenModal(false);
  };

  const dialogProps = {
    openModal,
    onClose,
    setOpenModal,
  };

  return (
    <>
      <Grid item xs={6} md={2}>
        <Box>
          <Card>
            <CardContent className="tile-cards">
              <Typography color="text.secondary" gutterBottom>
                Total Features
              </Typography>
              <Typography
                color="text.primary"
                sx={{ fontSize: 30, fontWeight: 300 }}
                gutterBottom
              >
                {sdvFeatureCount}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item xs={6} md={2}>
        <CardActionArea onClick={handleOpen}>
          <div className="dotted-card">
            <p className="dotted-text">CREATE FEATURE</p>
            <div className="dotted-icon">
              <SpokeIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
            </div>
          </div>
        </CardActionArea>
      </Grid>
      <SdvUploadDialog {...dialogProps} />
    </>
  );
};
export default SdvFeatureStats;
