import React, {
    useContext,
    useEffect,
    useState
} from "react";
import { useMutation, useQueryClient } from "react-query";
import Lottie from 'react-lottie';
import deploymentLoadingAnim from "../../../lottieAnimations/deployment_loading.json";
import deploymentFailedAnim from "../../../lottieAnimations/deployment_failed.json";
import { WizardContext, ACTIONS, TABS } from "../Context";
import ConfigReviewDetails from "./ConfigReviewDetails";
import { getUserName } from "../../../services/Users";
import { useToast } from "../../toast";
import { createConfiguration } from "../../../services/configuration";

const ConfigReviewWizard = () => {
    const [successAnimation, setSuccessAnimation] = useState(false);
    const [failed, setFailed] = useState(false);
    const { state, dispatch } = useContext(WizardContext);
    const { addToast } = useToast();
    // Access the client
    const queryClient = useQueryClient();
    const {
        toggleVehicle,
        vehicles,
        vehicleGroups,
        launch,
        configState = {},
        configName,
        configDescription,
    } = state;

    const getPayload = async () => {
        const shadowData = {
            state: {
                desired: {
                    configurations: {
                        ...configState
                    }
                }
            }
        };
        return {
            configName,
            configDescription,
            configStatus: "NOT_PUBLISHED",
            createdBy: await getUserName(),
            configState: JSON.stringify(shadowData),
            campaignCreationDate: new Date(),
            serialNo: vehicles,
            vehicleGroups: vehicleGroups,
        };
    };

    // Mutations
    const {
        mutate: configMutate,
        isLoading: configLoading,
        error: configError,
    } = useMutation(createConfiguration, {
        onSuccess: (data) => {
            setSuccessAnimation(true);
            const { data: { configId = "" } = {} } = data || {};
            dispatch({
                type: ACTIONS.UPDATE_CONFIG_NAME,
                payload: {
                    configId,
                },
            });
            addToast({
                type: "success",
                message: "Configuration Created",
                autoClose: 5000,
            });
            // Invalidate and refetch
            queryClient.invalidateQueries("getConfigStats");
            queryClient.invalidateQueries("configurations");
        },
        onError: (err) => {
            addToast({
                type: 'error',
                message: err.response.data.message || 'Failed to create configuration',
                autoClose: 5000,
            });
            setFailed(true);
        },
        onSettled: () => {
            dispatch({
                type: ACTIONS.TOGGLE_BUTTON,
                payload: {
                    disableNextBtn: false,
                },
            });
        },
    });

    let vehicleState = false;
    if (toggleVehicle === TABS.CHOOSE_VEHICLES) {
        vehicleState = vehicles.length > 0;
    } else if (toggleVehicle === TABS.CHOOSE_VEHICLE_GROUPS) {
        vehicleState = vehicleGroups.length > 0;
    }

    const handleSubmission = async () => {
        if (vehicleState) {
            dispatch({
                type: ACTIONS.TOGGLE_BUTTON,
                payload: {
                    disableNextBtn: true,
                },
            });
            const payload = await getPayload();
            configMutate(payload);
        }
    };

    useEffect(() => {
        if (launch) {
            handleSubmission();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [launch]);

    if (
        configLoading ||
        successAnimation ||
        configError
    ) {
        let infoText = "Creating configuration...";
        if (successAnimation) {
            infoText = "Configuration created";
        }
        if (configError || failed) {
            infoText = "Configuration failed, try again later.";
        }
        if (failed) {
            return (
                <>
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: deploymentFailedAnim,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                        height={300}
                        width={300}
                    />
                    <p className="wizard-loading-text">{infoText}</p>
                </>
            );
        }
        return (
            <>
                <Lottie options={{
                    loop: true,
                    autoplay: true,
                    animationData: deploymentLoadingAnim,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                    height={300}
                    width={300}
                />
                <p className="wizard-loading-text">{infoText}</p>
            </>
        )
    }

    return <ConfigReviewDetails />;
};

export default ConfigReviewWizard;
