import React, { useState } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from "@mui/material";
import { getStatsForVehicleGroupsView } from "../../services/vehicle";
import { useQuery } from "react-query";
import { grey } from "@mui/material/colors";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import SpokeIcon from "@mui/icons-material/Spoke";
import CreateVehicleGroup from "./group/CreateVehicleGroup";
import CreateVehicleGroupFromCsv from "./group/CreateVehicleGroupFromCsv";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VehicleGroupsStats = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openModalCsv, setOpenModalCsv] = useState(false);
  const handleOpenCsv = () => setOpenModalCsv(true);
  const handleOpenCsvClose = () => setOpenModalCsv(false);

  const { data, isLoading } = useQuery(
    "getStatsForVehicleGroupsView",
    getStatsForVehicleGroupsView,
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      <Grid item xs={6} md={2}>
        <Box>
          <Card>
            <CardContent className="tile-cards">
              <Typography color="text.secondary" gutterBottom>
                Total Vehicle Groups
              </Typography>
              <Typography
                color="text.primary"
                sx={{ fontSize: 30, fontWeight: 300 }}
                gutterBottom
              >
                {data?.totalGroups || "0"}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Check I={CAN.CREATE} a={SUBJECTS.GROUP}>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpen}>
            <div className="dotted-card">
              <p className="dotted-text">CREATE GROUP</p>
              <div className="dotted-icon">
                <SpokeIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpenCsv}>
            <div className="dotted-card">
              <p className="dotted-text">CREATE GROUP FROM CSV</p>
              <div className="dotted-icon">
                <CloudUploadIcon
                  sx={{ fontSize: "3.5rem", color: grey[500] }}
                />
              </div>
            </div>
          </CardActionArea>
        </Grid>
      </Check>
      <Grid item xs={3}></Grid>
      {openModal && (
        <CreateVehicleGroup
          open={openModal}
          toggleDialog={() => setOpenModal(!openModal)}
        />
      )}
      {openModalCsv && (
        <CreateVehicleGroupFromCsv
          open={openModalCsv}
          toggleDialog={() => setOpenModalCsv(!openModalCsv)}
        />
      )}
    </>
  );
};

export default VehicleGroupsStats;
