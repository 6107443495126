import React, { useState, useContext, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Collapse,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Constants from "../../services/Constants";
import { getAppVersion } from "../../services/Dashboard";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { useTheme } from "@mui/material/styles";
import { Drawer } from "../Drawer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AppLogo } from "./AppLogo";
import { Box } from "@mui/system";
import { capitalize } from "../../services/Utils";
import { useQuery } from "react-query";

const SideNav = (props) => {
  const { pathname } = useLocation();
  const { openDrawer, setOpenDrawer } = props;
  const theme = useTheme();
  const selectedItemColor = theme?.custom?.selectedItemColor;

  const [activeMenuItem, setActiveMenuItem] = useState("");

  const findCurrentActiveMenuItem = (pathname) => {
    const itemPositions = Constants.NAV_ROUTES_PATH_LIST.filter((item) => {
      return pathname.toLowerCase().indexOf(item) > -1;
    })
      .map((i) => ({
        name: i,
        position: pathname.toLowerCase().indexOf(i),
      }))
      .sort(function (a, b) {
        return a.position - b.position;
      });
    if (itemPositions && itemPositions.length > 1) {
      if (itemPositions[0].name === "") {
        setActiveMenuItem(itemPositions[1].name);
      }
    } else {
      setActiveMenuItem(itemPositions[0].name);
    }
  };

  useEffect(() => {
    findCurrentActiveMenuItem(pathname);
  }, [pathname]);
  return (
    <Drawer
      open={openDrawer}
      onClose={(ev, reason) => setOpenDrawer(false)}
      variant="permanent"
      BackdropProps={{ invisible: true }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          // paddingTop: "48px",
        }}
      >
        {/* <div className="logo"></div> */}
        <AppLogo />

        <List>
          {Constants.NAV_ROUTES.map((item, index) => (
            <span key={index}>
              {item && item?.children?.length > 0 ? (
                <NavItemWithChildren
                  item={item}
                  activeMenuItem={activeMenuItem}
                  pathname={pathname}
                  selectedItemColor={selectedItemColor}
                />
              ) : (
                <NavItem
                  item={item}
                  activeMenuItem={activeMenuItem}
                  pathname={pathname}
                  selectedItemColor={selectedItemColor}
                />
              )}
            </span>
          ))}
        </List>

        <div
          className="bottom"
          style={{ height: "239px", display: "flex", alignItems: "flex-end" }}
        >
          <AppVersionDisplay />
        </div>
      </div>
    </Drawer>
  );
};

export { SideNav };

const NavItem = (props) => {
  const { item, activeMenuItem, isSubMenuItem, selectedItemColor } = props;
  return (
    <Check
      I={CAN.MANAGE}
      a={SUBJECTS.USER}
      bypass={item.path === "users" ? false : true}
      key={item.title}
    >
      <ListItemButton
        sx={isSubMenuItem ? { pl: 3.5 } : {}}
        button
        key={item.title}
        component={RouterLink}
        to={item.path}
        selected={activeMenuItem === item.path}
      >
        {activeMenuItem === item.path ? (
          <Box
            className="menu-item-selected"
            style={{ borderColor: selectedItemColor }}
          />
        ) : null}
        <ListItemIcon>
          <Tooltip placement="right" title={capitalize(item?.title)}>
            {item.icon}
          </Tooltip>
        </ListItemIcon>
        <ListItemText key={item.title} sx={{ textTransform: "capitalize" }}>
          {item.title}
        </ListItemText>
      </ListItemButton>
    </Check>
  );
};

const NavItemWithChildren = (props) => {
  const { item, activeMenuItem, selectedItemColor } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isSubMenuItemSelected =
      item?.children?.filter((i) => i?.path === activeMenuItem).length > 0;
    if (isSubMenuItemSelected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeMenuItem, item?.children]);

  return (
    <>
      <ListItemButton
        button
        key={item?.title}
        // component={RouterLink}
        // to={item?.path}
        selected={activeMenuItem === item?.path}
        onClick={() => setOpen(!open)}
      >
        {activeMenuItem === item?.path ? (
          <Box
            className="menu-item-selected"
            style={{ borderColor: selectedItemColor }}
          />
        ) : null}
        <ListItemIcon>
          <Tooltip placement="right" title={capitalize(item?.title)}>
            {item.icon}
          </Tooltip>
        </ListItemIcon>
        <ListItemText key={item?.title} sx={{ textTransform: "capitalize" }}>
          {item?.title}
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <SubNavList
        items={item?.children}
        open={open}
        activeMenuItem={activeMenuItem}
        selectedItemColor={selectedItemColor}
      />
    </>
  );
};

const SubNavList = (props) => {
  const { items, open, activeMenuItem, selectedItemColor } = props;
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items?.map((i) => (
          <NavItem
            key={i?.title}
            item={i}
            activeMenuItem={activeMenuItem}
            isSubMenuItem
            selectedItemColor={selectedItemColor}
          />
        ))}
      </List>
    </Collapse>
  );
};

const AppVersionDisplay = () => {
  const [version, setVersion] = useState("");
  const { isLoading } = useQuery("getAppVersion", getAppVersion, {
    onSuccess: ({ data }) => {
      console.log("Current app version is - ", data?.version?.RELEASE_VERSION);
      setVersion(data?.version?.RELEASE_VERSION); // Set the version value from the data to display.
    },
    refetchOnWindowFocus: false,
  });
  return (
    <div
      className="version-block"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography variant="caption" color="gray">
        {version || "v0.0.0"}
      </Typography>
    </div>
  );
};
