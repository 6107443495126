import React from "react";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import ReleaseGrid from "../components/release/ReleaseGrid";
import ReleaseStats from "../components/release/ReleaseStats";

import "../styles/release.css";

export default function Releases() {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <ReleaseStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Available Releases"></CardHeader>
            <CardContent sx={{ padding: 0 }}>
              <ReleaseGrid />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
