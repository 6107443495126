import React, { useEffect, useState } from "react";
import { getVehicleDataList } from "../../services/SdvFeature";
import OTAPagination from "../OTAPagination";

const VehicleListForSdvDataGrid = (props) => {
  const { sdvData, setSelectedVehiclesList, selectedVehiclesList } = props;
  const columns = [
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      width: 400,
    },
    {
      field: "serialNo",
      headerName: "Serial No",
      width: 400,
    },
  ];
  const getVehiclesListForSdvId = (params) => {
    const sdvFeatureId = sdvData.sdvFeatureId;
    return getVehicleDataList(params, sdvFeatureId);
  };

  return (
    <OTAPagination
      columns={columns}
      endpointFn={getVehiclesListForSdvId}
      queryName={"getVehiclesListForSdvId"}
      filterMode="server"
      sortByDefault={"serialNoCreatedAt"}
      getRowId={(row) => row.serialNo}
      isRowSelectable={(params) => !params.row.sdvVehicleFeatureIsAvailable}
      checkboxSelection
      selectionModel={selectedVehiclesList}
      onSelectionModelChange={(selectionModel) => {
        setSelectedVehiclesList(selectionModel);
      }}
    />
  );
};

export default VehicleListForSdvDataGrid;
