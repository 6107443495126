import React, { useState, Fragment, useContext } from "react";
import {
  Typography,
  Button,
  Stack,
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { TextField } from "@aws-amplify/ui-react";
import { createNewUser, getUserDetails } from "../../services/Users";
import { useToast } from "../../components/toast";
import { GROUPS } from "../../services/Constants";
import { WizardContext } from "../wizard/Context";
import { USER_GROUPS } from "../../auth/permissions";

const CreateUserForm = ({ onUserCreated }) => {
  const allRoles = new Set();
  const { state: { customerData = [] } = {} } = useContext(WizardContext);
  customerData.forEach((m) => {
    m?.module?.__roles__.forEach((r) => {
      allRoles.add(r?.roleId);
    })
  })
  const { addToast } = useToast();
  let defaultGroup = "ota-guest-group";
  const GroupsArray = Array.from(allRoles)
    .filter(r => ![USER_GROUPS.HD_ADMIN, USER_GROUPS.SUPER_ADMIN].includes(r))
    .map((i) => {
      const newGroup = { name: GROUPS[i], value: i };
      if (i === "ota-guest-group") {
        newGroup.selected = true;
        newGroup.disabled = true;
        defaultGroup = newGroup;
      }
      return newGroup;
    });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([defaultGroup]);

  const handleChange = (event) => {
    const { id } = event?.target;
    const foundItem = selectedGroups.filter((i) => i === id);
    if (foundItem && foundItem.length) {
      const newGroup = selectedGroups.filter((i) => i !== id);
      setSelectedGroups([...newGroup]);
    } else {
      const newGroup = selectedGroups.filter((i) => i !== id);
      setSelectedGroups([...newGroup, id]);
    }
  };

  const clearForm = () => {
    setName("");
    setEmail("");
  };

  const createUser = async () => {
    var params = {
      Username: email /* required */,
      DesiredDeliveryMediums: ["EMAIL"],
      ForceAliasCreation: false,
      UserAttributes: [
        {
          Name: "name" /* required */,
          Value: name,
        },
        {
          Name: "email" /* required */,
          Value: email,
        }
      ],
    };
    const userGroups = selectedGroups.map(group => {
      return {
        action: 'add',
        customer: localStorage?.selectedCustomer,
        group: group?.value
      }
    });
    const admin = await getUserDetails();
    const newUserResponse = await createNewUser({
      admin,
      params,
      userGroups
    });

    if (newUserResponse) {
      const { User, statusCode = "", message = "" } = newUserResponse;
      if (statusCode && statusCode !== 200 && !!message) {
        addToast({
          type: "error",
          message: message,
          autoClose: 3000,
        });
      } else if (User && !!User.Username) {
        clearForm();
        addToast({
          type: "success",
          message: `User created successfully. temporary password is sent to the user's email.`,
          autoClose: 3000,
        });
        onUserCreated();
      }
    } else {
      const { statusCode, message } = newUserResponse;
      if (statusCode && statusCode !== 200 && !!message) {
        addToast({
          type: "error",
          message: message,
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <Fragment>
      <Box>
        <Typography
          color="text.primary"
          sx={{ mt: 5 }}
          style={{ marginBottom: "18px" }}
        >
          Add Users and provide the right level of access to the roles.
        </Typography>
        <Stack spacing={2}>
          <TextField
            hasError={false}
            inputMode="text"
            isRequired={true}
            label="Name"
            placeholder=""
            type="text"
            wrap="nowrap"
            onChange={(e) => setName(e.currentTarget.value)}
            value={name}
          />
          <TextField
            label={"Email"}
            type="email"
            isRequired={true}
            onChange={(e) => setEmail(e.currentTarget.value)}
            value={email}
          />

          <FormControl sx={{ m: 1, width: "100%" }}>
            <FormLabel component="legend">Organization : {localStorage?.selectedCustomer}</FormLabel>
          </FormControl>

          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">{`Assign to Groups`}</FormLabel>
            <FormGroup>
              {GroupsArray.map((i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      id={i.value}
                      checked={i.selected}
                      onChange={(e) => handleChange(e)}
                      value={i}
                      name={i.name}
                      disabled={i.disabled}
                    />
                  }
                  label={i.name}
                />
              ))}
            </FormGroup>
          </FormControl>

          <Typography
            color="text.secondary"
            sx={{ mt: 5 }}
            style={{ marginBottom: "18px" }}
          >
            A temporary password will be generated and sent to the user's email
            on successfull creation of the user account.
          </Typography>

          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => createUser()}
            disabled={!name || !email}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default CreateUserForm;
