import React, { memo, useEffect, useState } from "react";
import { styled } from "@mui/system";

const SimulationViewer = ({ id, src }) => {
  // const [content, setContent] = useState(null);
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const Viewer = styled("iframe")(
    ({ theme }) => `
    width: 100%;    
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
		
		#simulation-viewer canvas#canvas {
			width: -webkit-fill-available;
		}
  `
  );

  // fetch(src)
  //   .then((response) => response.text())
  //   .then((htmlContent) => {
  //     // Create HTML node from the content
  //     const template = document.createElement("template");
  //     template.innerHTML = htmlContent;
  //     const node = template.content.firstChild;

  //     // Now you can use the 'node' variable
  //     // console.log(node);
  //     // document.getElementById("simulation-viewer").appendChild(node);
  //     setContent(node);
  //   })
  //   .catch((error) => console.error("Error:", error));

  // useEffect(() => {
  //   fetch(src)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.text();
  //     })
  //     .then((data) => {
  //       setContent(data);
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "There has been a problem with your fetch operation:",
  //         error
  //       );
  //     });
  // }, [src]);

  useEffect(() => {
    const iframe = document.getElementById(id || "simulation-viewer");
    iframe.contentWindow.postMessage(
      {
        action: "applyStyles",
        styles: "body { background-color: lightyellow; color: darkred; }",
      },
      "*"
    );
  }, [id]);

  return (
    <>
      <Viewer
        id={id || "simulation-viewer"}
        type="text/html"
        src={src}
        width="100%"
        height="100%"
      />
      {/* <html
        id="simulation-viewer"
        style={{ width: "100%", height: "100%" }}
        dangerouslySetInnerHTML={{ __html: content }}
      ></html> */}
    </>
  );
};

export default memo(SimulationViewer);
