import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import {
  useParams,
  Link as RouterLink,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import {
  Typography,
  Stack,
  Paper,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
  Box,
  Grid,
  CardContent,
  Icon,
  Card,
  CardHeader,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import UserAvatarCard from "../components/user/UserAvatarCard";
import UserActivityCalendarView from "../components/charts/UserActivityCalendarView";
import UserActivityList from "../components/user/UserActivityList";
import UserGroupList from "../components/user/UserGroupList";
import { getUserById } from "../services/Users";
import { getCurrentPathTab, getInitialsForAvatar } from "../services/Utils";
import { getUserDetails, getCustomerDetails } from "../services/Users";
import BreadCrumbsRendered from "../components/BreadCrumbsRendered";
import { WizardContext } from "../components/wizard/Context";

const PATHS = ["overview", "activity", "groups", "customer"];

const CustomerDetail = styled.div`
  margin-bottom: 2%;
  margin-top: 2%;
  margin-left: 2%;
`;

export default function User(props) {
  const { isMyProfile = false } = props;
  const { state } = useContext(WizardContext);
  const { modules: currentUserData } = state;
  let params = useParams();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, "overview")
  );

  const { data: userData } = useQuery(
    ["userData", params?.userId],
    isMyProfile ? getUserDetails : getUserById,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data } = useQuery(
    ["customerData", params?.userId],
    getCustomerDetails,
    {
      refetchOnWindowFocus: false,
      enabled: !isMyProfile,
    }
  );

  const modules = isMyProfile ? currentUserData : data;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(getCurrentPathTab(pathname, PATHS, "overview"));
  }, [pathname]);

  return (
    <>
      {!!userData && (
        <div className="fragmentContainer">
          <BreadCrumbsRendered pathname={pathname} />
          <UserAvatarCard data={userData} />
          <Box sx={{ width: "100%", marginTop: 4 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  label="Overview"
                  value={"overview"}
                  component={RouterLink}
                  to={"overview"}
                />
                <Tab
                  label="Activity"
                  value={"activity"}
                  component={RouterLink}
                  to={"activity"}
                />
                <Tab
                  label="Groups"
                  value={"groups"}
                  component={RouterLink}
                  to={"groups"}
                />
                <Tab
                  label="Customer"
                  value={"customer"}
                  component={RouterLink}
                  to={"customer"}
                />
              </Tabs>
            </Box>
            <div className="user-tabs" style={{ padding: "32px" }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Overview
                      isMyProfile={isMyProfile}
                      data={userData}
                      theme={theme}
                    />
                  }
                />
                <Route
                  path="overview"
                  element={
                    <Overview
                      isMyProfile={isMyProfile}
                      data={userData}
                      theme={theme}
                    />
                  }
                />
                <Route
                  path="activity"
                  element={
                    <Activity isMyProfile={isMyProfile} data={userData} />
                  }
                />
                <Route
                  path="groups"
                  element={
                    <Groups
                      isMyProfile={isMyProfile}
                      data={userData}
                      theme={theme}
                    />
                  }
                />
                <Route
                  path="customer"
                  element={
                    <Modules
                      isMyProfile={isMyProfile}
                      data={modules}
                      theme={theme}
                    />
                  }
                />
              </Routes>
            </div>
          </Box>
        </div>
      )}
    </>
  );
}

const Overview = (props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <UserActivityList showItems={5} showHeader {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {props?.data?.cognitoId && <UserGroupList {...props} />}
        </Grid>
      </Grid>
    </div>
  );
};

const Activity = (props) => {
  return (
    <div>
      <Stack spacing={2}>
        <UserActivityCalendarView userId={props?.data?.userId} />
        <UserActivityList {...props} />
      </Stack>
    </div>
  );
};

const Groups = (props) => {
  if (!props?.data?.cognitoId) {
    return null;
  }
  return (
    <div>
      <UserGroupList {...props} />
    </div>
  );
};

const renderModules = (props) => {
  const { data = [], theme } = props || {};
  return data.map((customer) => {
    const { modules = [], license = {} } = customer;
    return (
      <>
        <Grid item xs={6}>
          <Card>
            <CardHeader
              title={
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: 16 }}
                  gutterBottom
                  color="text.secondary"
                >
                  {license.customerId} Customer Details
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <CustomerDetail>
                <Typography color="text.primary">
                  License Type : {license.licenseType}
                </Typography>
              </CustomerDetail>
              <Divider />
              <CustomerDetail>
                <Typography color="text.primary">
                  License Expiry : {license.expiryDate || "N/A"}
                </Typography>
              </CustomerDetail>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader
              title={
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: 16 }}
                  gutterBottom
                  color="text.secondary"
                >
                  List of modules enabled for {license.customerId} (
                  {modules.length})
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <List
                sx={{
                  width: "100%",
                  margin: 0,
                  padding: 0,
                  bgcolor: "background.paper",
                }}
              >
                {modules.map((m) => (
                  <ListItem alignItems="flex-end">
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: theme.palette.primary[400] }}>
                        <DoneOutlineIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ fontSize: 12, textTransform: "capitalize" }}
                    >
                      {m?.module?.moduleName}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </>
    );
  });
};

const Modules = (props) => {
  return (
    <Grid container spacing={4}>
      {renderModules(props)}
    </Grid>
  );
};
