import React, { Fragment, useState } from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useMutation, useQueryClient } from "react-query";
import { createRelease } from "../../services/Release";
import TextField from "@mui/material/TextField";
import ImageChecklist from "../../components/image/ImageChecklist";
import { getUserName } from "../../services/Users";
import { getDefaultReleaseName } from "../vehicle/Utils";

const CreateReleasePopup = ({ onReleaseCreated }) => {
  const [releaseName, setReleaseName] = useState(
    getDefaultReleaseName()
  );
  const [files, setFiles] = useState([]);
  const [isNameError, setIsNameError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [isReleaseNameInvalid, setIsReleaseNameInvalid] = useState(true);
  const [alertOpen, setAlertOpen] = useState(null);

  const queryClient = useQueryClient();

  const { mutate: mutateRelease, isLoading } = useMutation(createRelease, {
    onSuccess: (data) => {
      console.log("success saving release data");
      console.log(data);
      onReleaseCreated();
    },
    onError: (err) => {
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        setAlertOpen(errorData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries("releases");
    },
  });

  const validateReleaseName = (name) => {
    if (!!name) {
      const re = /^[a-zA-Z0-9_-]+$/;
      if (!re.test(name)) {
        setIsNameError(true);
        setHelperText(
          `White space and special characters not allowed, except underscores (_) and hypens (-).`
        );
        setIsReleaseNameInvalid(true);
      } else if (name.length > 32) {
        setIsNameError(true);
        setHelperText(
          "Maximum length of the name cannot be over 32 characters!"
        );
        setIsReleaseNameInvalid(true);
      } else {
        setIsNameError(false);
        setHelperText("");
        setIsReleaseNameInvalid(false);
      }
    } else {
      setIsNameError(false);
      setHelperText("");
      setIsReleaseNameInvalid(true);
    }
  };

  const handleSubmission = async () => {
    mutateRelease({
      releaseName: releaseName,
      imageIds: files,
      createdBy: await getUserName(),
    });
  };

  const isImageNotSelected = () => files.length === 0;

  return (
    <Fragment>
      <Box>
        <TextField
          id="Release name"
          required
          label="Release Name"
          variant="standard"
          value={releaseName}
          onInput={(e) => {
            setReleaseName(e.target.value);
            validateReleaseName(e.target.value);
          }}
          error={isNameError}
          helperText={helperText}
          fullWidth
        />
        <Typography color="text.primary" sx={{ mt: 5 }}>
          Choose images to create a New Release
        </Typography>
        <div className="release-list-wrapper">
          <ImageChecklist onValueUpdated={(images) => setFiles(images)} />
        </div>
        <Box>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={handleSubmission}
            disabled={isReleaseNameInvalid || isImageNotSelected()}
          >
            Create Release
          </Button>
        </Box>
      </Box>
      {alertOpen && (
        <Slide direction="left" in={!!alertOpen} mountOnEnter unmountOnExit>
          <Alert
            severity="error"
            style={{ position: "fixed", top: "68px", right: "32px" }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(null);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alertOpen.message}
          </Alert>
        </Slide>
      )}
    </Fragment>
  );
};

export default CreateReleasePopup;
