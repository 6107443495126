import React, { useEffect, useState } from "react";
import { getSdvVehicleFeatureList } from "../../services/SdvFeature";
import OTAPagination from "../OTAPagination";
import { capitalize } from "../../services/Utils";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const SdvVehicleFeatureDataGrid = (props) => {
  const { sdvFeatureId, setSelectedRows, selectedRows } = props;
  const columns = [
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      width: 400,
    },
    {
      field: "feature_status",
      headerName: "Feature Status",
      width: 200,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      sortable: true,
      valueFormatter: ({ value }) => capitalize(value),
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      flex: 1,
      sortable: true,
      valueFormatter: ({ value }) => capitalize(value),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      editable: false,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.createdAt} />
      ),
    },
  ];

  const getSdvVehicleFeatureListForSdvId = (params) => {
    return getSdvVehicleFeatureList(params, sdvFeatureId);
  };

  return (
    <OTAPagination
      columns={columns}
      endpointFn={getSdvVehicleFeatureListForSdvId}
      queryName={"getSdvVehicleFeatureListForSdvId"}
      filterMode="server"
      sortByDefault={"createdAt"}
      getRowId={(row) => row.vehicleKey}
      checkboxSelection
      selectionModel={selectedRows}
      onSelectionModelChange={(selectionModel) => {
        setSelectedRows(selectionModel);
      }}
    />
  );
};

export default SdvVehicleFeatureDataGrid;
