import React, { useState, Fragment } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import { useMutation, useQuery } from "react-query";
import Constants, {
  FILE_TYPES,
  IMAGE_UPLOAD_ERRORS,
} from "../../services/Constants";
import VideoFileOutlinedIcon from "@mui/icons-material/VideoFileOutlined";
import AndroidOutlinedIcon from "@mui/icons-material/AndroidOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import uploadFileUsingS3Api from "../../services/AwsFileUploader.js";
import UploadListItemData from "./CreateSdvFeatureForm";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {
  createSDVFeature,
  getServerConfiguration,
  updateSDVFeatureUploadStatus,
} from "../../services/SdvFeature";
import { useToast } from "../toast";
const SdvUploadDialog = ({
  openModal,
  setOpenModal,
  onClose,
  resumeUpload,
}) => {
  const REQUIRED_FILES_TO_UPLOAD = [
    {
      uploadType: "metadata",
      fileType: FILE_TYPES.JSON,
      uploadText: "Upload metadata file.",
      icon: <DescriptionOutlinedIcon color="primary" />,
      resumeUploadId: resumeUpload?.sdvFeatureId || null,
    },
    {
      uploadType: "apk",
      fileType: FILE_TYPES.APK,
      uploadText: "Upload APK file.",
      icon: <AndroidOutlinedIcon color="primary" />,
      resumeUploadId: resumeUpload?.sdvFeatureId || null,
    },
    {
      uploadType: "image",
      fileType: FILE_TYPES.IMAGE,
      uploadText: "Upload image file.",
      icon: <ImageOutlinedIcon color="primary" />,
      resumeUploadId: resumeUpload?.sdvFeatureId || null,
    },
    {
      uploadType: "video",
      fileType: FILE_TYPES.VIDEO,
      uploadText: "Upload preview video file.",
      icon: <VideoFileOutlinedIcon color="primary" />,
      resumeUploadId: resumeUpload?.sdvFeatureId || null,
    },
  ];
  const [fileUploadFunctions, setFileUploadFunctions] = useState({
    image: null,
    video: null,
    apk: null,
  });

  const [uploadFileMetaData, setUploadFileMetaData] = useState({
    image: {
      imageFileName: null,
      imageFileType: null,
      imageFileSize: null,
    },
    video: {
      videoFileName: null,
      videoFileType: null,
      videoFileSize: null,
    },
    apk: {
      apkFileName: null,
      apkFileType: null,
      apkFileSize: null,
    },
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const handleClose = () => {
    setFeatureName("");
    setdescription("");
    setIsDisabled(true);
    setIsInputDataMissing(true);
    setIsUploadInProgress(false);
    setFileUploadFunctions({
      image: null,
      video: null,
      apk: null,
    });
    setUploadFileMetaData({
      image: {
        imageFileName: null,
        imageFileType: null,
        imageFileSize: null,
      },
      video: {
        videoFileName: null,
        videoFileType: null,
        videoFileSize: null,
      },
      apk: {
        apkFileName: null,
        apkFileType: null,
        apkFileSize: null,
      },
    });
    onClose?.();
  };
  const [description, setdescription] = useState("");
  const [featureName, setFeatureName] = useState("");
  const [metaDataFile, setMetaDataFile] = useState("");
  const [isInputDataMissing, setIsInputDataMissing] = useState(true);
  const { addToast } = useToast();
  let { UPLOAD_STATUS: UploadStatus } = Constants;
  const [imageRepoBucket, setImageRepoBucket] = useState("");

  const assignTypeFunctions = (type, func = null) => {
    fileUploadFunctions[type] = func;
    let resumeTypes = ["video", "image"];
    if (!!resumeUpload?.sdvFeatureId) {
      resumeTypes = resumeUpload?.uploadTypes;
      setIsInputDataMissing(false);
    }
    const notAvailable = resumeTypes.filter((type) => {
      return type !== "apk" && fileUploadFunctions[type] === null;
    });
    if (notAvailable?.length) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  useQuery("getServerConfig", getServerConfiguration, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: serverConfig = {} } = data;
      setImageRepoBucket(serverConfig.s3.IMAGE_UPLOAD_BUCKET);
    },
    onError: (err) => {
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  const imageUploadAction = async (data) => {
    const { sdvFeatureId } = data;
    const videoUpload = fileUploadFunctions["video"];
    const imageUpload = fileUploadFunctions["image"];
    const apkUpload = fileUploadFunctions["apk"];
    videoUpload?.(sdvFeatureId);
    imageUpload?.(sdvFeatureId);
    apkUpload?.(sdvFeatureId);
  };

  const { mutate: mutateImage, isLoading } = useMutation(createSDVFeature, {
    onSuccess: async ({ data }) => {
      imageUploadAction(data);
    },
    onError: (err) => {
      //Api call error block
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
    onSettled: () => {},
  });

  const { mutate: updateUploadStatus } = useMutation(
    updateSDVFeatureUploadStatus,
    {
      onSuccess: async ({ data }) => {
        const { isActive } = data;
        if (isActive) {
          addToast({
            type: "success",
            message: `Uploaded successfully.`,
            autoClose: 3000,
          });
          handleClose();
        }
      },
      onError: (err) => {},
      onSettled: () => {},
    }
  );

  const updateImgUploadStatus = (uploadId, uploadType, uploadStatus) => {
    const payload = {
      sdvFeatureId: uploadId,
      uploadType,
      uploadStatus,
    };
    updateUploadStatus(payload);
  };

  const assignFileUploadMetaData = (selectedFile, type) => {
    switch (type) {
      case "image":
        setUploadFileMetaData({
          ...uploadFileMetaData,
          image: {
            imageFileName: selectedFile?.name,
            imageFileType: selectedFile?.type,
            imageFileSize: selectedFile?.size.toString(),
          },
        });
        break;
      case "video":
        setUploadFileMetaData({
          ...uploadFileMetaData,
          video: {
            videoFileName: selectedFile?.name,
            videoFileType: selectedFile?.type,
            videoFileSize: selectedFile?.size.toString(),
          },
        });
        break;
      case "apk":
        setUploadFileMetaData({
          ...uploadFileMetaData,
          apk: {
            apkFileName: selectedFile?.name,
            apkFileType: selectedFile?.type,
            apkFileSize: selectedFile?.size.toString(),
          },
        });
        break;
      default:
        console.log("type not matched");
    }
  };

  const assignFileUploadFunction = (
    type,
    selectedFile,
    stsToken,
    setUploadInstance,
    setUploadProgress,
    setErrorMsg,
    errorMsg,
    setUploadStatus
  ) => {
    if (!!type && selectedFile !== null && !!stsToken) {
      assignFileUploadMetaData(selectedFile, type);
      const uploadFileFunc = async (id) => {
        try {
          const result = await uploadFileUsingS3Api({
            bucket: `${imageRepoBucket}`,
            key: `${type}/${id}`,
            selectedFile,
            onInit: (instance) => {
              setUploadInstance?.(instance || null);
            },
            onProgress: (progress) => {
              setUploadStatus(UploadStatus.InProgress);
              if (progress?.part === 2) {
                updateImgUploadStatus(id, type, UploadStatus.InProgress);
              }
              setUploadProgress({
                ...progress,
                uploadPct: progress.loaded / progress.total,
              });
            },
            creds: stsToken,
          });
          if (result) {
            setUploadStatus(UploadStatus.Completed);
            updateImgUploadStatus(id, type, UploadStatus.Completed);
          }
        } catch (error) {
          const { message = IMAGE_UPLOAD_ERRORS.SOMETHING_WENT_WRONG } = error;
          setErrorMsg(message);
          addToast({
            type: "error",
            message: error.message,
            autoClose: 4000,
          });
          handleClose();
        }
      };
      assignTypeFunctions(type, uploadFileFunc);
    } else if (!!type && selectedFile === null) {
      assignTypeFunctions(type, null);
    }
  };

  const handleSubmission = async () => {
    const body = {
      sdvFeatureName: featureName,
      description: description,
      apkFileName: uploadFileMetaData["apk"].apkFileName,
      apkFileType: uploadFileMetaData["apk"].apkFileType,
      apkFileSize: uploadFileMetaData["apk"].apkFileSize,
      apk_uploadStatus: UploadStatus.Started,
      imageFileName: uploadFileMetaData["image"].imageFileName,
      imageFileType: uploadFileMetaData["image"].imageFileType,
      imageFileSize: uploadFileMetaData["image"].imageFileSize,
      image_uploadStatus: UploadStatus.Started,
      videoFileName: uploadFileMetaData["video"].videoFileName,
      videoFileType: uploadFileMetaData["video"].videoFileType,
      videoFileSize: uploadFileMetaData["video"].videoFileSize,
      video_uploadStatus: UploadStatus.Started,
      metaDataInfo: metaDataFile ? metaDataFile : {},
      isActive: false,
    };

    setIsUploadInProgress(true);
    if (!!resumeUpload?.sdvFeatureId) {
      imageUploadAction({ sdvFeatureId: resumeUpload?.sdvFeatureId });
    } else {
      mutateImage(body);
    }
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        scroll="paper"
        PaperProps={{
          style: {
            maxHeight: "90%",
            minWidth: "500px",
          },
        }}
      >
        <DialogTitle>
          {!!resumeUpload?.sdvFeatureId
            ? "Resume upload"
            : "Create new Feature"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Fragment>
            {" "}
            <Typography
              color="text.primary"
              sx={{ mt: 1 }}
              style={{ marginBottom: "10px" }}
            >
              Add New Feature for Companion App.
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={12}>
                {" "}
                <Stack spacing={2}>
                  {!!resumeUpload?.sdvFeatureId ? null : (
                    <>
                      <TextField
                        hasError={false}
                        inputMode="text"
                        isRequired={true}
                        label="Name"
                        placeholder=""
                        type="text"
                        wrap="nowrap"
                        onChange={(e) => {
                          const val = e.currentTarget.value;
                          setFeatureName(val);
                          setIsInputDataMissing(!val || !description);
                        }}
                        value={featureName}
                      />
                      <TextField
                        label={"Description"}
                        type="text"
                        isRequired={true}
                        onChange={(e) => {
                          const val = e.currentTarget.value;
                          setdescription(val);
                          setIsInputDataMissing(!val || !featureName);
                        }}
                        value={description}
                      />
                    </>
                  )}

                  <List>
                    {REQUIRED_FILES_TO_UPLOAD?.filter((item) => {
                      if (!!item?.resumeUploadId) {
                        const resumeTypes = resumeUpload?.uploadTypes;
                        if (resumeTypes.indexOf(item.uploadType) > -1) {
                          return true;
                        } else {
                          return false;
                        }
                      } else {
                        return true;
                      }
                    }).map((item) => (
                      <Paper elevation={3} sx={{ minWidth: 250, marginTop: 2 }}>
                        <div key={item?.uploadText}>
                          <UploadListItemData
                            {...item}
                            {...{
                              assignFileUploadFunction,
                            }}
                            setMetaDataFile={setMetaDataFile}
                            isUploadInProgress={isUploadInProgress}
                          />
                        </div>
                      </Paper>
                    ))}
                  </List>
                </Stack>
              </Grid>
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => handleSubmission()}
            disabled={isDisabled || isUploadInProgress || isInputDataMissing}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SdvUploadDialog;
