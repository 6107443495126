export const sampleImagesList = {
  res: [
    {
      clusterId: "3187d676-6c92-477b-8fdc-1a61e5bdecc9",
      fileName: "tata_update_pkg_18923.iso",
      fileType: "application/x-cd-image",
      fileSize: "580577280",
      campaignType: "OTHERS",
      version: "1.0.17",
      uploadStatus: "Completed",
      changeLog: "",
      updatedAt: "2023-09-22T08:47:46.189Z",
      createdAt: "2023-09-22T08:46:24.325Z",
      createdById: "106c3eb3-998a-44b5-aadb-c69edfb9087c",
      updatedById: "106c3eb3-998a-44b5-aadb-c69edfb9087c",
      customerId: "HD",
      __user__: {
        userId: "106c3eb3-998a-44b5-aadb-c69edfb9087c",
        cognitoId: "1211ad1e-e732-46f2-a73e-f4d90145e265",
        userName: "Baral",
        email: "pbaral@visteon.com",
        userType: "app_user",
        createdAt: "2023-09-20T06:08:28.805Z",
        updatedAt: "2023-09-20T06:08:28.805Z",
      },
      __has_user__: true,
      uploadedBy: "Baral",
    },
    {
      clusterId: "4cd0108f-e5ea-4549-9391-402537d96de0",
      fileName: "tata_update_pkg_18923.iso",
      fileType: "application/x-cd-image",
      fileSize: "580577280",
      campaignType: "OTHERS",
      version: "1.0.10",

      uploadStatus: "Started",
      changeLog: "",
      updatedAt: "2023-09-22T08:44:49.603Z",
      createdAt: "2023-09-22T08:44:49.603Z",
      createdById: "106c3eb3-998a-44b5-aadb-c69edfb9087c",
      updatedById: "106c3eb3-998a-44b5-aadb-c69edfb9087c",
      customerId: "HD",
      __user__: {
        userId: "106c3eb3-998a-44b5-aadb-c69edfb9087c",
        cognitoId: "1211ad1e-e732-46f2-a73e-f4d90145e265",
        userName: "Baral",
        email: "pbaral@visteon.com",
        userType: "app_user",
        createdAt: "2023-09-20T06:08:28.805Z",
        updatedAt: "2023-09-20T06:08:28.805Z",
      },
      __has_user__: true,
      uploadedBy: "Baral",
    },
    {
      clusterId: "d9c9bb19-7747-487d-bedf-4ccd11d3f86a",
      fileName: "cloud1.png",
      fileType: "image/png",
      fileSize: "12477",
      campaignType: "OTHERS",
      version: "1.0.0",

      uploadStatus: "Completed",
      changeLog: "",
      updatedAt: "2023-09-18T09:39:25.380Z",
      createdAt: "2023-09-18T09:39:22.470Z",
      createdById: "8a553109-9c92-4e35-8bba-08a5a38aab63",
      updatedById: "8a553109-9c92-4e35-8bba-08a5a38aab63",
      customerId: "HD",
      __user__: {
        userId: "8a553109-9c92-4e35-8bba-08a5a38aab63",
        cognitoId: "c3dc7e10-04cc-4b93-879e-7950f2e7c1de",
        userName: "Anandraj Elangovan",
        email: "aelango4@visteon.com",
        userType: "app_user",
        createdAt: "2022-12-27T12:59:55.198Z",
        updatedAt: "2023-09-12T06:31:33.405Z",
      },
      __has_user__: true,
      uploadedBy: "Anandraj Elangovan",
    },
    {
      clusterId: "c1fd2fb2-39cb-4f44-8b37-5373367fb3e8",
      fileName: "HD_Demo.MOV",
      fileType: "video/quicktime",
      fileSize: "199571121",
      campaignType: "OTHERS",
      version: "1.1.1",

      uploadStatus: "Completed",
      changeLog: "",
      updatedAt: "2023-06-15T13:41:27.393Z",
      createdAt: "2023-06-15T13:41:12.559Z",
      createdById: "3a2315d0-7007-4bbb-b95c-904960b2d1ad",
      updatedById: "3a2315d0-7007-4bbb-b95c-904960b2d1ad",
      customerId: "HD",
      __user__: {
        userId: "3a2315d0-7007-4bbb-b95c-904960b2d1ad",
        cognitoId: "024e04f7-8702-4160-8faf-e2efd6258e3f",
        userName: "pradeepsrinivas balakrishnan",
        email: "pbalakr3@visteon.com",
        userType: "app_user",
        createdAt: "2022-12-27T12:59:55.198Z",
        updatedAt: "2023-09-20T06:07:10.384Z",
      },
      __has_user__: true,
      uploadedBy: "pradeepsrinivas balakrishnan",
    },
    {
      clusterId: "cbb8f9b0-8309-4757-bd4c-4e1057ab054e",
      fileName: "849mb_file_1.pdf",
      fileType: "application/pdf",
      fileSize: "890649900",
      campaignType: "OTHERS",
      version: "1.0.2",

      uploadStatus: "Completed",
      changeLog: "",
      updatedAt: "2023-06-12T15:58:13.132Z",
      createdAt: "2023-06-12T15:55:16.833Z",
      createdById: "0c7670b4-7d80-4aba-868d-d07a6653e6aa",
      updatedById: "0c7670b4-7d80-4aba-868d-d07a6653e6aa",
      customerId: "HD",
      __user__: {
        userId: "0c7670b4-7d80-4aba-868d-d07a6653e6aa",
        cognitoId: "efe3acca-ecb5-4cbc-b1c2-dc91ba09d57e",
        userName: "THIRU",
        email: "veluthirumlairaja_dup@gmail.com",
        userType: "app_user",
        createdAt: "2022-12-27T12:59:55.198Z",
        updatedAt: "2023-08-01T08:12:51.262Z",
      },
      __has_user__: true,
      uploadedBy: "THIRU",
    },
    {
      clusterId: "44f4a5d8-b00d-436c-b067-f5d610034ddf",
      fileName: "INSTANA_235001703D.iso",
      fileType: "",
      fileSize: "4014739456",
      campaignType: "SMART_CORE",
      version: "INSTANA_235001703D",

      uploadStatus: "Completed",
      changeLog: "",
      updatedAt: "2023-06-07T20:00:43.705Z",
      createdAt: "2023-06-07T20:00:43.705Z",
      createdById: "7688bd9d-7f86-413a-a8ef-57d37fccdaf8",
      updatedById: "7688bd9d-7f86-413a-a8ef-57d37fccdaf8",
      customerId: "HD",
      __user__: {
        userId: "7688bd9d-7f86-413a-a8ef-57d37fccdaf8",
        cognitoId: "d9c8a000-099b-4881-ae50-a660eea09d19",
        userName: "bsivakum@visteon.com",
        email: "bsivakum@visteon.com",
        userType: "app_user",
        createdAt: "2023-06-01T15:45:46.577Z",
        updatedAt: "2023-06-01T15:45:46.577Z",
      },
      __has_user__: true,
      uploadedBy: "bsivakum@visteon.com",
    },
    {
      clusterId: "26968546-4dce-4e46-971d-025af17a4586",
      fileName: "INSTANA_235001704D.iso",
      fileType: "",
      fileSize: "4014764032",
      campaignType: "SMART_CORE",
      version: "INSTANA_235001704D",

      uploadStatus: "Completed",
      changeLog: "",
      updatedAt: "2023-06-07T12:06:49.073Z",
      createdAt: "2023-06-07T12:06:49.073Z",
      createdById: "65a53e52-51db-4c7a-a724-c0df51f92227",
      updatedById: "65a53e52-51db-4c7a-a724-c0df51f92227",
      customerId: "HD",
      __user__: {
        userId: "65a53e52-51db-4c7a-a724-c0df51f92227",
        cognitoId: "a2ebb0dd-6028-42cd-930b-aa78c4cd686f",
        userName: "Shanthi Priya",
        email: "spriyai@visteon.com",
        userType: "app_user",
        createdAt: "2022-12-29T12:41:44.243Z",
        updatedAt: "2022-12-29T12:41:44.243Z",
      },
      __has_user__: true,
      uploadedBy: "Shanthi Priya",
    },
    {
      clusterId: "953714f6-6009-44c9-9fa3-876111ce2f4b",
      fileName: "provisioningdata.jpg",
      fileType: "image/jpeg",
      fileSize: "103103",
      campaignType: "OTHERS",
      version: "12.3.2",

      uploadStatus: "Completed",
      changeLog: "",
      updatedAt: "2023-06-06T10:02:50.815Z",
      createdAt: "2023-06-06T10:02:50.815Z",
      createdById: "4cf45327-b05c-425f-8909-423e880cebb7",
      updatedById: "4cf45327-b05c-425f-8909-423e880cebb7",
      customerId: "HD",
      __user__: {
        userId: "4cf45327-b05c-425f-8909-423e880cebb7",
        cognitoId: "e73fe7de-c9b1-4a4a-b57c-6634673bedfa",
        userName: "Govindaraj Sanjeevi",
        email: "gsanjeev@visteon.com",
        userType: "app_user",
        createdAt: "2022-12-27T12:59:55.198Z",
        updatedAt: "2023-09-14T09:31:51.136Z",
      },
      __has_user__: true,
      uploadedBy: "Govindaraj Sanjeevi",
    },
    {
      clusterId: "e37ab31a-baff-4098-8080-c63da116776c",
      fileName: "INSTANA_235001103D.iso",
      fileType: "",
      fileSize: "11751424",
      campaignType: "SMART_CORE",
      version: "5.0.0",

      uploadStatus: "Completed",
      changeLog: "",
      updatedAt: "2023-06-01T16:02:12.526Z",
      createdAt: "2023-06-01T16:02:12.526Z",
      createdById: "65a53e52-51db-4c7a-a724-c0df51f92227",
      updatedById: "65a53e52-51db-4c7a-a724-c0df51f92227",
      customerId: "HD",
      __user__: {
        userId: "65a53e52-51db-4c7a-a724-c0df51f92227",
        cognitoId: "a2ebb0dd-6028-42cd-930b-aa78c4cd686f",
        userName: "Shanthi Priya",
        email: "spriyai@visteon.com",
        userType: "app_user",
        createdAt: "2022-12-29T12:41:44.243Z",
        updatedAt: "2022-12-29T12:41:44.243Z",
      },
      __has_user__: true,
      uploadedBy: "Shanthi Priya",
    },
    {
      clusterId: "f26cddc8-be8a-4e5a-9994-7a0ab04665dd",
      fileName: "INSTANA_235001103D.iso",
      fileType: "",
      fileSize: "11751424",
      campaignType: "SMART_CORE",
      version: "4.0.0",

      uploadStatus: "Completed",
      changeLog: "",
      updatedAt: "2023-05-31T12:30:41.160Z",
      createdAt: "2023-05-31T12:30:41.160Z",
      createdById: "65a53e52-51db-4c7a-a724-c0df51f92227",
      updatedById: "65a53e52-51db-4c7a-a724-c0df51f92227",
      customerId: "HD",
      __user__: {
        userId: "65a53e52-51db-4c7a-a724-c0df51f92227",
        cognitoId: "a2ebb0dd-6028-42cd-930b-aa78c4cd686f",
        userName: "Shanthi Priya",
        email: "spriyai@visteon.com",
        userType: "app_user",
        createdAt: "2022-12-29T12:41:44.243Z",
        updatedAt: "2022-12-29T12:41:44.243Z",
      },
      __has_user__: true,
      uploadedBy: "Shanthi Priya",
    },
  ],
  meta: {
    page: 1,
    take: 10,
    itemCount: 28,
    pageCount: 3,
    hasPreviousPage: false,
    hasNextPage: true,
  },
};
