import React, { useEffect, useState } from "react";
import {
  useParams,
  Link as RouterLink,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";
import { useQuery } from "react-query";
import {
  Typography,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Button,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import Constants from "../services/Constants";
import {
  formatFileSize,
  formatDate,
  getCurrentPathTab,
  getCampaignNameString,
  detailsTabsBoxStyle,
  overviewListItemStyles,
} from "../services/Utils";
import { getReleasesByImageId } from "../services/Release";
import BreadCrumbsRendered from "../components/BreadCrumbsRendered";
import { getColumnsData } from "../components/release/ReleaseColumns";

const PATHS = ["overview", "releases"];

export default function Image() {
  let params = useParams();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, PATHS[0])
  );
  const [imageDetails, setImageDetails] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    async function getImageDetails() {
      const imageDetails = await axios.get(
        Constants.DESCRIBE_IMAGE.replace(":id", params?.imageId)
      );
      setImageDetails(imageDetails.data);
    }

    getImageDetails();
  }, [params?.imageId]);

  const Overview = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Grid container>
          <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
            <List>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="File Name"
                  secondary={imageDetails?.fileName}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="File Type"
                  secondary={imageDetails?.fileType}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="File Size"
                  secondary={formatFileSize(imageDetails?.fileSize)}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Version"
                  secondary={imageDetails?.version}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Base Version"
                  secondary={imageDetails?.baseVersion || "-"}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
            <List>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Uploaded By"
                  secondary={imageDetails?.uploadedBy}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Updated At"
                  secondary={formatDate(imageDetails?.updatedAt)}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Campaign"
                  secondary={getCampaignNameString(imageDetails?.campaignType)}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={4}>
            <List>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="ChecksumSHA256"
                  secondary={imageDetails?.checksumSHA256 || "-"}
                  secondaryTypographyProps={{
                    style: {
                      whiteSpace: "nowrap",
                      overflowX: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                />
              </ListItem>
              {/* <ListItem sx={{ mt: 2 }} {...overviewListItemStyles}>
                <Button
                  disabled={!imageDetails?.checksumSHA256}
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  component={Link}
                  href={imageDetails?.downloadUrl}
                >
                  Download image
                </Button>
              </ListItem> */}
            </List>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const Releases = (props) => {
    const { imageId } = props;

    const columns = getColumnsData({ hideActions: false });

    const { data: { data } = { data: [] } } = useQuery(
      ["fetchProvisioningReport", imageId],
      getReleasesByImageId,
      {
        refetchOnWindowFocus: false,
        onSuccess: (res) => {
          console.log(res);
        },
      }
    );

    return (
      <Box {...detailsTabsBoxStyle}>
        <Box sx={{ height: "calc(100vh - 356px)", width: "100%" }}>
          <DataGrid
            getRowId={(row) => row?.releaseId}
            rows={data}
            columns={columns}
            disableSelectionOnClick
          />
        </Box>
      </Box>
    );
  };

  return (
    <div className="fragmentContainer">
      <BreadCrumbsRendered pathname={pathname} />
      <Box
        sx={{ width: "100%", marginTop: 4, padding: "2%" }}
        component={Paper}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Overview"
              value={"overview"}
              component={RouterLink}
              to={"overview"}
            />
            <Tab
              label="Releases"
              value={"releases"}
              component={RouterLink}
              to={"releases"}
            />
          </Tabs>
        </Box>
        <div className="tabs-container">
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="overview" element={<Overview />} />
            <Route path="releases" element={<Releases {...params} />} />
          </Routes>
        </div>
      </Box>
    </div>
  );
}
