import React, { Fragment } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CardActionArea,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CreateReleasePopup from "./CreateReleasePopup";
import Check from "../../auth/ability";
import { useQuery } from "react-query";
import { getReleasesData } from "../../services/Release";
import { grey } from "@mui/material/colors";
import { CAN, SUBJECTS } from "../../auth/permissions";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { MODULES } from "../../services/Constants";
import useModule from "../../hooks/useModule";

const ReleaseStats = () => {
  const { validateModuleWithMessage } = useModule()
  const { data: { data: { releaseCount = 0 } = {} } = {} } = useQuery(
    "getReleasesData",
    getReleasesData,
    {
      refetchOnWindowFocus: false,
    }
  );

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => {
    if (!validateModuleWithMessage(MODULES.OTA)) return;
    setOpenModal(true);
  }
  const handleClose = () => setOpenModal(false);

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <Box>
          <Card>
            <CardContent className="tile-cards">
              <Typography color="text.secondary" gutterBottom>
                Total Releases
              </Typography>
              <Typography
                color="text.primary"
                sx={{ fontSize: 30, fontWeight: 300 }}
                gutterBottom
              >
                {releaseCount}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Check I={CAN.CREATE} a={SUBJECTS.RELEASE}>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpen}>
            <div className="dotted-card">
              <p className="dotted-text">CREATE RELEASE</p>
              <div className="dotted-icon">
                <RssFeedIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
              </div>
            </div>
          </CardActionArea>
          <Dialog
            open={openModal}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            scroll="paper"
            PaperProps={{
              style: {
                maxHeight: "90%",
              },
            }}
          >
            <DialogTitle>
              Create Release
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <CreateReleasePopup onReleaseCreated={handleClose} />
            </DialogContent>
          </Dialog>
        </Grid>
      </Check>
      <Grid item xs={3}></Grid>
    </Fragment>
  );
};

ReleaseStats.defaultProps = {};

ReleaseStats.propTypes = {};

export default ReleaseStats;
