import React from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { getJobExecutions } from "../../services/vehicle";
import CancelVehicleDeployment from "./CancelVehicleDeployment";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const GroupDeploymentList = (data = {}) => {
  let { deploymentId } = useParams();
  const { vehicles = [] } = data;

  // jobExecutions Queries
  let {
    data: { data: { executionSummaries = [] } = {} } = {},
    isLoading: summaryLoading,
  } = useQuery(
    ["jobExecutions", deploymentId],
    getJobExecutions, // to get the execution summary of the whole deployment
    {
      refetchOnWindowFocus: false,
    }
  );

  if (summaryLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid item xs={12}>
      <TableContainer
        sx={{
          maxHeight: 528,
          overflow: "auto",
        }}
        style={{
          border: "1px solid #e2e2e2",
        }}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: 650,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Serial No</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Queued At</TableCell>
              <TableCell>Started At</TableCell>
              <TableCell>Last Updated At</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {executionSummaries.map((item) => (
              <TableRow
                key={item?.thingArn?.split("/").pop()}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Tooltip title={item.thingArn?.split("/").pop()}>
                    <Button
                      size="medium"
                      sx={{
                        justifyContent: "flex-start",
                        textTransform: "none",
                      }}
                      component={RouterLink}
                      to={`/vehicles/${item.thingArn
                        ?.split("/")
                        .pop()}/deployments/${deploymentId}`}
                    >
                      {item.thingArn?.split("/").pop()}
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {item.jobExecutionSummary.status
                    ? item.jobExecutionSummary.status
                    : "-"}
                </TableCell>

                <TableCell>
                  {item.jobExecutionSummary.queuedAt ? (
                    <DateAndTimeGridCell
                      value={item.jobExecutionSummary.queuedAt}
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  {item.jobExecutionSummary.startedAt ? (
                    <DateAndTimeGridCell
                      value={item.jobExecutionSummary.startedAt}
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  {item.jobExecutionSummary.lastUpdatedAt ? (
                    <DateAndTimeGridCell
                      value={item.jobExecutionSummary.lastUpdatedAt}
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  <CancelVehicleDeployment
                    data={{
                      serialNo: item.thingArn?.split("/").pop(),
                      jobExecutionSummary: item?.jobExecutionSummary,
                    }} // send vehicle serialNo in data
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default GroupDeploymentList;
