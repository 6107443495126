import React, { useState } from "react";
import {
  useParams,
  Link as RouterLink,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";
import {
  Typography,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from "moment";
import {
  DeploymentJobDocumentTab,
  DeploymentJobStatusTab,
  DeploymentVehicleTab,
  DeploymentStatus,
} from "../components/deployment";
import {
  detailsTabsBoxStyle,
  formatDate,
  formatFileSize,
  getCurrentPathTab,
  overviewListItemStyles,
  formatDateTimeWithAmPm,
} from "../services/Utils";
import { getDeploymentDetails } from "../services/deployment";
import { useQuery } from "react-query";
import PublishDeployment from "../components/deployment/PublishDeployment";
import BreadCrumbsRendered from "../components/BreadCrumbsRendered";
import { DateAndTimeGridCell } from "../components/common/DateAndTimeGridCell";

const PATHS = [
  "overview",
  "releases",
  "images",
  "vehicles",
  "jobdocument",
  "jobstatus",
];

export default function Deployment() {
  let params = useParams();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, "overview")
  );

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const { deploymentId = "" } = params || {};

  // Queries
  const { data: { data: deploymentDetails = {} } = {} } = useQuery(
    ["deploymentDetails", deploymentId],
    getDeploymentDetails
  );

  const {
    deploymentName = "",
    deploymentStatus = "",
    campaignStartDate = "",
    campaignEndDate = "",
    createdBy = "",
    release: {
      releaseId = "",
      releaseName = "",
      createdAt = "",
      createdBy: createdByRelease = "",
      images: releaseImages = [],
    } = {},
  } = deploymentDetails;

  const Overview = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <List>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Deployment Name"
              secondary={deploymentName}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Deployment Id" secondary={deploymentId} />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Deployment Status"
              secondary={<DeploymentStatus status={deploymentStatus} />}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Created by" secondary={createdBy} />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Campaign Start Date"
              secondary={formatDateTimeWithAmPm(campaignStartDate)}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Campaign End Date"
              secondary={formatDateTimeWithAmPm(campaignEndDate)}
            />
          </ListItem>
        </List>
      </Box>
    );
  };

  const Releases = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <List>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Release ID" secondary={releaseId} />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Release name" secondary={releaseName} />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="No. of images"
              secondary={releaseImages.length}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText
              primary="Created At"
              secondary={moment(createdAt).format("DD-MMM-yyyy hh:mm A")}
            />
          </ListItem>
          <ListItem {...overviewListItemStyles}>
            <ListItemText primary="Created By" secondary={createdByRelease} />
          </ListItem>
        </List>
      </Box>
    );
  };

  const Images = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
          <Table
            stickyHeader
            sx={{ minWidth: 650, border: "1px solid #e2e2e2" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell align="right">File Type</TableCell>
                <TableCell align="right">File Size</TableCell>
                <TableCell align="right">Version</TableCell>
                <TableCell align="right">Created At</TableCell>
                <TableCell align="right">Uploaded By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {releaseImages.map((image) => (
                <TableRow
                  key={image.imageUploadId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {image.fileName}
                  </TableCell>
                  <TableCell align="right">{image.fileType}</TableCell>
                  <TableCell align="right">
                    {formatFileSize(image.fileSize)}
                  </TableCell>
                  <TableCell align="right">{image.version}</TableCell>
                  <TableCell align="right">
                    <DateAndTimeGridCell value={image?.createdAt} />
                  </TableCell>
                  <TableCell align="right">{image.uploadedBy}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const Vehicles = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <DeploymentVehicleTab deploymentDetails={deploymentDetails} />
      </Box>
    );
  };

  const JobDocument = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <DeploymentJobDocumentTab />
      </Box>
    );
  };

  const JobStatus = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <DeploymentJobStatusTab />
      </Box>
    );
  };

  return (
    <div className="fragmentContainer">
      <BreadCrumbsRendered pathname={pathname} />

      <Box
        sx={{ width: "100%", marginTop: 4, padding: "2%" }}
        component={Paper}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Overview"
              value={"overview"}
              component={RouterLink}
              to={"overview"}
            />
            <Tab
              label="Releases"
              value={"releases"}
              component={RouterLink}
              to={"releases"}
            />
            <Tab
              label="Images"
              value={"images"}
              component={RouterLink}
              to={"images"}
            />
            <Tab
              label="Vehicles"
              value={"vehicles"}
              component={RouterLink}
              to={"vehicles"}
            />
            {/* <Tab
              label="Job Status"
              value={"jobstatus"}
              component={RouterLink}
              to={"jobstatus"}
            /> */}
            <Tab
              label="Job Document"
              value={"jobdocument"}
              component={RouterLink}
              to={"jobdocument"}
            />
          </Tabs>
        </Box>
        <div className="tabs-container">
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="overview" element={<Overview />} />
            <Route path="releases" element={<Releases />} />
            <Route path="images" element={<Images />} />
            <Route path="vehicles" element={<Vehicles />} />
            <Route path="jobdocument" element={<JobDocument />} />
            {/* <Route path="jobstatus" element={<JobStatus />} /> */}
          </Routes>
        </div>
      </Box>
    </div>
  );
}
