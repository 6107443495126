import React from "react";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import StatusActiveInactive from "../common/StatusActiveInactive";

const getHuManufacturedCols = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    {
      field: "serialNo",
      headerName: "Vehicle serial",
      flex: 1,
      // editable: true,
      renderCell: (d) => d.value || "-",
    },
    {
      field: "serialNoCreatedAt",
      headerName: "Serial created Date",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      flex: 1,
    },
    {
      field: "vehicleCreationDate",
      headerName: "Vehicle key creation Date",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "features",
      headerName: "Features",
      flex: 0.75,
    },
    // {
    //   field: "purchaseDate",
    //   headerName: "Vehicle Purchase Date",
    //   flex: 0.75,
    //   renderCell: (d) => {
    //     const val =
    //       d?.row?.vehicle?.vehiclePurchaseDetails?.purchaseDate ||
    //       d?.row?.vehiclePurchaseDetails?.purchaseDate;

    //     return <DateAndTimeGridCell value={val} />;
    //   },
    // },
    {
      field: "active",
      headerName: "Status",
      type: "boolean",
      flex: 0.5,
      renderCell: ({ row }) => {
        return <StatusActiveInactive active={row?.active} />;
      },
    },
  ];
};

const getVehicleManufacturedCols = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    {
      field: "vehicleKey",
      headerName: "Vehicle Key",
      flex: 1,
    },
    {
      field: "vehicleCreationDate",
      headerName: "Vehicle key created At",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "features",
      headerName: "Features",
      flex: 0.75,
    },
    // {
    //   field: "purchaseDate",
    //   headerName: "Vehicle Purchased At",
    //   flex: 0.75,
    //   renderCell: (d) => {
    //     const val =
    //       d?.row?.purchaseDate || d?.row?.vehiclePurchaseDetails?.purchaseDate;

    //     return <DateAndTimeGridCell value={val} />;
    //   },
    // },

    {
      field: "serialNo",
      headerName: "Vehicle serial",
      flex: 1,
      // editable: true,
      renderCell: (d) => d.value || "-",
    },
    {
      field: "serialNoCreatedAt",
      headerName: "Serial created At",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    // {
    //   field: "active",
    //   headerName: "Status",
    //   flex: 0.5,
    //   renderCell: ({ row }) =>
    //     row?.hasOwnProperty("active") ? (
    //       <StatusActiveInactive active={row?.active} />
    //     ) : (
    //       "-"
    //     ),
    // },
  ];
};

const getColumns = (type) => {
  if (type === "hu-manufactured-date") {
    return getHuManufacturedCols();
  } else if (type === "vehicle-manufactured-date") {
    return getVehicleManufacturedCols();
  } else if (type === "vehicle-purchased-date") {
    return getVehicleManufacturedCols();
  }
};
export { getColumns };
