import React from "react";
import { Grid } from "@mui/material";
import {
  DeploymentsByUsers,
  DeploymentsByPeriod,
  DeploymentsCalendarView,
  DeploymentsByStatus,
  DashboardStats,
} from "../components/charts";

const Dashboard = () => {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <DashboardStats />
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DeploymentsCalendarView />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DeploymentsByPeriod />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DeploymentsByStatus />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DeploymentsByUsers />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
