import { format } from "date-fns";
import moment from "moment";
import { DEPLOYMENT_PACKAGE_TYPE } from "./Constants";

const formatFileSize = (bytes, decimalPoint) => {
  if (bytes === 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const formatDisplayDatesForSearch = (dateObj) => {
  const { from, to } = dateObj;
  const formatTemplate = "dd/MM/yyyy";
  const doformat = (d) => {
    return format(new Date(d), formatTemplate);
  };
  if (!!from && !!to) {
    return `${doformat(from)} - ${doformat(to)}`;
  }
};

const formatDate = (date) => {
  if (date) {
    return moment(date).format("DD-MMM-yyyy HH:mm");
  } else {
    return "";
  }
};

const formatDateWithoutTime = (date) => {
  if (date) {
    return moment(date).format("DD-MMM-yyyy");
  } else {
    return "-";
  }
};

const formatDateToTime = (date) => {
  if (date) {
    return moment(date).format("LT");
  } else {
    return "";
  }
};

const formatDate_12h = (date) => {
  if (date) {
    return moment(date).format("DD-MMM-yyyy LT");
  } else {
    return "-";
  }
};

const formatDate_utc = (date) => {
  if (date) {
    return moment(date).utc().format();
  } else {
    return "";
  }
};

const formatTimeStamp = (date) => {
  if (date) {
    return moment(date).format("DD-MMM-yyyy HH:mm:ss");
  } else {
    return "";
  }
};

const formatTimeStampFromNow = (date) => {
  if (date) {
    const timeString = moment(date).format("DD-MMM-yyyy HH:mm:ss");
    return moment(timeString).fromNow();
  } else {
    return "";
  }
};

const formatDateTimeWithAmPm = (date) => {
  if (date) {
    return moment(date).format("DD-MMM-yyyy hh:mm A");
  } else {
    return "-";
  }
};

const stringToHslColor = (str, s, l) => {
  var hash = 0;
  for (var i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};

const constructSearchUrl = ({ filterData }) => {
  return `&filter=${encodeURIComponent(JSON.stringify(filterData))}`;
};
const filterItem = (items, itemType) => {
  return items.filter((el) => el.type === itemType);
};
const filterDateItems = (items) => {
  return filterItem(items, "dateTime");
};
const filterItemsByColumnName = (columnName, columns) => {
  return columns.filter((el) => el.field === columnName);
};
const constructFilterUrl = ({ filterValue, query, columns }) => {
  let filterQuery = query;
  let filterData = JSON.parse(JSON.stringify(filterValue));
  let filterItemValue = filterData.items.some((filterItems) => {
    if (
      filterItems.operatorValue === "isEmpty" ||
      filterItems.operatorValue === "isNotEmpty"
    ) {
      return true;
    } else {
      if (typeof filterItems.value === "boolean") {
        return true;
      } else if (filterItems.value && filterItems.value !== "") {
        let items = filterItemsByColumnName(filterItems.columnField, columns);
        if (
          filterItems?.value &&
          filterItems?.value?.from &&
          filterItems?.value?.to
        ) {
          return true;
        } else {
          let dateItemColumns = filterDateItems(items);
          if (dateItemColumns.length > 0) {
            let value = formatDate_utc(filterItems.value);
            filterItems.value = value;
          }
          return true;
        }
      } else {
        return false;
      }
    }
  });

  if (filterItemValue) {
    let searchUrl = constructSearchUrl({ filterData });
    filterQuery = query + searchUrl;
  }
  return filterQuery;
};

const constructQueryUrl = (params) => {
  const { filterValue, query } = params;
  if (filterValue && Object.keys(filterValue)?.length) {
    return constructFilterUrl(params);
  }
  return query;
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Returns the name of the currently active tab using the current url path
 * @param  {String} pathname The pathname string which is returned from the react-router library's useLocation() hook.
 * @param  {Array<String>} tabsList The list of tab names that are used in the current tabs group.
 * @param  {String} defaultTab Name of the default tab in the tabs group.
 * @return {String}      The tab name which is currently active.
 */
const getCurrentPathTab = (pathname, tabsList, defaultTab) => {
  if (pathname && tabsList) {
    const pathParts = pathname.split("/");
    const newPathsArray = pathParts.filter((item, index) => {
      if (index > 1) {
        const pathFound = tabsList.filter((i) => i === item.toLowerCase());
        return pathFound && pathFound.length;
      } else {
        return false;
      }
    });
    return newPathsArray && newPathsArray.length
      ? newPathsArray[newPathsArray.length - 1]
      : defaultTab;
  } else {
    return defaultTab || "";
  }
};

const getInitialsForAvatar = (nameString = "") => {
  return nameString
    .split(" ")
    .map((i) => i[0])
    .join("")
    .toUpperCase();
};

const getCampaignNameString = (type) => {
  let name = "-";
  switch (type) {
    case DEPLOYMENT_PACKAGE_TYPE.SMARTCORE:
      name = "Smart Core";
      break;
    case DEPLOYMENT_PACKAGE_TYPE.OTHERS:
      name = "Others";
      break;

    default:
      break;
  }
  return name;
};

const getLast365DaysDateRange = () => {
  const dt = new Date();
  const today = new Date();
  const dateBefore365Days = new Date(dt.setDate(dt.getDate() - 365));
  const last365DaysDateRange = {
    from: dateBefore365Days,
    to: today,
  };
  return last365DaysDateRange;
};

const formatDateWithoutTimeToIsoString = (d) => {
  if (!!d) {
    const dt = new Date(d);
    const year = dt.getFullYear();
    const month = dt.getMonth() + 1;
    const date = dt.getDate();
    const isoDate = new Date(year, month, date, 0, 0, 0, 0).toISOString();
    return isoDate;
  }
};

const formatDateWithoutTimeToUTCString = (d) => {
  if (!!d) {
    const dt = new Date(d);
    const year = dt.getFullYear();
    const month = dt.getMonth() + 1;
    const date = dt.getDate();
    const utcDate = new Date(year, month, date, 0, 0, 0, 0).toUTCString();
    return utcDate;
  }
};

const detailsTabsBoxStyle = {
  sx: { mt: 2 },
};

const overviewListItemStyles = {
  sx: { pt: 0, pb: 1 },
};

const isValidDate = (dt) => {
  // If the date object is invalid it will return 'NaN' on getTime()
  return !isNaN(dt?.getTime());
};

const isFileTypeValid = (fileName = "", type) => {
  const filetype = type.split(",");
  /* getting file extenstion eg- .json,.iso, etc */
  const extension = fileName?.substring(fileName.lastIndexOf("."));
  //return extension === type;
  return filetype.includes(extension.toLowerCase());
};

export {
  formatFileSize,
  formatDate,
  formatDateWithoutTime,
  formatDateToTime,
  formatTimeStamp,
  formatTimeStampFromNow,
  formatDisplayDatesForSearch,
  stringToHslColor,
  formatDate_12h,
  constructQueryUrl,
  formatDate_utc,
  capitalize,
  getCurrentPathTab,
  getInitialsForAvatar,
  getCampaignNameString,
  getLast365DaysDateRange,
  formatDateWithoutTimeToIsoString,
  formatDateWithoutTimeToUTCString,
  detailsTabsBoxStyle,
  overviewListItemStyles,
  isValidDate,
  isFileTypeValid,
  formatDateTimeWithAmPm,
};
