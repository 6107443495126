import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserGroups } from "../services/Users";
import { USER_GROUPS } from "./permissions";

const ProtectedRoute = ({ redirectPath = "/", children }) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserGroups().then((data) => {
      const currentCustomer = localStorage.selectedCustomer || "";
      const filteredCustomer = data?.find(i => i?.customer === currentCustomer);
      setGroups(filteredCustomer?.groups)
    }).finally(
      () => setLoading(false)
    );
  }, []);

  if (loading) {
    return (
      <div className="route-loader">
        <CircularProgress size={30} />
      </div>
    );
  }

  // find special group
  const specialGroups = [USER_GROUPS.SUPER_ADMIN, USER_GROUPS.HD_ADMIN];
  const findSpecialGroup = groups.find(g => specialGroups.includes(g));

  if (!findSpecialGroup) {
    return <Navigate to={redirectPath} replace />;
  } else if (findSpecialGroup) {
    return children;
  }
};

export default ProtectedRoute;
