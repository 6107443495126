import React from "react";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import StatusActiveInactive from "../common/StatusActiveInactive";
import { Button, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import TripDetailsDialog from "./TripDetailsDialog";

const getColumns = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    {
      field: "tripId",
      headerName: "Trip ID",
      flex: 1,
      renderCell: (data) => {
        return (
          <Tooltip title={data?.value}>
            <TripDetailsDialog tripId={data?.value} />
          </Tooltip>
        );
      },
    },
    {
      field: "startTime",
      headerName: "Start Date",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "endTime",
      headerName: "End Date",
      type: "dateTime",
      flex: 0.75,
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
  ];
};

export { getColumns };
