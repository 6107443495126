import React from "react";
import { Card, CardHeader, CardContent, Grid } from "@mui/material";
import { ImageStats, ImageUploadDataGrid } from "../components/image";

const ImageRepofragment = () => {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <ImageStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Available Images"></CardHeader>
            <CardContent sx={{ padding: 0 }}>
              <ImageUploadDataGrid />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageRepofragment;
