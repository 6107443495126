import { Box } from "@mui/material";
import { teal } from "@mui/material/colors";
import React from "react";
import Constants from "../../services/Constants";
let { UPLOAD_STATUS: UploadStatus } = Constants;

const SdvUploadStatus = ({ status }) => {
  const { image_uploadStatus, video_uploadStatus } = status;

  const isStarted =
    image_uploadStatus === UploadStatus.Started ||
    video_uploadStatus === UploadStatus.Started;

  const isInProgress =
    image_uploadStatus === UploadStatus.InProgress ||
    video_uploadStatus === UploadStatus.InProgress;

  const isCompleted =
    image_uploadStatus === UploadStatus.Completed &&
    video_uploadStatus === UploadStatus.Completed;

  let overallStatus = "";

  if (isStarted) {
    overallStatus = UploadStatus.Started;
  } else if (isInProgress) {
    overallStatus = UploadStatus.InProgress;
  } else if (isCompleted) {
    overallStatus = UploadStatus.Completed;
  }

  let background = teal[700];

  let colors = {
    Completed: "#3ba272",
    Started: "#5470c6",
    InProgress: "#73c0de",
    Failed: "#ee6666",
  };

  let color = colors[overallStatus] || background;
  return (
    <Box
      sx={{
        height: "20px",
        width: "100px",
        textAlign: "center",
        display: "inline",
        fontSize: 10,
        background: color,
        borderRadius: "5px",
        color: "white",
        padding: "4px 7px",
        fontWeight: "bold",
      }}
    >
      {UploadStatus[overallStatus]?.replace(/_/g, " ").toUpperCase()}
    </Box>
  );
};

export default SdvUploadStatus;
