import React from "react";
import { Card, CardHeader, CardContent, Grid } from "@mui/material";
import { SdvFeatureStats, SdvFeatureDataGrid } from "../components/sdv-feature";

const SdvFeaturesFragment = () => {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <SdvFeatureStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Available SDV Features"></CardHeader>
            <CardContent sx={{ padding: 0 }}>
              <SdvFeatureDataGrid />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SdvFeaturesFragment;
