import React from "react";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import SdvFeatureDataTableRowActions from "./SdvFeatureDataTableRowActions";
import { capitalize } from "../../services/Utils";
import SdvUploadStatus from "./SdvUploadStatus";
import styled from "styled-components";

const ActiveText = styled.span`
  color: #43a047;
`;

const InactiveText = styled.span`
  color: #c1c1c1;
`;

const getSdvFeatureDataColumns = () => {
  return [
    {
      field: "sdvFeatureName",
      headerName: "Feature Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "vehiclesCount",
      headerName: "Vehicles In Features",
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "number",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "isActive",
      headerName: "Feature Status",
      type: "boolean",
      flex: 0.75,
      renderCell: ({ row }) =>
        row.isActive ? (
          <ActiveText>Active</ActiveText>
        ) : (
          <InactiveText>Inactive</InactiveText>
        ),
    },
    {
      field: "overAllStatus",
      headerName: "Upload Status",
      flex: 0.75,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (data) => {
        return <SdvUploadStatus status={data.row} />;
      },
    },
    // {
    //   field: "image_uploadStatus",
    //   headerName: "Image Status",
    //   flex: 0.75,
    //   align: "center",
    //   headerAlign: "center",
    //   disableColumnMenu: true,
    // },
    // {
    //   field: "video_uploadStatus",
    //   headerName: "Video Status",
    //   flex: 0.75,
    //   align: "center",
    //   headerAlign: "center",
    //   disableColumnMenu: true,
    // },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 0.75,
      sortable: true,
      valueFormatter: ({ value }) => capitalize(value),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.75,
      editable: false,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.createdAt} />
      ),
    },
    {
      field: "none",
      headerName: "Action",
      flex: 1.25,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (data) => <SdvFeatureDataTableRowActions data={data} />,
    },
  ];
};

export { getSdvFeatureDataColumns };
