import React, { useContext } from "react";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMutation, useQuery } from "react-query";
import Typography from '@mui/material/Typography';
import { WizardContext, ACTIONS } from "../wizard/Context";
import { getAvailableRoles, updateUserRoles, getOrgsListForUser } from "../../services/Users";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { useToast } from "../toast";
import { GROUPS, UserRoleStatus } from "../../services/Constants";

const UserRoleManagerDialog = () => {

    const { dispatch, state } = useContext(WizardContext);
    const { addToast } = useToast();
    const { showRoleManager = false, roleUserId } = state;
    const addGroup = new Set();
    const removeGroup = new Set();

    const { data: userRoles = [], isLoading: queryLoading, isFetching } = useQuery(
        ["getAvailableRoles", roleUserId],
        getAvailableRoles,
        {
            refetchOnWindowFocus: true,
        }
    );

    const { data: customers = [], isLoading: customerLoading } = useQuery(
        ["getOrgsListForUser", roleUserId],
        getOrgsListForUser,
        {
            refetchOnWindowFocus: true,
        }
    );

    const { mutate: updateRolesForUser, isLoading: mutationLoading } = useMutation(updateUserRoles, {
        onSuccess: (res) => {
            res.forEach(r => {
                if (r?.statusCode === 400) {
                    addToast({
                        type: "error",
                        message: `${r?.message} ${r?.group} for ${r?.customer}`,
                        autoClose: 3000,
                    });
                } else if (r?.statusCode === 200) {
                    addToast({
                        type: "success",
                        message: `Updated ${r?.group} for ${r?.customer}.`,
                        autoClose: 3000,
                    });
                }
            })
            handleClose();
        },
        onError: (e) => {
            addToast({
                type: "error",
                message: "Failed to update roles for the user!",
                autoClose: 3000,
            });
        }
    });

    const handleClose = () => {
        dispatch({
            type: ACTIONS.RESET,
        });
    }

    const updateUserGroup = (event, selectedGroup, customer, customerGroup) => {
        const group = customerGroup.filter(g => g?.status === UserRoleStatus.ASSIGNED).map(g => g?.role) || [];
        const checked = event.target.checked;
        const groupIncludes = group.includes(selectedGroup);
        if (checked && !groupIncludes) {
            addGroup.add(`${selectedGroup}:${customer}`);
        } else if (!checked && groupIncludes) {
            removeGroup.add(`${selectedGroup}:${customer}`);
        } else if (!checked && !groupIncludes) {
            addGroup.delete(`${selectedGroup}:${customer}`);
        } else {
            removeGroup.delete(`${selectedGroup}:${customer}`);
        }
    };


    const handleSubmit = async () => {
        // constructing payload
        let addPayload = [...addGroup];
        let removePayload = [...removeGroup];
        addPayload = addPayload.map((e) => {
            const item = e.split(":");
            return { action: "add", group: item[0], customer: item[1] };
        });
        removePayload = removePayload.map((e) => {
            const item = e.split(":");
            return { action: "remove", group: item[0], customer: item[1] };
        });
        const payload = [...addPayload, ...removePayload];
        if (payload.length > 0) {
            updateRolesForUser({ userId: roleUserId, payload: { groups: payload } })
        } else {
            addToast({
                type: "error",
                message: "There is no change in roles to update!",
                autoClose: 3000,
            });
        }
    };


    const renderRolesCheckbox = (params) => {
        const { groups = [], customer = '' } = params || {};
        return groups.map(g => {
            const checked = [UserRoleStatus.ASSIGNED, UserRoleStatus.DISABLED].includes(g?.status);
            return <Grid item xs={4}>
                <FormControlLabel
                    label={GROUPS[g?.role] || ''}
                    control={<Checkbox
                        disableRipple
                        defaultChecked={checked}
                        disabled={g?.status === UserRoleStatus.DISABLED}
                        onChange={(e) => updateUserGroup(e, g?.role, customer, groups)}
                    />}
                />
            </Grid>
        }
        )
    }

    const isLoading = queryLoading || mutationLoading || customerLoading;
    const userCustomers = customers.map(c => c.customerid);

    return (
        <Dialog
            maxWidth={"md"}
            fullWidth
            open={showRoleManager}
            onClose={handleClose}
        >
            <DialogTitle>Manage Roles</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You can assign Roles based on customer and module
                </DialogContentText>
                {isFetching ? <HourglassTopIcon /> :
                    userRoles.length > 0 && userCustomers.length > 0 && userRoles
                        .filter(r => userCustomers.includes(r?.customer))
                        .map(role => {
                            return <>
                                <Typography variant="h6" sx={{ marginTop: "3%", marginBottom: "2%" }}>
                                    {role?.customer} roles
                                </Typography>
                                <Grid container spacing={2}>
                                    {renderRolesCheckbox(role)}
                                </Grid>
                            </>
                        })
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    size="medium"
                    onClick={() => handleSubmit()}
                    endIcon={isLoading && <HourglassTopIcon />}
                    disabled={isLoading}
                >
                    Update
                </Button>
                <Button size="medium" onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserRoleManagerDialog;