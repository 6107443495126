import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
} from "@mui/material";
import { detailsTabsBoxStyle } from "../../services/Utils";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";

const VehiclesInGroupTab = (props) => {
  return (
    <Box {...detailsTabsBoxStyle}>
      <Grid item xs={12}>
        <TableContainer
          sx={{
            maxHeight: 800,
            overflow: "auto",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650, border: "1px solid #e2e2e2" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Serial No</TableCell>
                <TableCell>Vehicle Key</TableCell>

                <TableCell>Created At</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Updated By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.groupData?.devices?.flat()?.map((vehicle) => (
                <TableRow
                  key={vehicle.serialNo}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Tooltip title={vehicle.serialNo}>
                      <Button
                        size="medium"
                        sx={{
                          justifyContent: "flex-start",
                          textTransform: "none",
                        }}
                        component={RouterLink}
                        to={`/vehicles/${vehicle.serialNo}`}
                      >
                        {vehicle.serialNo}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{vehicle.vehicleKey}</TableCell>
                  <TableCell>
                    <DateAndTimeGridCell value={vehicle?.serialNoCreatedAt} />
                  </TableCell>
                  <TableCell>{vehicle?.createdBy}</TableCell>
                  <TableCell>
                    <DateAndTimeGridCell value={vehicle?.updatedAt} />
                  </TableCell>
                  <TableCell>{vehicle?.updatedBy}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default VehiclesInGroupTab;
