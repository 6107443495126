import React from "react";
import { getSdvFeatureList } from "../../services/SdvFeature";
import Constants from "../../services/Constants";
import OTAPagination from "../OTAPagination";
import { getSdvFeatureDataColumns } from "./SdvFeatureDataColumns";

const SdvFeatureDataGrid = () => {
  const columns = getSdvFeatureDataColumns();

  return (
    <OTAPagination
      columns={columns}
      endpoint={Constants.LIST_SDV_APP_FEATURES}
      endpointFn={getSdvFeatureList}
      queryName={"getSdvFeatureList"}
      filterMode="server"
      sortByDefault={"createdAt"}
      getRowId={(row) => row.sdvFeatureId}
    />
  );
};

export default SdvFeatureDataGrid;
