import React, { useState } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SdvUploadDialog from "./SdvUploadDialog";
import Constants, { UPLOAD_TYPES } from "../../services/Constants";
import { useMutation, useQueryClient } from "react-query";
import { useToast } from "../../components/toast";
import { updateSDVFeatureStatus } from "../../services/SdvFeature";

const SdvFeatureDataTableRowActions = ({ data }) => {
  const {
    sdvFeatureId,
    apk_uploadStatus,
    image_uploadStatus,
    video_uploadStatus,
    isActive,
  } = data?.row;
  const { addToast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [resumeUpload, setResumeUpload] = useState(null);
  const onClose = () => {
    setResumeUpload(null);
    setOpenModal(false);
  };
  let { UPLOAD_STATUS: UploadStatus } = Constants;

  const handleResumeUpload = (id) => {
    const resumeData = {
      uploadTypes: [],
      sdvFeatureId,
    };
    if (apk_uploadStatus !== UploadStatus.Completed) {
      resumeData?.uploadTypes.push(UPLOAD_TYPES.APK);
    }
    if (image_uploadStatus !== UploadStatus.Completed) {
      resumeData?.uploadTypes.push(UPLOAD_TYPES.IMAGE);
    }
    if (video_uploadStatus !== UploadStatus.Completed) {
      resumeData?.uploadTypes.push(UPLOAD_TYPES.VIDEO);
    }
    setResumeUpload(resumeData);
    setOpenModal(true);
  };

  const dialogProps = {
    openModal,
    setOpenModal,
    onClose,
    resumeUpload,
  };

  const apk_status =
    apk_uploadStatus === UploadStatus.Failed ||
    apk_uploadStatus === UploadStatus.Completed;

  const img_status =
    image_uploadStatus === UploadStatus.Failed ||
    image_uploadStatus === UploadStatus.Completed;

  const video_status =
    video_uploadStatus === UploadStatus.Failed ||
    video_uploadStatus === UploadStatus.Completed;

  const overAllStatus =
    image_uploadStatus === UploadStatus.Completed &&
    video_uploadStatus === UploadStatus.Completed;

  const queryClient = useQueryClient();
  const { mutate: updateFeatureStatus, isLoading } = useMutation(
    updateSDVFeatureStatus,
    {
      onSuccess: async ({ data }) => {
        addToast({
          type: "success",
          message: `Feature status updated successfully to ${data?.sdvFeatureName}.`,
          autoClose: 3000,
        });
        queryClient.invalidateQueries("getSdvFeatureList");
      },
      onError: (e) => {
        const { response } = e;
        addToast({
          type: "error",
          message: response?.data?.message || "Failed to update feature status",
          autoClose: 3000,
        });
      },
      onSettled: () => {},
    }
  );

  const handleFeatureStatus = () => {
    if (isActive) {
      console.log("Disable");
    } else {
      console.log("Enable");
    }
    const payload = {
      sdvFeatureId: sdvFeatureId,
      isActive: isActive ? false : true,
    };
    updateFeatureStatus(payload);
  };

  return (
    <>
      <div>
        <Button
          size="small"
          component={RouterLink}
          to={`/sdv-app-features/${data.id}`}
        >
          Details
        </Button>

        {(!apk_status || !img_status || !video_status) && (
          <>
            {<span>|</span>}
            <Button size="small" onClick={() => handleResumeUpload(data?.id)}>
              Upload
            </Button>
          </>
        )}
        {overAllStatus && (
          <>
            {<span>|</span>}
            <Button size="small" onClick={() => handleFeatureStatus()}>
              {!!isActive ? "Disable" : "Enable"}
            </Button>
          </>
        )}
      </div>
      <SdvUploadDialog {...dialogProps} />
    </>
  );
};

export default SdvFeatureDataTableRowActions;
