import React, { useEffect, useState } from "react";
import { Ability, AbilityBuilder } from "@casl/ability";
import { Can } from "@casl/react";
import { getUserGroups } from "../services/Users";
import { CAN, USER_GROUPS, SUBJECTS } from "./permissions";

const defineAbility = (groups = []) => {
  const { can, build } = new AbilityBuilder(Ability);

  if (groups.includes(USER_GROUPS.GUEST)) {
    can(CAN.READ, SUBJECTS.ALL);
  }
  if (groups.includes(USER_GROUPS.OTA_CAMPAIGN_APPROVER)) {
    can(CAN.PUBLISH, SUBJECTS.DEPLOYMENT);
    can(CAN.CANCEL, SUBJECTS.DEPLOYMENT);
    can(CAN.MANAGE, SUBJECTS.DEPLOYMENT);
  }
  if (groups.includes(USER_GROUPS.OTA_CAMPAIGN_CREATOR)) {
    can(CAN.CREATE, SUBJECTS.DEPLOYMENT);
    can(CAN.CREATE, SUBJECTS.IMAGE);
    can(CAN.CREATE, SUBJECTS.RELEASE);
    can(CAN.CREATE, SUBJECTS.GROUP);
  }
  if (groups.includes(USER_GROUPS.OTA_ADMIN)) {
    can(CAN.MANAGE, SUBJECTS.DEPLOYMENT);
    can(CAN.MANAGE, SUBJECTS.IMAGE);
    can(CAN.MANAGE, SUBJECTS.RELEASE);
    can(CAN.MANAGE, SUBJECTS.GROUP);
  }
  if (groups.includes(USER_GROUPS.REMOTE_COMMANDS_ADMIN)) {
    can(CAN.MANAGE, SUBJECTS.REMOTE_COMMANDS);
  }
  if (groups.includes(USER_GROUPS.COTA_ADMIN)) {
    can(CAN.MANAGE, SUBJECTS.CONFIGURATION);
    can(CAN.MANAGE, SUBJECTS.CUSTOM_CONFIG);
    can(CAN.MANAGE, SUBJECTS.FEATURES);
  }
  if (groups.includes(USER_GROUPS.SUBSCRIPTION_ADMIN)) {
    can(CAN.MANAGE, SUBJECTS.SCHEDULER);
  }
  if (groups.includes(USER_GROUPS.ANALYTICS_ADMIN)) {
    // YTD
  }
  if (groups.includes(USER_GROUPS.HD_ADMIN)) {
    can(CAN.MANAGE, SUBJECTS.USER);
  }
  if (groups.includes(USER_GROUPS.SUPER_ADMIN)) {
    can(CAN.MANAGE, SUBJECTS.ALL);
  }

  return build();
};


const Check = ({ children, bypass = false, validateAdmin = false, ...rest }) => {
  const [groups, setGroups] = useState([]);
  const ability = defineAbility(groups);
  useEffect(() => {
    getUserGroups().then((data) => {
      // setting user groups based on customer
      const selected = data.find(i => i.customer === localStorage.selectedCustomer);
      setGroups(selected?.groups || [])
    });
  }, []);

  if (validateAdmin && groups.length > 0) {
    return (groups.includes(USER_GROUPS.SUPER_ADMIN) || groups.includes(USER_GROUPS.HD_ADMIN));
  }

  if (bypass) {
    return <>{children}</>;
  }
  return (
    <Can {...rest} ability={ability}>
      <>{children}</>
    </Can>
  );
};

export default Check;
