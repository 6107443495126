import React, { useContext } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import VehicleList from "./VehicleList";
import VehicleGroupList from "./VehicleGroupList";
import { WizardContext, ACTIONS, TABS } from "../Context";
import TabPanel, { a11yProps } from "./TabPanel";

const VehicleWizard = () => {
  const { state, dispatch } = useContext(WizardContext);
  const { toggleVehicle, vehicles, vehicleGroups } = state;
  const vehiclesCount = vehicles.length > 0 ? `(${vehicles.length})` : "";
  const groupsCount =
    vehicleGroups.length > 0 ? `(${vehicleGroups.length})` : "";

  return (
    <>
      <Tabs
        centered
        value={toggleVehicle}
        onChange={(_, newValue) =>
          dispatch({
            type: ACTIONS.TOGGLE_VEHICLE,
            payload: {
              toggleVehicle: newValue,
              vehicles: [],
              vehicleGroups: [],
              disableNextBtn: true,
            },
          })
        }
        aria-label="tabs auto"
      >
        <Tab
          disableRipple
          label={`Choose Vehicles ${vehiclesCount}`}
          {...a11yProps(0)}
        />
        <Tab
          disableRipple
          label={`Choose Vehicle Groups ${groupsCount}`}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel
        value={toggleVehicle}
        index={0}
        className="wizard-list-container"
      >
        {toggleVehicle === TABS.CHOOSE_VEHICLES && <VehicleList wizard />}
      </TabPanel>
      <TabPanel
        value={toggleVehicle}
        index={1}
        className="wizard-list-container"
      >
        {toggleVehicle === TABS.CHOOSE_VEHICLE_GROUPS && (
          <VehicleGroupList
            wizard
            onValueUpdated={(groups) =>
              dispatch({
                type: ACTIONS.UPDATE_VEHICLE_GROUP,
                payload: { vehicleGroups: groups },
              })
            }
          />
        )}
      </TabPanel>
    </>
  );
};

export default VehicleWizard;
