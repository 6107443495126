import React from "react";
import { getImagesList } from "../../services/Image";
import Constants from "../../services/Constants";
import OTAPagination from "../OTAPagination";
import { getImageUploadDataColumns } from "./ImageUploadDataColumns";

const ImageUploadDataGrid = () => {
  const columns = getImageUploadDataColumns();

  return (
    <OTAPagination
      columns={columns}
      endpoint={Constants.LIST_IMAGES}
      endpointFn={getImagesList}
      queryName={"images"}
      filterMode="server"
      getRowId={(row) => row.imageUploadId}
    />
  );
};

export default ImageUploadDataGrid;
