import { Typography, Breadcrumbs, Link } from "@mui/material";
import { capitalize } from "../services/Utils";

const BreadCrumbsRendered = (props) => {
  const { pathname } = props;
  const segments = !!pathname ? pathname.split("/").filter((i) => i) : [];
  const getHref = (segment, index) => {
    if (!!segment && segments?.length) {
      return "/" + segments?.slice(0, index + 1).join("/");
    }
  };
  return (
    <Breadcrumbs aria-label="breadcrumb" className="internal-breadcrumb">
      <Link underline="hover" color="inherit" href="/">
        App
      </Link>
      {segments?.length &&
        segments.map((segment, index) => (
          <span>
            {segments.length - 1 === index ? (
              <Typography color="text.primary">
                {capitalize(segment)}
              </Typography>
            ) : (
              <Link
                underline="hover"
                color="inherit"
                href={getHref(segment, index)}
              >
                {segment}
              </Link>
            )}
          </span>
        ))}
    </Breadcrumbs>
  );
};

export default BreadCrumbsRendered;
