import React from "react";
import { Button, Tooltip } from "@mui/material";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import VehicleGroupDataTableRowActions from "./VehicleGroupDataTableRowActions";
import { Link as RouterLink } from "react-router-dom";

const getVehicleGroupsDataColumns = () => {
  // Column props reference available at - ota-portal_v2\node_modules\.pnpm\@mui+x-data-grid@5.15.2_rrttaqilrsb3aifveu7wjrwxnm\node_modules\@mui\x-data-grid\models\colDef\gridColDef.d.ts
  return [
    {
      field: "vehicleGroupName",
      headerName: "Group Name",
      width: 200,
      renderCell: (data) => {
        const { row: { vehicleGroupId = "" } = {} } = data;
        return (
          <Tooltip title={data?.value}>
            <Button
              size="medium"
              sx={{ justifyContent: "flex-start", textTransform: "none" }}
              component={RouterLink}
              to={`/groups/${vehicleGroupId}`}
            >
              {data?.value}
            </Button>
          </Tooltip>
        );
      },
    },

    {
      field: "vehicles",
      headerName: "Vehicles In Group",
      flex: 0.75,
      align: "center",
      headerAlign: "center",
      type: "number",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (d) => d?.value?.length,
    },

    {
      field: "createdAt",
      headerName: "Created Date",
      flex: 0.75,
      type: "date",
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      flex: 0.75,
      type: "date",
      renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      flex: 1,
      renderCell: (d) => d?.value || "-",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (d) => <VehicleGroupDataTableRowActions data={d} />,
      align: "center",
      headerAlign: "center",
    },
  ];
};

export { getVehicleGroupsDataColumns };
