import React from "react";
import { Route, Routes } from "react-router-dom";
import { Card, Tabs, Tab, Box, Grid } from "@mui/material";
import UserList from "../components/user/UserList";
import User from "../routes/User";
import UserStats from "../components/user/UserStats";
import UnassignedUserList from "../components/user/UnassignedUserList";
import { UserRoleStatus } from "../services/Constants";
import "../styles/user.css";

const Users = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        exact
        element={
          <div className="fragmentContainer">
            <Grid container spacing={2}>
              <UserStats />
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                      <Tab
                        label={`${localStorage.selectedCustomer} Users`}
                        {...a11yProps(0)}
                      />
                      <Tab label="Unassigned Users" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <div className="tabs-container">
                    <CustomTabPanel value={value} index={0}>
                      <UserList {...props} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <UnassignedUserList {...props} />
                    </CustomTabPanel>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
      />
      <Route path="/:userId/*" element={<User />} />
    </Routes>
  );
};

export default Users;
