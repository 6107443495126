import React, { useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "react-query";
import { addVehicleForSdvFeature } from "../../services/SdvFeature";
import { useToast } from "../../components/toast";
import VehicleListForSdvDataGrid from "../sdv-feature/VehicleListForSdvFeature";

const SdvFeatureVehicleMap = (props) => {
  const { addToast } = useToast();
  const { open, toggleDialog, sdvData } = props;
  const [selectedVehiclesList, setSelectedVehiclesList] = useState([]);
  const { sdvFeatureName, sdvFeatureId } = sdvData;

  const { mutate: mutateVehicleForSdvFeature, isLoading } = useMutation(
    addVehicleForSdvFeature,
    {
      onSuccess: async () => {
        toggleDialog();
        addToast({
          type: "success",
          message: `Vehicles successfully added to ${sdvFeatureName}.`,
          autoClose: 3000,
        });
      },
      onError: (e) => {
        const { response } = e;
        addToast({
          type: "error",
          message: response?.data?.message || "Failed to create",
          autoClose: 3000,
        });
      },
      onSettled: () => {},
    }
  );

  const handleSubmit = () => {
    const body = {
      sdvFeatureId: sdvFeatureId,
      serialNo: selectedVehiclesList,
    };
    mutateVehicleForSdvFeature(body);
  };

  return (
    <Dialog
      open={open}
      onClose={toggleDialog}
      PaperProps={{
        sx: { width: "55%", maxWidth: "1200px", minHeight: "500px" },
      }}
    >
      <DialogTitle>
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" gutterBottom>
            {`Add ${sdvFeatureName} to Vehicles`}
          </Typography>
          <div style={{ marginLeft: "1em" }}> </div>
        </span>
        <IconButton
          aria-label="close"
          onClick={toggleDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Tooltip title="Close create dialog">
            <CloseIcon />
          </Tooltip>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "auto" }}>
        <>
          <VehicleListForSdvDataGrid
            sdvData={sdvData}
            setSelectedVehiclesList={setSelectedVehiclesList}
            selectedVehiclesList={selectedVehiclesList}
          />
        </>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "end" }}>
        <div>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!selectedVehiclesList.length}
          >
            CREATE
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default SdvFeatureVehicleMap;
