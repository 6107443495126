import axios from "axios";
import Constants from "../services/Constants";
import { constructQueryUrl, formatDateWithoutTimeToIsoString } from "./Utils";

export const randColor = () =>
  `hsl(${Math.round(Math.random() * 360)}, 70%, 50%)`;

export const getDeploymentsByUsers = async () => {
  const url = Constants.DASHBOARD_GET_DEPLOYMENTS.replace(":group", "users");
  const { data = [] } = await axios.get(url);
  const result = data.map((item) => {
    return { ...item, color: randColor() };
  });
  return result;
};

export const getDeploymentsByStatus = async () => {
  const url = Constants.DASHBOARD_GET_DEPLOYMENTS.replace(":group", "status");
  const { data = [] } = await axios.get(url);
  const result = data.map((item) => {
    return { ...item, color: randColor() };
  });
  return result;
};

export const getDeploymentsCalendar = async ({ queryKey }) => {
  const [_, type, year, period] = queryKey;
  let result = [];
  if (type === "year") {
    const url = Constants.DASHBOARD_GET_DEPLOYMENT_FOR_YEAR.replace(
      ":year",
      year
    ).replace(":group", "year");
    const { data } = await axios.get(url);
    result = data.map((item) => {
      return { day: item.id, value: parseInt(item.value) };
    });
  } else if (type === "last_365_days") {
    const url = Constants.DASHBOARD_GET_DEPLOYMENT_FOR_DATE_RANGE.replace(
      ":startdate",
      formatDateWithoutTimeToIsoString(period.from)
    ).replace(":enddate", formatDateWithoutTimeToIsoString(period.to));
    const { data } = await axios.get(url);
    result = data.map((item) => {
      return { day: item.id, value: parseInt(item.value) };
    });
  }
  return result;
};

export const getDeploymentsByPeriod = async ({ queryKey }) => {
  const [_, type, period] = queryKey;
  let url = "";
  let result = [];
  if (type === "current_year") {
    url = Constants.DASHBOARD_GET_DEPLOYMENT_FOR_YEAR.replace(
      ":year",
      period
    ).replace(":group", "month");
    const { data } = await axios.get(url);
    const monthsData = [...Constants.MONTHS_NAMES];
    data.forEach((item) => {
      monthsData[new Date(item.id).getMonth()].value = item.value;
    });
    result = monthsData;
  } else if (type === "date_range") {
    url = Constants.DASHBOARD_GET_DEPLOYMENT_FOR_DATE_RANGE.replace(
      ":startdate",
      formatDateWithoutTimeToIsoString(period.from)
    ).replace(":enddate", formatDateWithoutTimeToIsoString(period.to));
    const { data } = await axios.get(url);
    result = data.map((item) => {
      return {
        id: new Date(item.id).toLocaleDateString(),
        value: parseInt(item.value),
      };
    });
  } else if (type === "last_365_days") {
    url = Constants.DASHBOARD_GET_DEPLOYMENTS.replace(":group", "365days");
    const { data } = await axios.get(url);
    result = data.map((item) => {
      return {
        id: item?.id,
        value: parseInt(item?.value),
      };
    });
  }
  return result;
};

export const getStatsForDashboard = async () => {
  const { data = {} } = await axios.get(Constants.DASHBOARD_GET_DASH_STATS);
  return data;
};

export const getUserActivityCalendar = async ({ queryKey }) => {
  const [_, type, year, period, userid] = queryKey;
  let result = [];
  if (type === "year") {
    const url = Constants.GET_USER_ACTIVITY_FOR_YEAR.replace(":type", "year")
      .replace(":userid", userid)
      .replace(":year", year);
    const { data = [] } = await axios.get(url);
    result = data.map((item) => {
      return { day: item.id, value: parseInt(item.value) };
    });
  } else if (type === "last_365_days") {
    const url = Constants.GET_USER_ACTIVITY_FOR_DATE_RANGE.replace(
      ":type",
      "range"
    )
      .replace(":userid", userid)
      .replace(":from", formatDateWithoutTimeToIsoString(period.from))
      .replace(":to", formatDateWithoutTimeToIsoString(period.to));
    const { data = [] } = await axios.get(url);
    result = data.map((item) => {
      return { day: item.id, value: parseInt(item.value) };
    });
  }
  return result;
};

export const getReportsForProvisioningByDateRange = async ({ queryKey }) => {
  const [_, searchObj] = queryKey;
  const { type, dateRange } = searchObj;
  let url = Constants.REPORTS_GET_PROVISIONING_DATA_FOR_DATE_RANGE.replace(
    ":type",
    type
  )
    .replace(":startdate", dateRange?.from?.toISOString())
    .replace(":enddate", dateRange?.to?.toISOString());
  const { data } = await axios.get(url);
  return data;
};

export const getReportsForSchedulerByDateRangeWithPagination = async ({
  queryKey,
}) => {
  const [
    _,
    page = 1,
    pageSize = 50,
    field = "updatedAt",
    sort = "DESC",
    filterValue = {},
    columns,
    extraFilters = [],
  ] = queryKey;
  const newFilterValue = JSON.parse(JSON.stringify(filterValue));
  try {
    if (newFilterValue?.items) {
      newFilterValue.items = [...filterValue.items, ...extraFilters];
    } else {
      newFilterValue["items"] = [...extraFilters];
    }
  } catch (e) {
    console.log(e);
  }
  let query =
    Constants.REPORTS_GET_SCHEDULER_DATA_FOR_DATE_RANGE +
    `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}`;

  query = constructQueryUrl({ filterValue: newFilterValue, query, columns });
  return await axios.get(query);
};

export const getReportsForSchedulerByDateRange = async ({ queryKey }) => {
  console.log(queryKey);
  const [_, dateRange] = queryKey;
  const { from, to } = dateRange;
  let query =
    Constants.REPORTS_GET_SCHEDULER_DATA_FOR_DATE_RANGE +
    `?from=${from?.toISOString()}&to=${to?.toISOString()}`;
  return await axios.get(query);
};

export const schedulerProvisionAndScan = async () => {
  return await axios.post(Constants.ENTITLEMENT_SCHEDULER_PROVISIONING);
};

export const getListOfProvisioningCsv = async () => {
  return await axios.get(Constants.GET_PROVISIONING_CSV_LIST);
};

export const provisioningSelectedFiles = async (payload) => {
  return await axios.post(
    Constants.ENTITLEMENT_SCHEDULER_PROVISIONING,
    payload
  );
};

export const getAppVersion = async () => {
  return await axios.get(Constants.SERVER_CONFIG);
};
