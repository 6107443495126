import React from "react";
import { Grid, Box, Card, CardContent, Typography } from "@mui/material";
import { getStatsForVehiclesView } from "../../services/vehicle";
import { useQuery } from "react-query";

const VehicleStats = () => {
  const { data, isLoading } = useQuery(
    "getStatsForDashboard",
    getStatsForVehiclesView,
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      <Grid item xs={6} md={3}>
        <Box>
          <Card>
            <CardContent>
              <Typography color="text.secondary">
                Visteon Provisioned
              </Typography>
              <Typography color="text.primary" sx={{ fontSize: 25 }}>
                {data?.visteonProvisioned}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item xs={6} md={3}>
        <Box>
          <Card>
            <CardContent>
              <Typography color="text.secondary">
                Customer Provisioned
              </Typography>
              <Typography color="text.primary" sx={{ fontSize: 25 }}>
                {data?.customerProvisioned}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item xs={6} md={4}>
        <Box>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Typography color="text.secondary">
                    Active vehicles
                  </Typography>
                  <Typography color="text.primary" sx={{ fontSize: 25 }}>
                    {data?.activeVehicles}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">
                    Inactive vehicles
                  </Typography>
                  <Typography color="text.primary" sx={{ fontSize: 25 }}>
                    {data?.inActiveVehicles}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>      
      <Grid item xs={6} md={2}>
        <Box>
          <Card>
            <CardContent>
              <Typography color="text.secondary">
                Subscriptions(Vehicles)
              </Typography>
              <Typography color="text.primary" sx={{ fontSize: 25 }}>
                {data?.vehiclesWithActiveSubscriptions}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </>
  );
};

export default VehicleStats;
