import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TabPanel from "../Tabpanel";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Box,
} from "@mui/material";
import { useQuery } from "react-query";
import { detailsTabsBoxStyle } from "../../services/Utils";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getDeploymentDetails,
  getDeploymentAudits,
} from "../../services/vehicle";
import { formatDate, formatTimeStamp } from "../../services/Utils";
import DeploymentJobDocumentTab from "../deployment/DeploymentJobDocumentTab";
import { formatDate_12h } from "../../services/Utils";
import VehicleDeploymentList from "../deployment/VehicleDeploymentList";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import Constants from "../../services/Constants";

const VehicleDeploymentDetails = () => {
  let { vehicleId, deploymentId } = useParams();

  const [errMsg, setErrMsg] = useState(
    "Deployment not yet published for this device"
  );

  // deploymentDetails Queries
  const {
    data: { data: deploymentDetail = {} } = {},
    isLoading: deploymentLoading,
  } = useQuery(
    ["deploymentDetails", deploymentId, vehicleId],
    getDeploymentDetails,
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        //const {response: {data: {message = ""} = {}} = {}} = error;
        setErrMsg(Constants.COMMON_DEP_ERR_MSG);
      },
    }
  );

  // jobExecutions Queries
  const { data: { data: jobAuditData = [] } = {} } = useQuery(
    ["deploymentAudits", deploymentId, vehicleId],
    getDeploymentAudits,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (deploymentLoading) {
    return <CircularProgress />;
  }
  if (!deploymentDetail?.job) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="warning">
          {errMsg}
        </Alert>
      </Box>
    );
  }

  const { vehicle: { serialNo = null, vehicleKey = null } = {} } =
    deploymentDetail;

  const vehicles = [
    {
      serialNo,
      vehicleKey,
    },
  ];

  return (
    <TabPanel>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: "16px" }}>
          <Typography
            style={{ marginBottom: "8px", paddingBottom: "0" }}
            className="details-heading"
          >
            Deployment Detail
          </Typography>
          <VehicleDeploymentList vehicles={vehicles} type="vehicle" />
        </Grid>

        {jobAuditData.length > 0 && (
          <Grid item xs={12} sx={{ mt: "16px" }}>
            <Typography
              style={{ marginBottom: "8px", paddingBottom: "0" }}
              className="details-heading"
            >
              Job Audit
            </Typography>
            <TableContainer sx={{ maxHeight: 800, overflow: "auto" }}>
              <Table
                stickyHeader
                sx={{ minWidth: 650, border: "1px solid #e2e2e2" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Event Time</TableCell>
                    <TableCell>Android Time</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobAuditData.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {formatDate_12h(row?.createdAt)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDate_12h(row?.eventTime)}
                      </TableCell>
                      <TableCell>{row.signal}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {deploymentDetail?.job?.status !== "IN_PROGRESS" ? (
          <>
            {/* 
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle">Job Id </Typography>
                <Typography>{deploymentDetail.job.jobId}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle">Job Status </Typography>
                <Typography>{deploymentDetail.job.status}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle">Created At </Typography>
                <Typography>{deploymentDetail.job.createdAt}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle">Completed At </Typography>
                <Typography>{deploymentDetail.job.completedAt}</Typography>
              </Grid>*/}
            <Grid item xs={12} sx={{ mt: "16px" }}>
              <Typography
                style={{ marginBottom: "8px", paddingBottom: "0" }}
                className="details-heading"
              >
                Job Document
              </Typography>
              <DeploymentJobDocumentTab />
            </Grid>
          </>
        ) : (
          <>
            {/*<Grid item xs={12} md={3}>
                <Typography variant="subtitle">Job Execution Id </Typography>
                <Typography>{deploymentDetail.jobExecution.jobId}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Job Execution Status</Typography>
                <Typography>{deploymentDetail.jobExecution.status}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Last Updated At</Typography>
                <Typography>
                  {deploymentDetail.jobExecution.lastUpdatedAt}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Queued At</Typography>
                <Typography>
                  {deploymentDetail.jobExecution.queuedAt}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Started At</Typography>
                <Typography>
                  {deploymentDetail.jobExecution.startedAt}
                </Typography>
          </Grid>*/}
            <Grid item xs={12}>
              <Typography className="details-heading">Job Document</Typography>
              <DeploymentJobDocumentTab />
            </Grid>
          </>
        )}
      </Grid>
    </TabPanel>
  );
};

export default VehicleDeploymentDetails;
