import React, { useState } from "react";
import {
  Outlet,
  Link as RouterLink,
  useParams,
  useLocation,
} from "react-router-dom";
import { Typography, Tabs, Tab, Box, Paper } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import {
  VehicleDeploymentDetails,
  VehicleDetailsTab,
  VehicleDeploymentsTab,
  VehicleLogsTab,
  VehicleRemoteCommands,
  VehicleAttributesTab,
  VehicleCustomConfigTab,
} from "../components/vehicle";
import { useQuery } from "react-query";
import { detailsTabsBoxStyle, getCurrentPathTab } from "../services/Utils";
import VehicleSubscriptionList from "../components/vehicle/VehicleSubscriptionList";
import { getVehicleDetails } from "../services/vehicle";
import BreadCrumbsRendered from "../components/BreadCrumbsRendered";
import VehicleConfigTab from "../components/vehicle/VehicleConfigTab";
import AssociatedGroupsForVehicle from "../components/vehicle/AssociatedGroupsForVehicle";
import TripsList from "../components/vehicle/TripsList";

const PATHS = [
  "overview",
  "attributes",
  "packages",
  "subscriptions",
  "deployments",
  "groups",
  "configurations",
  "custom-configuration",
  "remote-commands",
  "livelogs",
  "trips",
];

const Vehicle = (props) => {
  const { vehicleId = "" } = useParams();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, "overview")
  );

  const { data: { data: vehicleData = [] } = {}, isLoading } = useQuery(
    ["vehicleDetails", vehicleId],
    getVehicleDetails,
    {
      refetchOnWindowFocus: true,
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const commonProps = {
    vehicleData,
    isLoading,
    vehicleId,
  };

  return (
    <div className="fragmentContainer">
      <BreadCrumbsRendered pathname={pathname} />
      <Box
        sx={{ width: "100%", marginTop: 4, padding: "2%" }}
        component={Paper}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs onChange={handleChange} value={value} variant="scrollable">
            <Tab
              label="Overview"
              value={"overview"}
              component={RouterLink}
              to={"overview"}
            />
            {/* <Tab
              label="Attributes"
              value={"attributes"}
              component={RouterLink}
              to={"attributes"}
            /> */}
            <Tab
              label="Packages"
              value={"packages"}
              component={RouterLink}
              to={"packages"}
            />
            <Tab
              label="Subscriptions"
              value={"subscriptions"}
              component={RouterLink}
              to={"subscriptions"}
            />
            <Tab
              label="Deployments"
              value={"deployments"}
              component={RouterLink}
              to={"deployments"}
            />
            <Tab
              label="Groups"
              value={"groups"}
              component={RouterLink}
              to={"groups"}
            />
            <Tab
              label="Configurations"
              value={"configurations"}
              component={RouterLink}
              to={"configurations"}
            />
            <Tab
              label="Custom Config"
              value={"custom-configuration"}
              component={RouterLink}
              to={"custom-configuration"}
            />
            <Tab
              label="Remote Commands"
              value={"remote-commands"}
              component={RouterLink}
              to={"remote-commands"}
            />
            <Tab
              label="Live Logs"
              value={"livelogs"}
              component={RouterLink}
              to={"livelogs"}
            />
            <Tab
              label="Trips"
              value={"trips"}
              component={RouterLink}
              to={"trips"}
            />
          </Tabs>
        </Box>
        <div className="tabs-container">
          <Routes>
            <Route path="/" element={<VehicleDetailsTab {...commonProps} />} />
            <Route
              path="overview"
              element={<VehicleDetailsTab {...commonProps} />}
            />
            {/* <Route
              path="attributes"
              element={<VehicleAttributesTab {...commonProps} />}
            /> */}
            <Route
              path="packages"
              element={
                <Box {...detailsTabsBoxStyle}>
                  <Typography>Packages</Typography>
                </Box>
              }
            />
            <Route
              path="subscriptions"
              element={<VehicleSubscriptionList {...commonProps} />}
            />
            <Route
              path="deployments"
              element={<VehicleDeploymentsTab {...commonProps} />}
            />
            <Route
              path="groups"
              element={<AssociatedGroupsForVehicle {...commonProps} />}
            />
            <Route
              path="configurations"
              element={<VehicleConfigTab {...commonProps} />}
            />
            <Route
              path="custom-configuration"
              element={<VehicleCustomConfigTab {...commonProps} />}
            />
            <Route
              path="remote-commands"
              element={<VehicleRemoteCommands {...commonProps} />}
            />
            <Route
              path="livelogs"
              element={<VehicleLogsTab vehicleId={vehicleId} />}
            />
            <Route path="trips" element={<TripsList serialNo={vehicleId} />} />

            <Route
              path="deployments/:deploymentId"
              element={<VehicleDeploymentDetails />}
            />
          </Routes>
          <Outlet />
        </div>
      </Box>
    </div>
  );
};

export default Vehicle;
