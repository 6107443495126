import React, { useState } from "react";
import { string, object, bool } from "prop-types";
import { useQueryClient } from "react-query";
import axios from "axios";
import Constants from "../../services/Constants";
import { Button, Menu, MenuItem, Checkbox, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { updateUserOrgs, getAllAvailableOrgs } from "../../services/Users";
import { useMutation, useQuery } from "react-query";
import { useToast } from "../toast";

const AssignOrgBtn = (props) => {
    const { addToast } = useToast();
    const { dbUserId = "" } = props;
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();

    const { mutate: updateOrgsForUser } = useMutation(updateUserOrgs, {
        onSuccess: (res) => {
            addToast({
                type: "success",
                message: `User organization updated successfully.`,
                autoClose: 3000,
            });
        },
        onError: (e) => {
            addToast({
                type: "error",
                message: "Failed to update organization for the user!",
                autoClose: 3000,
            });
        },
        onSettled: () => {
            setLoading(false);
            // Invalidate and refetch
            queryClient.invalidateQueries('getAllUsersData');
            queryClient.invalidateQueries('getAllUnassignedUsers');
        },
    });

    const handleSubmit = async () => {
        setLoading(true);
        updateOrgsForUser({ userId: dbUserId, payload: { add: [localStorage?.selectedCustomer] } });
        setLoading(false);
    };

    return (
        <Button
            variant="text"
            onClick={handleSubmit}
            endIcon={loading && <HourglassTopIcon />}
            disabled={loading}
        >
            Move to {localStorage?.selectedCustomer}
        </Button>
    );
};

AssignOrgBtn.defaultProps = {
    userId: "",
    enabled: false,
    tableData: {},
};
AssignOrgBtn.propTypes = {
    userId: string,
    enabled: bool,
    tableData: object,
};

export default AssignOrgBtn;
