import React, { useRef, useState } from "react";
import { useParams, Link as RouterLink, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Grid,
  Fab,
  Drawer,
  IconButton,
} from "@mui/material";
import {
  formatDate,
  getCurrentPathTab,
  detailsTabsBoxStyle,
  overviewListItemStyles,
} from "../services/Utils";
// import { getReleasesByImageId } from "../services/Release";
import { getColumnsData } from "../components/release/ReleaseColumns";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CommentIcon from "@mui/icons-material/Comment";
import InfoIcon from "@mui/icons-material/Info";
import styled from "styled-components";
import CommentsList from "../components/clusterSimulations/comments/CommentsList";
import SimulationViewer from "../components/clusterSimulations/SimulationViewer";
import { getClusterImageById } from "../services/cluster";
import CancelIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const PATHS = ["preview"];

export default function Cluster() {
  let params = useParams();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, PATHS[0])
  );
  const [simulationDetails, setSimulationDetails] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: { data } = { data: [] } } = useQuery(
    ["fetchSimulationDetails", params?.simulationId],
    getClusterImageById,
    {
      refetchOnWindowFocus: false,
      enabled: !!params?.simulationId,
      onSuccess: (res) => {
        console.log("cluster res ", res);
        setSimulationDetails(res);
      },
    }
  );

  const Overview = ({ toggleDetailsDrawer }) => {
    return (
      <div style={{ padding: "0" }} {...detailsTabsBoxStyle}>
        <IconButton
          color="primary"
          onClick={() => {
            toggleDetailsDrawer?.();
          }}
          style={{
            position: "absolute",
            right: "0",
            paddingLeft: "5px",
            paddingTop: "5px",
            zIndex: "2",
          }}
        >
          <CancelIcon />
        </IconButton>
        <Grid container style={{ marginTop: "1em" }}>
          <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
            <List>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Simulation Name"
                  secondary={simulationDetails?.simulationName}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Simulation Upload Id"
                  secondary={simulationDetails?.simulationUploadId}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Simulation Type"
                  secondary={simulationDetails?.simulationType}
                />
              </ListItem>
              {/* <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="File Size"
                  secondary={formatFileSize(simulationDetails?.fileSize)}
                />
              </ListItem> */}
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Version"
                  secondary={simulationDetails?.version}
                />
              </ListItem>
              {/* <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Base Version"
                  secondary={simulationDetails?.baseVersion || "-"}
                />
              </ListItem> */}
            </List>
          </Grid>
          <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
            <List>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Description"
                  secondary={simulationDetails?.description}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Upload status"
                  secondary={simulationDetails?.uploadStatus}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="ChecksumSHA256"
                  secondary={simulationDetails?.checksumSHA256 || "-"}
                  secondaryTypographyProps={{
                    style: {
                      whiteSpace: "nowrap",
                      overflowX: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
            <List>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Uploaded By"
                  secondary={simulationDetails?.uploadedBy}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Updated At"
                  secondary={formatDate(simulationDetails?.updatedAt)}
                />
              </ListItem>
              {/* <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Campaign"
                  secondary={getCampaignNameString(
                    simulationDetails?.campaignType
                  )}
                />
              </ListItem> */}
            </List>
          </Grid>
        </Grid>
      </div>
    );
  };

  const Preview = (props) => {
    const theme = useTheme();
    const [commentsDrawerOpen, setCommentsDrawerOpen] = useState(false);
    const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
    const [showCommentsTable, setShowCommentsTable] = useState(false);
    const [addComment, setAddComment] = useState(null);
    // const { imageId } = props;

    const StyledDrawer = styled(Drawer)`
      &.MuiDrawer-root > div {
        border-left: 2px solid;
        border-right: 2px solid;
        border-color: ${theme.palette.primary[700]};
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5px;
      }
      &:hover.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: red;
      }
      &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: purple;
      }
    `;

    const columns = getColumnsData({ hideActions: false });
    const clusterViewerRef = useRef(null);
    const enterFullscreen = () => {
      const elem = clusterViewerRef.current;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        // Chrome, Safari, and Opera
        elem.webkitRequestFullscreen();
      }
    };

    const exitFullscreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    };

    const handleFullscreen = () => {
      if (!document.fullscreenElement) {
        enterFullscreen();
      } else {
        exitFullscreen();
      }
    };

    // const { data: { data } = { data: [] } } = useQuery(
    //   ["fetchProvisioningReport", imageId],
    //   getReleasesByImageId,
    //   {
    //     refetchOnWindowFocus: false,
    //     onSuccess: (res) => {
    //       console.log(res);
    //     },
    //   }
    // );

    const toggleDetailsDrawer = () => {
      setCommentsDrawerOpen(false);
      setDetailsDrawerOpen(!detailsDrawerOpen);
    };

    const toggleCommentsDrawer = () => {
      setDetailsDrawerOpen(false);
      setCommentsDrawerOpen(!commentsDrawerOpen);
    };
    const toggleCommentsTable = () => {
      setShowCommentsTable(!showCommentsTable);
    };

    return (
      <>
        <Box
          style={{ padding: "0" }}
          sx={{ height: "calc(100vh - 96px)", width: "100%" }}
          ref={clusterViewerRef}
        >
          <div
            id="cluster-preview-controls"
            style={{
              display: "flex",
              position: "absolute",
              right: "26px",
              margin: "8px",
              width: "362px",
              justifyContent: "space-between",
            }}
          >
            <Fab
              onClick={() => {
                toggleCommentsDrawer();
              }}
              variant="extended"
              size="medium"
              color="primary"
            >
              <CommentIcon sx={{ mr: 1 }} />
              Comments
            </Fab>
            <Fab
              onClick={() => {
                toggleDetailsDrawer();
              }}
              variant="extended"
              size="medium"
            >
              <InfoIcon variant="extended" sx={{ mr: 1 }} />
              Details
            </Fab>

            <Fab
              onClick={() => {
                handleFullscreen();
              }}
              variant="extended"
              size="medium"
              color="secondary"
            >
              <FullscreenIcon />
            </Fab>
          </div>

          {/* <Draggable handle="#draggable-handle"> */}
          <SimulationViewer
            id="cluster-viewer"
            src={
              // "https://ota-nextdev-base-s3storagesimulationimagerepobucke-udtpr4hnh9gz.s3.us-east-2.amazonaws.com/bdf8f8d8-a10c-4ea7-8d6e-b77aff4d6fcd/index2.html"
              simulationDetails?.downloadUrl
              // ||
              // "https://clustersimulationspoc.s3.amazonaws.com/1.0.0/index.html"
            }
          />
          <StyledDrawer
            open={commentsDrawerOpen}
            anchor="bottom"
            variant="persistent"
            // onClose={toggleCommentsDrawer(false)}
          >
            <CommentsList
              showCommentsTable={showCommentsTable}
              toggleCommentsTable={toggleCommentsTable}
              simulationUploadId={simulationDetails?.simulationUploadId}
              toggleCommentsDrawer={toggleCommentsDrawer}
            />
          </StyledDrawer>
          <StyledDrawer
            open={detailsDrawerOpen}
            anchor="bottom"
            variant="persistent"
            // onClose={toggleCommentsDrawer(false)}
          >
            <Overview toggleDetailsDrawer={toggleDetailsDrawer} />
          </StyledDrawer>
          {/* </Draggable> */}
        </Box>
      </>
    );
  };

  return (
    <div className="fragmentContainer">
      {/* <BreadCrumbsRendered pathname={pathname} /> */}
      <Box
        sx={{ width: "100%", marginTop: 0, padding: "8px" }}
        component={Paper}
      >
        <Preview {...params} />
      </Box>
    </div>
  );
}
