import React, { useEffect, useState } from "react";
import { Alert, Box, Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useToast } from "../../toast";
import { detailsTabsBoxStyle } from "../../../services/Utils";
import {
  getVehicleShadow,
  updateRemoteCommands,
} from "../../../services/vehicle";
import topview from "../../../images/topview-vehicle.png";
import { MODULES, MODULE_TEXT } from "../../../services/Constants";
import useModule from "../../../hooks/useModule";
import Check from "../../../auth/ability";
import { CAN, SUBJECTS } from "../../../auth/permissions";

const ToggleButtons = ({ value = false, handleDoorStatus, type }) => {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleDoorStatus}
      aria-label="door status"
    >
      <ToggleButton value="false" data-label={type}>
        <LockIcon data-label={type} />
      </ToggleButton>
      <ToggleButton value="true" data-label={type}>
        <LockOpenIcon data-label={type} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const VehicleRemoteCommands = ({ vehicleId, vehicleData }) => {
  const initState = {
    openFrontRightDoor: "false",
    openFrontLeftDoor: "false",
    openRearLeftDoor: "false",
    openRearRightDoor: "false",
  };
  const { addToast } = useToast();
  const { validate } = useModule();
  const [doorStatus, setDoorStatus] = useState(initState);

  const handleDoorStatus = (event, newStatus) => {
    const doors = [
      "openFrontRightDoor",
      "openFrontLeftDoor",
      "openRearLeftDoor",
      "openRearRightDoor",
    ];
    let type = event.target.getAttribute("data-label");
    if (type === null) {
      type = event.target.parentElement.getAttribute("data-label");
    }
    if (doors.includes(type) && newStatus !== null) {
      const status = { ...doorStatus };
      status[type] = newStatus;
      setDoorStatus({
        ...status,
      });
      const shadowData = {
        desired: {
          remoteCommands: {
            ...status,
          },
        },
      };
      mutate({
        thingName: vehicleId,
        payload: JSON.stringify({ state: shadowData }),
      });
    }
    console.log(
      type,
      newStatus,
      event.target.parentElement.getAttribute("data-label")
    );
  };

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const {
    data: { data: shadowData = {} } = {},
    isLoading: getShadowLoading,
    refetch,
  } = useQuery(["getVehicleShadow", vehicleId], getVehicleShadow, {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  // Mutations
  const { mutate, isLoading: mutateLoading } = useMutation(
    updateRemoteCommands,
    {
      onSuccess: (data) => {
        if (data) {
          addToast({
            type: "success",
            message: `Remote Command updated.`,
            autoClose: 3000,
          });
        }
      },
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          addToast({
            type: "error",
            message: errorData.message,
            autoClose: 3000,
          });
        }
      },
      onSettled: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("getVehicleShadow");
      },
    }
  );

  useEffect(() => {
    if (shadowData && shadowData.payload) {
      const shadowPayload = JSON.parse(shadowData?.payload);
      const {
        state: { desired: { remoteCommands = { ...initState } } = {} } = {},
      } = shadowPayload;
      setDoorStatus(remoteCommands);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shadowData]);

  if (!validate(MODULES.REMOTE_COMMANDS)) {
    return <Box {...detailsTabsBoxStyle}>
      <Alert variant="outlined" severity="warning">
        {MODULE_TEXT.REMOTE_COMMANDS} module not enabled for {localStorage.selectedCustomer} customer
      </Alert>
    </Box>
  }

  if (!vehicleData?.device?.certAvailable) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="warning">
          Device setup incomplete!
        </Alert>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="door-lock">
          <div className="door-button">
            <Check I={CAN.MANAGE} a={SUBJECTS.REMOTE_COMMANDS}>
              <ToggleButtons
                handleDoorStatus={handleDoorStatus}
                value={doorStatus.openFrontRightDoor}
                type={"openFrontRightDoor"}
              />
              <span className="door-text"> Driver Door </span>
            </Check>
          </div>
          <div className="door-button">
            <Check I={CAN.MANAGE} a={SUBJECTS.REMOTE_COMMANDS}>
              <ToggleButtons
                handleDoorStatus={handleDoorStatus}
                value={doorStatus.openRearRightDoor}
                type={"openRearRightDoor"}
              />
              <span className="door-text"> Rear Right Door </span>
            </Check>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="topview-vehicle">
          <img src={topview} alt="top view vehicle" />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="door-lock">
          <div className="door-button">
            <Check I={CAN.MANAGE} a={SUBJECTS.REMOTE_COMMANDS}>
              <ToggleButtons
                handleDoorStatus={handleDoorStatus}
                value={doorStatus.openFrontLeftDoor}
                type={"openFrontLeftDoor"}
              />
              <span className="door-text"> Front Left Door </span>
            </Check>
          </div>
          <div className="door-button">
            <Check I={CAN.MANAGE} a={SUBJECTS.REMOTE_COMMANDS}>
              <ToggleButtons
                handleDoorStatus={handleDoorStatus}
                value={doorStatus.openRearLeftDoor}
                type={"openRearLeftDoor"}
              />
              <span className="door-text"> Rear Left Door </span>
            </Check>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default VehicleRemoteCommands;
