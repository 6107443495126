import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { DataGrid } from "@mui/x-data-grid";
import { getAllUnassignedUsers } from "../../services/Users";
import Constants from "../../services/Constants";
import { getColumnsData } from "./UnassignedUserColumns";

const UnassignedUserList = ({ user }) => {
    const [pageSize, setPageSize] = React.useState(Constants.PAGE_SIZE);

    const { data: users = [], isLoading: userLoading } = useQuery("getAllUnassignedUsers", getAllUnassignedUsers, {
        refetchOnWindowFocus: true,
    });

    const columns = getColumnsData({
        tableData: {
            users,
        },
        user,
    });

    return (
        <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
            pagination
            loading={userLoading}
            rows={users}
            columns={columns}
            sx={{ height: "calc(100vh - 290px)" }}
            checkboxSelection={false}
            getRowId={(row) => row.userId}
        />
    );
};

UnassignedUserList.defaultProps = {
    user: {},
};
UnassignedUserList.propTypes = {};

export default UnassignedUserList;
