import React, { useState } from "react";
import {
  Outlet,
  Link as RouterLink,
  useParams,
  useLocation,
} from "react-router-dom";
import { Tabs, Tab, Box, Paper } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { VehicleGroupDetailsTab } from "../components/vehicle";
import { useQuery } from "react-query";
import { getCurrentPathTab } from "../services/Utils";
import { getVehicleGroupDetailsById } from "../services/vehicle";
import BreadCrumbsRendered from "../components/BreadCrumbsRendered";
import VehiclesInGroupTab from "../components/vehicle/VehiclesInGroupTab";
import DeploymentsForGroupTab from "../components/vehicle/DeploymentsForGroupTab";
import GroupDeploymentDetails from "../components/vehicle/GroupDeploymentDetails";

const PATHS = ["overview", "vehicles", "deployments"];

const VehicleGroup = () => {
  const { groupId = "" } = useParams();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, "overview")
  );

  const { data: { data: groupData } = {}, isLoading } = useQuery(
    ["vehicleGroupDetails", groupId],
    getVehicleGroupDetailsById,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const commonProps = {
    groupData,
    isLoading,
    groupId,
  };

  return (
    <div className="fragmentContainer">
      <BreadCrumbsRendered pathname={pathname} />
      <Box
        sx={{ width: "100%", marginTop: 4, padding: "2%" }}
        component={Paper}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs onChange={handleChange} value={value} variant="scrollable">
            <Tab
              label="Overview"
              value={"overview"}
              component={RouterLink}
              to={"overview"}
            />
            <Tab
              label="Vehicles"
              value={"vehicles"}
              component={RouterLink}
              to={"vehicles"}
            />
            <Tab
              label="Deployments"
              value={"deployments"}
              component={RouterLink}
              to={"deployments"}
            />
          </Tabs>
        </Box>
        <div className="tabs-container">
          <Routes>
            <Route
              path="/"
              element={<VehicleGroupDetailsTab {...commonProps} />}
            />
            <Route
              path="overview"
              element={<VehicleGroupDetailsTab {...commonProps} />}
            />
            <Route
              path="vehicles"
              element={<VehiclesInGroupTab {...commonProps} />}
            />
            <Route
              path="deployments"
              element={<DeploymentsForGroupTab {...commonProps} />}
            />
            <Route
              path="deployments/:deploymentId"
              element={<GroupDeploymentDetails {...commonProps} />}
            />
          </Routes>
          <Outlet />
        </div>
      </Box>
    </div>
  );
};

export default VehicleGroup;
