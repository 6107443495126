import React, { useState } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  FormControl,
  CardActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Paper,
  FormGroup,
  Divider,
} from "@mui/material";
import useDebounce from "../../hooks/useDebounce";
import SearchDateInput from "./searchDateInput";
import SearchItemsPreview from "./searchItemsPreview";
import { formatDisplayDatesForSearch } from "../../services/Utils";
import MultipleGroupSelect from "./MultipleGroupSelect";

const searchFields = [
  // {
  //   columnField: "vehicle.vehiclePurchaseDetails.purchaseDate",
  //   label: "Purchased",
  // },
  // { columnField: "serialNoCreatedAt", label: "Provisioned" },
  // { columnField: "vehicleKeyUpdatedAt", label: "Manufactured" },
];

const VehicleSearch = ({
  setVehicleId,
  extraFilters,
  setExtraFilters,
  resetDataTableSelectionItems,
}) => {
  const [newVehicleId, setNewVehicleId] = useState("");
  const debouncedVehicleName = useDebounce(newVehicleId, 750);

  const initialDates = {
    from: new Date(new Date().setFullYear("2000")),
    to: new Date(),
  };

  const [manufacturedChecked, setManufacturedDateChecked] = useState(false);
  const [manufacturedDateObj, setManufacturedDateObj] = useState(
    JSON.parse(JSON.stringify(initialDates))
  );

  const [provisionedDateChecked, setProvisionedDateChecked] = useState(false);
  const [provisionedDateObj, setProvisionedDateObj] = useState(
    JSON.parse(JSON.stringify(initialDates))
  );

  const [purchasedDateChecked, setPurchasedDateChecked] = useState(false);
  const [purchasedDateObj, setPurchasedDateObj] = useState(
    JSON.parse(JSON.stringify(initialDates))
  );

  const [datefilterSelection, setDatefilterSelection] =
    useState("manufactured");

  const handleDateFilterSelectionChange = (event) => {
    setDatefilterSelection(event.target.value);
  };

  const sampleDateFilterItem = {
    value: {
      from: initialDates?.from?.toISOString(),
      to: initialDates?.to?.toISOString(),
    },
    operatorValue: "between",
    columnField: "vehicle.vehiclePurchaseDetails.purchaseDate",
  };

  const initialDateFilterItems = searchFields.map((i) => {
    return {
      ...JSON.parse(JSON.stringify(sampleDateFilterItem)),
      columnField: i?.columnField,
      label: i?.label,
      displayValue: formatDisplayDatesForSearch(initialDates),
    };
  });

  const [filterItems, setFilterItems] = useState(initialDateFilterItems);
  const [status, setStatus] = useState("all");
  const [navFeatureStatus, setNavFeatureStatus] = useState("all");
  const [subscriptionStatus, setSubscriptionStatus] = useState("all");
  const [certAvailable, setCertAvailable] = useState("all");
  const [vehicleKey, setVehicleKey] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);

  const onSearchSubmit = () => {
    const getVehicleKeyInFilter = extraFilters?.filter((i) => {
      return i.columnField === "vehicleKey" && i?.value?.item?.length; // Check if the vehicle groups filter items are in the extra filter list
    });
    if (getVehicleKeyInFilter?.length) {
      const getVehicleKeyContainsString = filterItems.filter((i) => {
        return i.columnField === "vehicleKey";
      });
      if (getVehicleKeyContainsString?.length) {
        const columnValue = {
          ...getVehicleKeyInFilter?.[0].value,
          ...getVehicleKeyContainsString?.[0].value,
        };
        const vehicleKeyFilterItem = getVehicleKeyInFilter?.[0];
        vehicleKeyFilterItem.value = columnValue;
        vehicleKeyFilterItem.displayValue =
          getVehicleKeyContainsString?.[0]?.displayValue;
        const newFilterItems = filterItems.filter((i) => {
          return i.columnField !== "vehicleKey";
        });
        setExtraFilters([...newFilterItems, ...[vehicleKeyFilterItem]]);
      } else {
        setExtraFilters([...filterItems, ...getVehicleKeyInFilter]);
      }
    } else {
      setExtraFilters([...filterItems]);
    }
    resetDataTableSelectionItems?.(); // Reset vehicles list data table selection
  };

  const resetSearchFormFilterAlone = () => {
    const itemsToRetainInFilter = ["vehicleKey"]; // column fields to retain in search filter
    const newItems = extraFilters.filter(
      (i) => itemsToRetainInFilter.indexOf(i.columnField) > -1
    ); // retains the filter items in extrafilters array
    if (newItems?.length) {
      if (newItems?.[0]?.value?.items?.length) {
        delete newItems[0].value.searchString;
        setExtraFilters([...newItems]);
      } else {
        setExtraFilters([]);
      }
    } else {
      setExtraFilters([]);
    }
  };

  const clearSearchForm = () => {
    setNewVehicleId(""); // Reset serial no input
    setVehicleId(""); // Reset serial no input
    setStatus("all"); // Reset status
    setNavFeatureStatus("all"); // Reset Navigation feature status
    setSubscriptionStatus("all"); // Reset subscriptions status input
    setCertAvailable("all"); // Reset the cert available input
    setVehicleKey(""); // Reset created by input
    setSelectedGroups([]); // Reset the vehicle groups multiselect input
    setPurchasedDateObj(JSON.parse(JSON.stringify(initialDates))); // Reset purchase date
    setProvisionedDateObj(JSON.parse(JSON.stringify(initialDates))); // Reset purchase date
    setManufacturedDateObj(JSON.parse(JSON.stringify(initialDates))); // Reset purchase date
    setFilterItems(initialDateFilterItems);
    setManufacturedDateChecked(false);
    setProvisionedDateChecked(false);
    setPurchasedDateChecked(false);
    setDatefilterSelection("manufactured"); // Reset date inputs radio selection
    resetSearchFormFilterAlone(); // Reset the filters array which is sent to the api
    resetDataTableSelectionItems?.(); // Reset vehicles list data table selection
  };

  const saveFilterItemsToFilterArray = (item) => {
    const { columnField, operatorValue, value } = item;
    const oldItems = filterItems;
    const newItems = oldItems.filter((i) => i.columnField !== columnField);
    setFilterItems([...newItems, item]);
  };

  const removeFilterItemsFromFilterArray = (columnField) => {
    const newItems = filterItems.filter((i) => i.columnField !== columnField);
    setFilterItems([...newItems]);
  };

  const handleGroupMultiSelectChange = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedGroups(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    if (!!value) {
      saveFilterItemsToFilterArray({
        value: value.map((i) => i.vehicleGroupId),
        operatorValue: "in",
        columnField: "vehicle.vehicleGroups.vehicleGroupId",
        label: "Vehicle Groups",
        displayValue: value.map((i) => i.vehicleGroupName).join(", "),
      });
    } else {
      removeFilterItemsFromFilterArray("vehicle.vehicleGroups.vehicleGroupId");
    }
  };

  const handleSerialNoChange = (e) => {
    const {
      target: { value },
    } = e;
    setNewVehicleId(value);
    if (!!value) {
      saveFilterItemsToFilterArray({
        value: value,
        operatorValue: "contains",
        columnField: "serialNo",
        label: "Serial No.",
        displayValue: value,
      });
    } else {
      removeFilterItemsFromFilterArray("serialNo");
    }
  };

  const handleStatusChange = (e) => {
    const {
      target: { value },
    } = e;
    console.log(value);
    setStatus(value);
    if (value === "all") {
      removeFilterItemsFromFilterArray("active");
    } else {
      saveFilterItemsToFilterArray({
        columnField: "active",
        operatorValue: "equals",
        value: value === "active" ? true : false,
        label: "Status",
        displayValue: value,
      });
    }
  };

  const handleNavFeatureStatusChange = (e) => {
    const {
      target: { value },
    } = e;
    console.log(value);
    setNavFeatureStatus(value);
    if (value === "all") {
      removeFilterItemsFromFilterArray("vehicle.vehicleFeatures.active");
    } else {
      saveFilterItemsToFilterArray({
        columnField: "vehicle.vehicleFeatures.active",
        operatorValue: "equals",
        value: value === "enabled" ? true : false,
        label: "Navigation Features",
        displayValue: value,
      });
    }
  };
  const handleSubscriptionsStatusChange = (e) => {
    const {
      target: { value },
    } = e;
    console.log(value);
    setSubscriptionStatus(value);
    if (value === "all") {
      removeFilterItemsFromFilterArray("vehicle.subscriptions.active");
    } else {
      saveFilterItemsToFilterArray({
        columnField: "vehicle.subscriptions.active",
        operatorValue: "equals",
        value: value === "active" ? true : false,
        label: "Subscriptions",
        displayValue: value,
      });
    }
  };

  const handleCertAvailableChange = (e) => {
    const {
      target: { value },
    } = e;
    console.log(value);
    setCertAvailable(value);
    if (value === "all") {
      removeFilterItemsFromFilterArray("certAvailable");
    } else {
      saveFilterItemsToFilterArray({
        columnField: "certAvailable",
        operatorValue: "equals",
        value: value === "available" ? true : false,
        label: "Certificate",
        displayValue: value,
      });
    }
  };

  const handleVehicleKeyChange = (e) => {
    const {
      target: { value },
    } = e;
    setVehicleKey(value);
    if (!!value) {
      saveFilterItemsToFilterArray({
        value: { searchString: value },
        operatorValue: "inAndLike",
        columnField: "vehicleKey",
        label: "Vehicle Key",
        displayValue: value,
      });
    } else {
      removeFilterItemsFromFilterArray("vehicleKey");
    }
  };

  const searchPreviewProps = {
    setExtraFilters,
    extraFilters,
    filterItems,
  };

  return (
    <Grid item xs={12} md={3}>
      <Card>
        <CardHeader title="Search Vehicles" sx={{ pb: 0 }} />
        <CardContent>
          <SearchItemsPreview {...searchPreviewProps} />
          <Box component="form">
            <TextField
              id="vehicle_id"
              label="Serial No"
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
              value={newVehicleId}
              onChange={handleSerialNoChange}
            />
            <TextField
              id="vehicle_key"
              label="Vehicle Key"
              variant="standard"
              fullWidth
              sx={{ mb: 2 }}
              value={vehicleKey}
              onChange={handleVehicleKeyChange}
            />
            <MultipleGroupSelect
              {...{
                selectedGroups,
                setSelectedGroups,
                handleGroupMultiSelectChange,
              }}
            />
            <FormControl
              variant="standard"
              style={{
                marginTop: "12px",
                marginBottom: "12px",
                marginRight: "1%",
                width: "49%",
              }}
            >
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                style={{ width: "100%" }}
                labelId="status-label"
                id="status"
                value={status}
                onChange={handleStatusChange}
                label="Status"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              style={{ marginTop: "12px", marginBottom: "12px", width: "50%" }}
            >
              <InputLabel id="status-label">Navigation Features</InputLabel>
              <Select
                style={{ width: "100%" }}
                labelId="status-label"
                id="status"
                value={navFeatureStatus}
                onChange={handleNavFeatureStatusChange}
                label="Navigation Features"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="enabled">Enabled</MenuItem>
                <MenuItem value="disabled">Disabled</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              style={{
                marginTop: "12px",
                marginBottom: "12px",
                marginRight: "1%",
                width: "49%",
              }}
            >
              <InputLabel id="subscriptions-label">Subscriptions</InputLabel>
              <Select
                style={{ width: "100%" }}
                labelId="subscriptions-label"
                id="subscriptions"
                value={subscriptionStatus}
                onChange={handleSubscriptionsStatusChange}
                label="Subscriptions"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              style={{
                marginTop: "12px",
                marginBottom: "12px",
                marginRight: "1%",
                width: "49%",
              }}
            >
              <InputLabel id="certAvailable-label">Certificate</InputLabel>
              <Select
                style={{ width: "100%" }}
                labelId="certAvailable-label"
                id="certAvailable"
                value={certAvailable}
                onChange={handleCertAvailableChange}
                label="CertAvailable"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="available">Available</MenuItem>
                <MenuItem value="notavailable">Not Available</MenuItem>
              </Select>
            </FormControl>
            <Paper
              component="form"
              elevation={3}
              sx={{
                p: "4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                mt: "4px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Filter by date
                    </FormLabel>
                    <FormGroup>
                      <SearchDateInput
                        colField="serialNoCreatedAt"
                        label="HU Manufactured"
                        extraFilters={extraFilters}
                        setExtraFilters={setExtraFilters}
                        filterItems={filterItems}
                        setFilterItems={setFilterItems}
                        dateObj={manufacturedDateObj}
                        setDateObj={setManufacturedDateObj}
                        dateChecked={manufacturedChecked}
                        setDateChecked={setManufacturedDateChecked}
                      />
                      <Divider />
                      <SearchDateInput
                        colField="vehicleKeyUpdatedAt"
                        label="Vehicle Manufactured"
                        extraFilters={extraFilters}
                        setExtraFilters={setExtraFilters}
                        filterItems={filterItems}
                        setFilterItems={setFilterItems}
                        dateObj={provisionedDateObj}
                        setDateObj={setProvisionedDateObj}
                        dateChecked={provisionedDateChecked}
                        setDateChecked={setProvisionedDateChecked}
                      />
                      <Divider />
                      <SearchDateInput
                        colField="vehicle.vehiclePurchaseDetails.purchaseDate"
                        label="Purchased"
                        extraFilters={extraFilters}
                        setExtraFilters={setExtraFilters}
                        filterItems={filterItems}
                        setFilterItems={setFilterItems}
                        dateObj={purchasedDateObj}
                        setDateObj={setPurchasedDateObj}
                        dateChecked={purchasedDateChecked}
                        setDateChecked={setPurchasedDateChecked}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </CardContent>
        <CardActions>
          <Button variant="outlined" onClick={() => onSearchSubmit()}>
            Search
          </Button>
          <Button variant="filled" onClick={() => clearSearchForm()}>
            Reset
          </Button>
          {/* <Button variant="outlined" onClick={openQueryBuilder}>
            Group query
          </Button> */}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default VehicleSearch;
