import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Avatar } from "@mui/material";
import { grey } from "@mui/material/colors";
import styled from "styled-components";
import UserRoleManager from "./UserRoleManager";
import UserDisableBtn from "./UserDisableBtn";
import UserConfirmBtn from "./UserConfirmBtn";
import { formatDate, stringToHslColor } from "../../services/Utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Constants, { UserRoleStatus } from "../../services/Constants";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import UserOrgManager from "./UserOrgManager";
import Check from "../../auth/ability";
import AssignOrgBtn from "./AssignOrgBtn";

const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const getColumnsData = ({ params }) => {
    const validateAdmin = Check({
        children: null,
        validateAdmin: true,
    });
    return [
        {
            field: "userName",
            headerName: "Name",
            width: 300,
            renderCell: (data) => {
                const { row = {} } = data;
                const {
                    userName = "",
                    enabled = false,
                    status = "",
                    userId,
                } = row || {};
                return (
                    <div className="user-account-block">
                        <Avatar
                            sx={{
                                bgcolor: enabled
                                    ? stringToHslColor(userName, 40, 40)
                                    : grey[500],
                            }}
                        >
                            {userName.charAt(0).toUpperCase()}
                        </Avatar>
                        <p className="account-username">
                            <RouterLink
                                to={`/users/${userId}`}
                            >
                                {userName}
                            </RouterLink>
                            {status === Constants.USER_STATUS.CONFIRMED && (
                                <CheckCircleIcon
                                    className="user-confirmed-icon"
                                    sx={{ fontSize: 14 }}
                                    color={enabled ? "primary" : "inherit"}
                                />
                            )}
                        </p>
                    </div>
                );
            },
        },
        {
            field: "email",
            headerName: "Email",
            width: 300,
            editable: false,
        },

        {
            field: "createdDate",
            headerName: "Added on",
            width: 150,
            editable: false,
            type: "dateTime",
            renderCell: (data) => {
                const { row = {} } = data;
                const {
                    createdAt = '',
                } = row || {};
                return < DateAndTimeGridCell value={createdAt} />
            },
        },
        {
            field: "org",
            headerName: "Assign Organization",
            width: 250,
            editable: false,
            disableColumnMenu: true,
            sortable: false,
            hide: validateAdmin ? false : true,
            renderCell: (data) => {
                const { row = {} } = data;
                const {
                    userId = '',
                } = row || {};
                return <AssignOrgBtn {...params} {...row} dbUserId={userId} />;
            },
        }
    ];
};

export { getColumnsData };
