import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { useToast } from "../toast";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearProgressWithLabel from "./LinearProgress";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Avatar from "@mui/material/Avatar";
import { getStsToken } from "../../services/SdvFeature";
import { useQuery } from "react-query";
import { isFileTypeValid } from "../../services/Utils";

const UploadListItemData = (props) => {
  const { addToast } = useToast();
  const {
    uploadType,
    uploadText,
    icon,
    fileType,
    assignFileUploadFunction,
    setMetaDataFile,
    isUploadInProgress,
  } = props;
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    loaded: 0,
    total: 100,
  });
  const [uploadStatus, setUploadStatus] = useState(null);
  const [stsToken, setStsToken] = useState({
    accessKeyId: "",
    secretAccessKey: "",
    sessionToken: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [uploadInstance, setUploadInstance] = useState(null);

  useQuery("stsToken", getStsToken, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: token = {} } = data;
      setStsToken(token);
    },
    onError: (err) => {
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  const onMetaDataFileSelected = (item) => {
    const fileReader = new FileReader();
    const selected = item?.target?.files[0];
    fileReader.readAsText(selected);
    fileReader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e?.target?.result);
        setMetaDataFile(jsonData);
      } catch (e) {
        //Error
        addToast({
          type: "error",
          message: `"${selected?.name}" may not contain a valid Json data!`,
          autoClose: 3000,
        });
        setMetaDataFile(null);
      }
    };
  };

  const validateFilePicked = (event) => {
    const selected = event?.target?.files[0];
    if (isFileTypeValid(selected?.name, fileType?.accept)) {
      setFileName(selected?.name);
      setSelectedFile(event.target.files[0]);
      setFileSelected(true);

      if (uploadType === "metadata") {
        onMetaDataFileSelected(event);
      } else {
        assignFileUploadFunction(
          uploadType,
          event.target.files[0],
          stsToken,
          setUploadInstance,
          setUploadProgress,
          setErrorMsg,
          errorMsg,
          setUploadStatus
        );
      }
    } else {
      setFileSelected(false);
      addToast({
        type: "error",
        message: `"${selected?.name}" is not a valid ${fileType?.name} file!`,
        autoClose: 3000,
      });
    }
  };

  const removeSelectedFile = () => {
    if (uploadType !== "metadata") {
      assignFileUploadFunction(uploadType, null);
    }
    setFileName("");
    setSelectedFile(null);
    setFileSelected(false);
    setMetaDataFile("");
  };
  return (
    <>
      {fileSelected ? (
        <ListItem
          secondaryAction={
            <IconButton edge="end" aria-label="delete">
              {/* {(uploadProgress && uploadProgress?.loaded === 0) ||
              uploadType === "metadata" ? (
                <DeleteIcon onClick={() => removeSelectedFile()} />
              ) : (
                ""
              )} */}
              {!isUploadInProgress ? (
                <DeleteIcon onClick={() => removeSelectedFile()} />
              ) : (
                ""
              )}
            </IconButton>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <DescriptionIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={fileName}
            secondary={
              uploadProgress && uploadProgress?.loaded !== 0 ? (
                <LinearProgressWithLabel {...uploadProgress} />
              ) : (
                ""
              )
            }
          />
        </ListItem>
      ) : (
        <ListItem disablePadding>
          <ListItemButton component="label">
            <ListItemIcon style={{ margin: 0 }}>
              <input
                hidden
                type="file"
                onChange={(event) => {
                  validateFilePicked(event);
                }}
                accept={fileType?.accept}
              />
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={uploadText}
              secondary={"Click to choose a file."}
            />
          </ListItemButton>
        </ListItem>
      )}
    </>
  );
};

export default UploadListItemData;
