import React from "react";
import { getColumnsData } from "./DeploymentColumns";
import { getDeploymentData } from "../../services/deployment";
import OTAPagination from "../OTAPagination";

const DeploymentListTable = () => {
  const columns = getColumnsData();

  return (
    <OTAPagination
      columns={columns}
      endpointFn={getDeploymentData}
      queryName={"deployments"}
      filterMode="server"
      sortByDefault={"campaignCreationDate"}
      getRowId={(row) => row.deploymentId}
    />
  );
};

DeploymentListTable.defaultProps = {};

DeploymentListTable.propTypes = {};

export default DeploymentListTable;
