import React, { useRef } from "react";
import { Box, Typography, FormLabel } from "@mui/material";
import { orange, grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { isFileTypeValid } from "../../services/Utils";
import { useToast } from "../toast";
import {
  FILE_TYPES,
  HD_UPLOAD_MAX_FILESIZE,
  CUSTOMERS,
} from "../../services/Constants";

export default function FileUploadPickerPane({
  onFileSelected,
  title,
  fileType,
  styles,
}) {
  const theme = useTheme();
  const filePickerInput = useRef();
  const { addToast } = useToast();

  const validateFileMaxSize = (selectedFile) => {
    let selectedFileMaxSize = false;
    let ls_customer = localStorage.selectedCustomer || "visteon";

    const matchingCustomers = CUSTOMERS.filter(
      (customer) => customer.value === ls_customer
    );

    const hdCustomer = matchingCustomers.find(
      (customer) => customer.value === "HD"
    );

    if (hdCustomer) {
      selectedFileMaxSize =
        Number(selectedFile?.size) > HD_UPLOAD_MAX_FILESIZE ? true : false;
    }

    if (selectedFileMaxSize) {
      addToast({
        type: "error",
        message: `"${selectedFile?.name}" file size exceeds the maximum allowed limit of 5 GB!`,
        autoClose: 3000,
      });
      filePickerInput.current.value = null;
    }

    return selectedFileMaxSize;
  };

  const validateFilePicked = (event) => {
    const selected = event?.target?.files[0];
    let isValidFileSize = validateFileMaxSize(selected);
    if (!!fileType?.accept && fileType?.accept !== "*") {
      if (isFileTypeValid(selected?.name, fileType?.accept)) {
        if (!isValidFileSize) {
          onFileSelected(event);
        }
      } else {
        addToast({
          type: "error",
          message: `"${selected?.name}" is not a valid ${fileType?.name} file!`,
          autoClose: 3000,
        });
        // Clear the file picker input value while the selected file is invalid.
        filePickerInput.current.value = null;
      }
    } else {
      if (!isValidFileSize) {
        onFileSelected(event);
      }
    }
  };

  return (
    <div>
      <Typography sx={{ fontSize: 15, marginBottom: 2 }} color="text.primary">
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          top: 0,
          left: 0,
          height: "400px",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          border: "2px dashed",
          borderColor: grey[500],
          background: grey[200],
          ...styles,
        }}
      >
        <FormLabel
          htmlFor="file-upload"
          sx={{
            textAlign: "center",
            fontSize: 30,
            padding: 2,
            width: 300,
            borderRadius: 2,
            border: "1px solid white",
            cursor: "pointer",
            color: "#fff",
            background: theme.palette.primary[700],
            boxShadow: 10,
          }}
        >
          Upload
        </FormLabel>
      </Box>

      <Box sx={{ display: "none" }}>
        <input
          type="file"
          id="file-upload"
          ref={filePickerInput}
          onChange={validateFilePicked}
          accept={fileType?.accept || FILE_TYPES.ALL.accept}
        />
      </Box>
    </div>
  );
}
