import React, { useEffect, useState } from "react";
import {
  useParams,
  Link as RouterLink,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Button,
  Grid,
} from "@mui/material";
import {
  formatDate,
  getCurrentPathTab,
  detailsTabsBoxStyle,
  overviewListItemStyles,
} from "../services/Utils";
import axios from "axios";
import Constants from "../services/Constants";
import BreadCrumbsRendered from "../components/BreadCrumbsRendered";
import SdvFeatureVehicleMap from "../components/sdv-feature/SdvFeatureVehicleMap";
import SdvVehicleFeatureDataGrid from "../components/sdv-feature/SdvFeatureVehicleDataGrid";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { useToast } from "../components/toast";
import { getSdvFeatureById } from "../services/SdvFeature";
import DeleteVehicleFeatureDialog from "../components/sdv-feature/DeleteVehicleFeatureDialog";

const PATHS = ["overview"];

const SdvFeature = () => {
  let params = useParams();
  const { addToast } = useToast();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, PATHS[0])
  );
  const [sdvDetails, setSdvDetails] = useState({});
  const [openModalSdv, setOpenModalSdv] = useState(false);
  const handleOpenSdv = () => setOpenModalSdv(true);
  const handleOpenSdvClose = () => setOpenModalSdv(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useQuery(["getSdvFeature", params?.sdvFeatureId], getSdvFeatureById, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setSdvDetails(data);
    },
    onError: (err) => {
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  const Overview = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Grid container>
          <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
            <List>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Feature Name"
                  secondary={sdvDetails?.sdvFeatureName}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Description"
                  secondary={sdvDetails?.description}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Uploaded By"
                  secondary={sdvDetails?.uploadedBy}
                />
              </ListItem>
              <ListItem {...overviewListItemStyles}>
                <ListItemText
                  primary="Updated At"
                  secondary={formatDate(sdvDetails?.updatedAt)}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}>
            <List></List>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const Vehicles = () => {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ justifyContent: "normal", display: "flex" }}
        >
          {sdvDetails?.sdvFeatureName}
        </Typography>
        <Box style={{ margin: "1em", justifyContent: "end", display: "flex" }}>
          <Button
            style={{ marginLeft: "1em" }}
            variant="outlined"
            onClick={handleOpenSdv}
          >
            Map Vehicle
          </Button>
          <Button
            style={{ marginLeft: "1em" }}
            variant="outlined"
            disabled={!selectedRows?.length}
            onClick={() => handleDelete()}
          >
            Remove Vehicle
          </Button>
        </Box>
        {openDeleteDialog && (
          <DeleteVehicleFeatureDialog
            id={sdvDetails?.sdvFeatureId}
            name={sdvDetails?.sdvFeatureName}
            selectedRows={selectedRows}
            toggleDialog={() => setOpenDeleteDialog(false)}
          />
        )}
        {openModalSdv && (
          <SdvFeatureVehicleMap
            open={openModalSdv}
            toggleDialog={() => setOpenModalSdv(!openModalSdv)}
            sdvData={sdvDetails}
          />
        )}
        <div>
          <SdvVehicleFeatureDataGrid
            sdvFeatureId={sdvDetails?.sdvFeatureId}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
          />
        </div>
      </Box>
    );
  };

  let { UPLOAD_STATUS: UploadStatus } = Constants;

  const img_status = sdvDetails?.image_uploadStatus === UploadStatus.Completed;

  const video_status =
    sdvDetails?.video_uploadStatus === UploadStatus.Completed;

  return (
    <div className="fragmentContainer">
      <BreadCrumbsRendered pathname={pathname} />
      <Box
        sx={{ width: "100%", marginTop: 4, padding: "2%" }}
        component={Paper}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Overview"
              value={"overview"}
              component={RouterLink}
              to={"overview"}
            />
            {img_status && video_status && (
              <Tab
                label="Vehicles"
                value={"vehicles"}
                component={RouterLink}
                to={"vehicles"}
              />
            )}
          </Tabs>
        </Box>
        <div className="tabs-container">
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="overview" element={<Overview />} />
            {img_status && video_status && (
              <Route path="vehicles" element={<Vehicles />} />
            )}
          </Routes>
        </div>
      </Box>
    </div>
  );
};
export default SdvFeature;
