import React from "react";
import { useParams } from "react-router-dom";
import { cancelVehicleDeployment } from "../../services/deployment";
import { useQueryClient, useMutation } from "react-query";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import CloseIcon from "@mui/icons-material/Close";

const CancelVehicleDeployment = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let { deploymentId } = useParams();
  const { jobExecutionSummary = {} } = data || {};
  const vehicle = data.serialNo;
  const payload = {
    deploymentId,
    vehicle,
  };
  // Access the client
  const queryClient = useQueryClient();

  const { mutate: handleCancelVehicle, isLoading: cancelIsLoading } =
    useMutation(cancelVehicleDeployment, {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("jobExecutions");
      },
    });

  return (
    <div>
      {(jobExecutionSummary.status === "QUEUED" ||
        jobExecutionSummary.status === "IN_PROGRESS") && (
        <Check I={CAN.CANCEL} a={SUBJECTS.DEPLOYMENT}>
          <Button
            size="small"
            onClick={() => {
              if (jobExecutionSummary.status === "IN_PROGRESS") {
                handleOpen();
              } else {
                handleCancelVehicle(payload);
              }
            }}
            disabled={cancelIsLoading}
          >
            {cancelIsLoading ? <HourglassTopIcon /> : "Cancel"}
          </Button>
        </Check>
      )}
      <InprogressCancelAlert {...{ open, handleClose }} />
    </div>
  );
};

CancelVehicleDeployment.defaultProps = {
  data: {},
};

CancelVehicleDeployment.propTypes = {};

export default CancelVehicleDeployment;

const InprogressCancelAlert = ({ open, handleClose }) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Cancel Deployment
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          Cannot cancel depoyments that are currently in "IN-PROGRESS" status!
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
