import React, { useContext } from "react";
import Button from '@mui/material/Button';
import { WizardContext, ACTIONS } from "../wizard/Context";

const UserRoleManager = (props) => {
  const {
    userId = ''
  } = props;

  const { dispatch } = useContext(WizardContext);

  const handleOpen = () => {
    dispatch({
      type: ACTIONS.SHOW_ROLE_MANAGER,
      payload: {
        roleUserId: userId
      }
    });
  }

  return (
    <Button variant="text" onClick={handleOpen}>Manage Roles</Button>
  );
}

export default UserRoleManager;
