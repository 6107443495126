import React, { Fragment, useContext } from "react";
import { WizardContext, ACTIONS } from "../wizard/Context";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CardActionArea,
} from "@mui/material";
import { useQuery } from "react-query";
import { getStatsForDashboard } from "../../services/Dashboard";
import { grey } from "@mui/material/colors";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { MODULES } from "../../services/Constants";
import useModule from "../../hooks/useModule";

const DeploymentStats = () => {
  const { validateModuleWithMessage } = useModule();
  const { dispatch } = useContext(WizardContext);
  const { data: { deployments = 0 } = {} } = useQuery(
    "getStatsForDashboard",
    getStatsForDashboard,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <Box>
          <Card>
            <CardContent className="tile-cards">
              <Typography color="text.secondary" gutterBottom>
                Total Deployments
              </Typography>
              <Typography
                color="text.primary"
                sx={{ fontSize: 30, fontWeight: 300 }}
                gutterBottom
              >
                {deployments}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Check I={CAN.CREATE} a={SUBJECTS.DEPLOYMENT}>
        <Grid item xs={12} sm={6} md={2}>
          <CardActionArea
            onClick={() => {
              if (validateModuleWithMessage(MODULES.OTA)) {
                dispatch({
                  type: ACTIONS.SHOW_SOFTWARE_WIZARD,
                })
              }
            }
            }
          >
            <div className="dotted-card">
              <p className="dotted-text">CREATE A DEPLOYMENT</p>
              <div className="dotted-icon">
                <RocketLaunchIcon
                  sx={{ fontSize: "3.5rem", color: grey[500] }}
                />
              </div>
            </div>
          </CardActionArea>
        </Grid>
      </Check>

      <Grid item xs={3}></Grid>
    </Fragment>
  );
};

DeploymentStats.defaultProps = {};

DeploymentStats.propTypes = {};

export default DeploymentStats;
