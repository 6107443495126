import { grey, indigo, orange, teal } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// pie chart colors generated from https://www.learnui.design/tools/data-color-picker.html

const orangeTheme = createTheme({
  palette: {
    primary: { ...orange, main: orange[800] },
    secondary: grey,
    tertiary: orange[50],
  },
  custom: {
    pieChartColors: [
      orange[800],
      orange[600],
      orange[400],
      orange[300],
      orange[200],
    ],
    dashboardCardGradient: `linear-gradient(135deg, transparent 0%,${orange[200]} 100%)`,
    logoClass: "orange",
    selectedItemColor: orange[800],
  },
});

const indigoOffset = 100;
const indigoTheme = createTheme({
  palette: {
    primary: indigo,
    secondary: grey,
    tertiary: indigo[50],
  },
  custom: {
    pieChartColors: [
      indigo[800 - indigoOffset],
      indigo[600 - indigoOffset],
      indigo[400 - indigoOffset],
      indigo[300 - indigoOffset],
      indigo[200 - indigoOffset],
    ],
    dashboardCardGradient: `linear-gradient(135deg, transparent 0%,${indigo[200]} 100%)`,
    logoClass: "indigo",
    selectedItemColor: indigo[800],
  },
});

const tealTheme = createTheme({
  palette: {
    primary: teal,
    secondary: grey,
    tertiary: teal[50],
  },
  custom: {
    pieChartColors: [teal[800], teal[600], teal[400], teal[300], teal[200]],
    dashboardCardGradient: `linear-gradient(135deg, transparent 0%,${teal[200]} 100%)`,
    logoClass: "teal",
    selectedItemColor: teal[800],
  },
});

const themes = {
  orangeTheme,
  indigoTheme,
  tealTheme,
};

const getThemeByName = (themeName) => themes[themeName];

export { themes, getThemeByName };
