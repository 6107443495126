import React, { useState, useEffect, useRef, useCallback } from "react";
import FileUploadProgressPane from "./FileUploadProgressPane";
import FileUploadPickerPane from "./FileUploadPickerPane";
import FileUploadAdditionalInfoPane from "./FileUploadAdditionalInfoPane";
import FileUploadCompletePane from "./FileUploadCompletePane";
import FileUploadFailedPane from "./FileUploadFailedPane";
import Constants, {
  DEPLOYMENT_PACKAGE_TYPE,
  FILE_TYPES,
} from "../../services/Constants";

function FileUpload({
  packageType,
  manifestData,
  handleClose,
  resumeUpload,
  uploadProgress,
  setUploadProgress,
  uploadStatus,
  setUploadStatus,
  uploadInstance,
  setUploadInstance,
  imageUploadActionLoading,
  setImageUploadActionLoading,
}) {
  let { UPLOAD_STATUS: UploadStatus } = Constants;

  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onFileSelected = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const resetForm = useCallback(() => {
    setIsFilePicked(false);
    setSelectedFile(null);
    setUploadProgress({ loaded: 0, total: 100 });
    setUploadStatus(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleClose = () => {
    resetForm();
    handleClose();
  };

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  if (!isFilePicked) {
    return (
      <FileUploadPickerPane
        onFileSelected={onFileSelected}
        title="Upload artefact to Image Repository"
        fileType={
          packageType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE
            ? FILE_TYPES.ISO
            : FILE_TYPES.ALL
        }
      />
    );
  }

  if (
    uploadStatus === UploadStatus.InProgress ||
    uploadStatus === UploadStatus.Started
  ) {
    return <FileUploadProgressPane uploadProgress={uploadProgress} />;
  }

  if (uploadStatus === UploadStatus.Failed) {
    return (
      <FileUploadFailedPane
        handleClose={onHandleClose}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
      />
    );
  }

  if (uploadStatus === UploadStatus.Completed) {
    return <FileUploadCompletePane handleClose={onHandleClose} />;
  }

  return (
    <FileUploadAdditionalInfoPane
      packageType={packageType}
      manifestData={manifestData}
      selectedFile={selectedFile}
      setUploadStatus={setUploadStatus}
      setUploadProgress={setUploadProgress}
      resetForm={resetForm}
      resumeUpload={resumeUpload}
      errorMsg={errorMsg}
      setErrorMsg={setErrorMsg}
      uploadInstance={uploadInstance}
      setUploadInstance={setUploadInstance}
      imageUploadActionLoading={imageUploadActionLoading}
      setImageUploadActionLoading={setImageUploadActionLoading}
    />
  );
}

export default FileUpload;
