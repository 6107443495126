import React, { useState } from "react";
import { object, shape } from "prop-types";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveWaffle } from "@nivo/waffle";
import { useQuery } from "react-query";
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  Button,
  Alert,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { getJobStatus } from "../../services/deployment";
import { JOB_STATUS } from "../../services/Constants";
import { CustomTooltip, BorderLinearProgress } from "./DeploymentAnalytics";
import VehicleDeploymentList from "./VehicleDeploymentList";
import { detailsTabsBoxStyle } from "../../services/Utils";

const DeploymentVehicleTab = (props) => {
  const [showProgress, setShowProgress] = useState(false);
  let params = useParams();
  const theme = useTheme();
  const { deploymentDetails = {} } = props;
  const {
    vehicles = [],
    deploymentId = "",
    jobId = "",
  } = deploymentDetails || {};

  const [errMsg, setErrMsg] = useState(
    "Deployment not yet published for this device"
  );

  // Queries
  const {
    data: {
      data: {
        summary = [],
        totalVehicles = 0,
        auditProgress: { count = 0, audits = [] } = {},
      } = {},
    } = {},
    isLoading,
  } = useQuery(["jobStatus", deploymentId], getJobStatus, {
    enabled: !!deploymentId,
    refetchInterval: 5000,
  });

  const commonProperties = {
    margin: { top: 40, right: 40, bottom: 40, left: 40 },
    sortByValue: true,
    innerRadius: 0.5,
    padAngle: 1,
    cornerRadius: 1,
    activeOuterRadiusOffset: 8,
    colors: theme.custom.pieChartColors,
    animate: true,
    borderWidth: 1,
    borderColor: {
      from: "color",
      modifiers: [["darker", 0.2]],
    },
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsTextColor: "#333333",
    arcLinkLabelsThickness: 2,
    arcLinkLabelsColor: { from: "color" },
    arcLabelsSkipAngle: 10,
    arcLabelsTextColor: {
      from: "color",
      modifiers: [["darker", 2]],
    },
  };

  const getProgressValue = (value, base) => {
    return Math.round((value / base) * 100);
  };

  if (!deploymentDetails?.jobId) {
    return (
      <Box {...detailsTabsBoxStyle}>
        <Alert variant="outlined" severity="warning">
          {errMsg}
        </Alert>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {count > 0 && (
        <Grid item md={12}>
          <Button
            sx={{
              float: "right",
            }}
            variant="outlined"
            onClick={() => setShowProgress((prevState) => !prevState)}
            endIcon={
              !showProgress ? (
                <KeyboardDoubleArrowRightIcon
                  sx={{ color: theme.palette.primary }}
                />
              ) : (
                <KeyboardDoubleArrowLeftIcon
                  sx={{ color: theme.palette.primary }}
                />
              )
            }
          >
            {!showProgress ? "view more" : "view less"}
          </Button>
        </Grid>
      )}

      {!showProgress && (
        <>
          {summary.length > 0 && (
            <Grid item md={5}>
              <div style={{ height: "350px" }}>
                <ResponsivePie
                  data={summary}
                  {...commonProperties}
                  tooltip={CustomTooltip}
                />
              </div>
            </Grid>
          )}
        </>
      )}

      {summary?.length > 0 && (
        <Grid item md={6} container spacing={2}>
          {summary.map((i) => (
            <Grid item md={4}>
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  sx={{
                    color: (theme) => theme.palette.grey[200],
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  variant="determinate"
                  value={100}
                  size={120}
                />
                <CircularProgress
                  variant="determinate"
                  value={getProgressValue(i.value, totalVehicles)}
                  size={120}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    <Typography align="center" variant="h5">
                      {i.value}
                    </Typography>
                    <Typography align="center" variant="caption">
                      {JOB_STATUS[i.id]}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {showProgress && (
        <Grid item md={6}>
          {audits.map((i) => (
            <div className="job-status-progress-border">
              <div className="job-status-progress">
                <Typography variant="caption">
                  {i.id} ({i.value})
                </Typography>
                <div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={getProgressValue(i.value, count)}
                  />
                </div>
              </div>
            </div>
          ))}
        </Grid>
      )}

      {showProgress && (
        <Grid item md={12}>
          {audits.length > 0 && (
            <div style={{ height: "200px" }}>
              <ResponsiveWaffle
                data={audits}
                total={count}
                rows={10}
                columns={80}
                colors={theme.custom.pieChartColors}
                // colors={{ scheme: 'nivo' }}
                fillDirection="left"
                margin={{ top: 0, right: 0, bottom: 10, left: 220 }}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.3]],
                }}
                animate={true}
                motionStiffness={80}
                motionDamping={10}
                legends={[
                  {
                    anchor: "left",
                    direction: "column",
                    justify: false,
                    translateX: -220,
                    translateY: 0,
                    itemsSpacing: 4,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    itemTextColor: "#777",
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                          itemBackground: "#f7fafb",
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          )}
        </Grid>
      )}
      <VehicleDeploymentList vehicles={vehicles} type="deployment" />
    </Grid>
  );
};

DeploymentVehicleTab.defaultProps = {
  deploymentDetails: shape({
    vehicles: [],
  }),
};

DeploymentVehicleTab.propTypes = {
  deploymentDetails: object,
};

export default DeploymentVehicleTab;
